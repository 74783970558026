import React from 'react'
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material'
import { Button, AccountInfo } from '@neobase-one/neobase-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { getSplitEarningsInfo, MessageWithTransaction } from '../utils'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'
import { useProvider, useAccount } from 'wagmi'
import { isAddress } from '@ethersproject/address'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'

interface Split {
  address: string
  recipients: number
  balance: string
  earnings: string
  splitType: 'immutable' | 'mutable' | 'liquid'
  relation: 'receiving' | 'transfer' | 'controller'
}

const Splits = () => {
  const theme = useTheme()

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar } = useSnackbar()
  const [splits, setSplits] = React.useState<Split[]>()
  const [gridColumns, setGridColumns] = React.useState<GridColDef[]>()
  const provider = useProvider()
  const { address, isDisconnected } = useAccount()

  const SplitType: React.FC<{
    type: string
  }> = ({ type }) => {
    const typeMap: { [key: string]: string } = {
      immutable: '#10b981',
      mutable: '#f44336',
      liquid: '#3f50b5',
    }
    return (
      <Tooltip
        title={
          <span style={{ fontFamily: 'IBM Plex Mono' }}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </span>
        }
        placement="top"
      >
        <Box
          sx={{
            width: 16,
            height: 16,
            mr: 2,
            background: typeMap[type],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            borderRadius: '2px',
            cursor: 'pointer',
          }}
        >
          {type.charAt(0).toUpperCase()}
        </Box>
      </Tooltip>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <SplitType type={params.row['splitType']} />
            <AccountInfo
              address={params.value}
              dense={true}
              buttonProps={{
                onClick: () => navigate(`/accounts/${params.value}`),
              }}
              onCopy={() =>
                enqueueSnackbar(
                  <MessageWithTransaction message="Address copied" />,
                )
              }
            />
          </>
        )
      },
    },
    { field: 'recipients', headerName: 'Recipients', minWidth: 90, flex: 1 },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'earnings',
      headerName: 'Earnings',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'relation',
      headerName: 'Relation',
      minWidth: 90,
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['receiving', 'transfer', 'controller'],
      valueFormatter: (params) => {
        return params.value.charAt(0).toUpperCase() + params.value.slice(1)
      },
    },
  ]

  React.useEffect(() => {
    async function queryRelated(splitsClient: SplitsClient, userId: string) {
      setLoading(true)
      const relatedSplits = await splitsClient.getRelatedSplits({
        address: userId,
      })
      const donors = await Promise.all(
        relatedSplits.receivingFrom.map(async (donor) => {
          const { activeAmount, totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            donor.id,
            true,
            useFetchMetadata,
          )
          return {
            address: donor.id,
            recipients: donor.recipients.length,
            balance: '$' + Number(activeAmount).toFixed(2),
            earnings: '$' + totalAmount,
            splitType: donor.controller === null ? 'immutable' : 'mutable',
            relation: 'receiving',
          } as Split
        }),
      )
      const relatedLiquidSplits =
        await splitsClient.liquidSplits!.getRelatedLiquidSplits({
          address: userId,
        })
      const liquidDonors = await Promise.all(
        relatedLiquidSplits.receivingFrom.map(async (donor) => {
          const { activeAmount, totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            donor.id,
            true,
            useFetchMetadata,
          )
          return {
            address: donor.id,
            recipients: donor.holders.length,
            balance: '$' + Number(activeAmount).toFixed(2),
            earnings: '$' + totalAmount,
            splitType: 'liquid',
            relation: 'receiving',
          } as Split
        }),
      )
      const controlling = await Promise.all(
        relatedSplits.controlling.map(async (split) => {
          const { activeAmount, totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            split.id,
            true,
            useFetchMetadata,
          )
          return {
            address: split.id,
            recipients: split.recipients.length,
            balance: '$' + Number(activeAmount).toFixed(2),
            earnings: '$' + totalAmount,
            splitType: split.controller === null ? 'immutable' : 'mutable',
            relation: 'controller',
          } as Split
        }),
      )

      const transferred = await Promise.all(
        relatedSplits.pendingControl.map(async (pending) => {
          const { activeAmount, totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            pending.id,
            true,
            useFetchMetadata,
          )
          return {
            address: pending.id,
            recipients: pending.recipients.length,
            balance: '$' + Number(activeAmount).toFixed(2),
            earnings: '$' + totalAmount,
            splitType: pending.controller === null ? 'immutable' : 'mutable',
            relation: 'transfer',
          } as Split
        }),
      )
      setGridColumns(columns)
      setSplits([...donors, ...liquidDonors, ...controlling, ...transferred])
      setLoading(false)
    }
    if (address && isAddress(address) && provider) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      queryRelated(splitsClient, address)
    }
  }, [address, provider])

  const SplitsList = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Card variant="outlined" sx={{ width: '100%' }}>
          <CardHeader title="Your splits" />
          <Divider />
          <CardContent sx={{ p: 0 }}>
            {gridColumns && splits && !loading ? (
              <>
                {splits.length ? (
                  <DataGrid
                    getRowId={(row) => row.address + row.relation}
                    autoHeight
                    columns={gridColumns}
                    rows={splits}
                    // getRowId={(row) => row.tokenId}
                    disableSelectionOnClick
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={{
                      border: 0,
                    }}
                  />
                ) : (
                  <Box sx={{ m: 4 }}>
                    <Typography sx={{ textAlign: 'center' }}>
                      No splits associated with your account
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        Splits
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1 }}>
        A Split is a payable smart contract that splits all CANTO & ERC20 tokens
        it receives.
      </Typography>
      {isDisconnected ? (
        <p>Please connect wallet to view related splits</p>
      ) : (
        <SplitsList />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button label="New Split" onClick={() => navigate('/newSplit')} />
      </Box>
    </Container>
  )
}

export default Splits
