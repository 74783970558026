import * as React from 'react'
import Card from '@mui/material/Card'
import { styled } from '@mui/system'

export const ButtonCard = styled(Card)`
  :hover {
    background-image: linear-gradient(
      rgba(173, 138, 39, 0.24),
      rgba(173, 138, 39, 0.24)
    );
  }
  transition: background-image 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`
