import * as React from 'react'
import {
  SplitsClient,
  WaterfallClient,
  LiquidSplitClient,
} from '@neobase-one/splits-sdk'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isAddress } from '@ethersproject/address'
import Loading from './Loading'
import SplitAccount from './SplitAccount'
import WaterfallAccount from './WaterfallAccount'
import LiquidAccount from './LiquidAccount'
import UserAccount from './UserAccount'
import VestingAccount from './VestingAccount'
import { useProvider, useSigner } from 'wagmi'

const Account: React.FC = () => {
  const params = useParams()
  const [accountType, setAccountType] = React.useState({
    type: 'user',
    loading: true,
  })
  const provider = useProvider()

  useEffect(() => {
    async function queryAccount(id: string) {
      const liquidSplitClient = new LiquidSplitClient({
        chainId: 7700,
        provider,
      })
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      const waterfallclient = new WaterfallClient({
        chainId: 7700,
        provider,
      })
      Promise.all([
        liquidSplitClient
          .getLiquidSplitMetadata({
            liquidSplitId: id,
          })
          .catch(() => {
            return null
          }),
        splitsClient
          .getSplitMetadata({
            splitId: id,
          })
          .catch(() => {
            return null
          }),
        waterfallclient
          .getWaterfallMetadata({
            waterfallModuleId: id,
          })
          .catch(() => {
            return null
          }),
        splitsClient.vesting
          ?.getVestingMetadata({
            vestingModuleId: id,
          })
          .catch((err) => {
            return null
          }),
      ]).then((val) => {
        if (val[0] !== null) {
          setAccountType({
            type: 'liquid',
            loading: false,
          })
        } else if (val[1] !== null) {
          setAccountType({
            type: 'split',
            loading: false,
          })
        } else if (val[2] !== null) {
          setAccountType({
            type: 'waterfall',
            loading: false,
          })
        } else if (val[3] !== null) {
          setAccountType({
            type: 'vesting',
            loading: false,
          })
        } else {
          setAccountType({
            type: 'user',
            loading: false,
          })
        }
      })
    }
    if (params.accountID) {
      if (isAddress(params.accountID)) {
        setAccountType({
          type: 'user',
          loading: true,
        })
        queryAccount(params.accountID)
      } else {
        setAccountType({
          type: 'user',
          loading: false,
        })
      }
    }
  }, [params.accountID])

  if (accountType.loading) return <Loading />
  else
    return accountType.type == 'split' ? (
      <SplitAccount />
    ) : accountType.type == 'waterfall' ? (
      <WaterfallAccount />
    ) : accountType.type == 'liquid' ? (
      <LiquidAccount />
    ) : accountType.type == 'vesting' ? (
      <VestingAccount />
    ) : (
      <UserAccount />
    )
}
export default Account
