import React from 'react'
import { Box, Container, Modal, Typography, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import CSRList from './CSRList'
import { useAccount } from 'wagmi'
import DepositTokenForm from './DepositVaultForm'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import CSRListUser from './CSRListUser'
const Vault: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [openDepositToken, setOpenDepositToken] = React.useState<boolean>(false)
  const [preSelectedToken, setPreSelectedToken] = React.useState<string>()
  const [trigger, SetTrigger] = React.useState(0)

  const { address, isConnected } = useAccount()

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: 24,
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        CSR Vault
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1, mb: 4 }}>
        The Vault stores CSR token and exposes function to distribute rewards to
        the recipient
      </Typography>
      {address && isConnected ? (
        <Stack alignItems={'center'} spacing={6}>
          <CSRList
            owner={address}
            trigger={trigger}
            refresh={() => {
              SetTrigger((trigger) => trigger + 1)
            }}
          />
          <ImportExportIcon fontSize="large" color="disabled" />
          <CSRListUser
            trigger={trigger}
            setOpenDepositToken={setOpenDepositToken}
            setPreSelectedToken={setPreSelectedToken}
          />
        </Stack>
      ) : (
        <Box sx={{ m: 4 }}>
          <Typography sx={{ textAlign: 'center' }}>
            Connect wallet to view your tokens in vault
          </Typography>
        </Box>
      )}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button
          label="Deposit Tokens"
          onClick={() => SetOpenDepositToken(true)}
        />
      </Box> */}
      {address && isConnected && (
        <Modal
          open={openDepositToken}
          onClose={() => {
            setOpenDepositToken(false)
          }}
          keepMounted={true}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <Box sx={{ ...modalStyles, width: 600 }}>
            {
              <DepositTokenForm
                trigger={trigger}
                onClose={() => {
                  setOpenDepositToken(false)
                }}
                refresh={() => {
                  SetTrigger((trigger) => trigger + 1)
                }}
                preSelectedToken={preSelectedToken}
              />
            }
          </Box>
        </Modal>
      )}
    </Container>
  )
}

export default Vault
