import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Stack,
} from '@mui/material'
import * as React from 'react'
import { Button } from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import CallSplitIcon from '@mui/icons-material/CallSplit'
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import { ButtonCard } from './SyledComponents'

const CreatePage: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        New Contract
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1 }}>
        You can stack multiple contracts together to create the payment flow you
        need.
      </Typography>
      <Stack spacing={6} pt={6}>
        <ButtonCard onClick={() => navigate('/newSplit')}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              cursor: 'pointer',
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                xs={2}
                md={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CallSplitIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  color={theme.palette.primary.main}
                  sx={{ fontWeight: 600 }}
                >
                  Split
                </Typography>
                <Typography variant="caption">
                  Set the percent of future income each account will be paid
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </ButtonCard>
        <ButtonCard onClick={() => navigate('/newSplit?type=liquid')}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              cursor: 'pointer',
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                xs={2}
                md={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ConfirmationNumberIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  color={theme.palette.primary.main}
                  sx={{ fontWeight: 600 }}
                >
                  Liquid Split
                </Typography>
                <Typography variant="caption">
                  Represent ownership in a Split using transferrable NFTs
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </ButtonCard>
        <ButtonCard onClick={() => navigate('/NewWaterfall')}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              cursor: 'pointer',
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                xs={2}
                md={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <WaterfallChartIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  color={theme.palette.primary.main}
                  sx={{ fontWeight: 600 }}
                >
                  Waterfall
                </Typography>
                <Typography variant="caption">
                  Specify the amounts and order in which accounts will be paid
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </ButtonCard>
      </Stack>
    </Container>
  )
}
export default CreatePage
