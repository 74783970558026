import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  Checkbox,
  Box,
} from '@mui/material'
import * as React from 'react'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { useEffect } from 'react'
import {
  AccountInfo,
  IconButton,
  ToggleButtonGroup,
} from '@neobase-one/neobase-components'
import { Button as CustomButton } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { isAddress } from '@ethersproject/address'
import { useTheme } from '@mui/material/styles'
import { useProvider, useSigner } from 'wagmi'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ethers } from 'ethers'
import {
  getSplitEarningsInfo,
  getUserEarningsInfo,
  getWaterfallEarningsInfo,
  queryRelated,
} from '../utils'
import AccountSkeleton from './AccountSkeleton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import { useAccount } from 'wagmi'
import CSRList from './CSRList'
import EastIcon from '@mui/icons-material/East'
import AccountLabel from './AccountLabel'

const SplitAccount: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [earnings, setEarnings] = React.useState<{
    totalAmount: string
    activeAmount: string
    balances: AccountInfoProps[]
    withdrawnAmount: string
    withdrawn: AccountInfoProps[]
  }>()
  const [receiving, setReceiving] = React.useState<AccountInfoProps[]>()
  const [controlling, setControlling] = React.useState<AccountInfoProps[]>()
  const [transferred, setTransferred] = React.useState<AccountInfoProps[]>()
  const [waterfalls, setWaterfalls] = React.useState<AccountInfoProps[]>()
  const [distribute, setDistribute] = React.useState<number[]>([])
  const provider = useProvider()
  const { data: signer } = useSigner()
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const { useFetchMetadata } = React.useContext(MetaDataContext)

  const [loadingRelated, setLoadingRelated] = React.useState<boolean>(false)
  const [loadingEarnings, setLoadingEarnings] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { address, isDisconnected } = useAccount()
  const [trigger, setTrigger] = React.useState(0)
  const [splitCount, setSplitCount] = React.useState(0)
  const [waterafllCount, setWaterafllCount] = React.useState(0)

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const userViewMap = {
    Receiving: receiving,
    Controlling: controlling,
    Transferred: transferred,
  }
  const [currView, setCurrView] = React.useState<
    'Receiving' | 'Controlling' | 'Transferred'
  >('Receiving')

  useEffect(() => {
    async function setRelated(splitsClient: SplitsClient, splitId: string) {
      setLoadingRelated(true)
      const {
        waterfalls,
        donors,
        liquidDonors,
        controlling,
        transferred,
        splitCount,
        waterfallCount,
      } = await queryRelated(
        splitsClient,
        splitId,
        useFetchMetadata,
        false,
        navigate,
        theme,
        enqueueSnackbar,
      )

      setWaterafllCount(splitCount)
      setSplitCount(waterfallCount)
      setWaterfalls(waterfalls)
      setReceiving([...donors, ...liquidDonors])
      setControlling(controlling)
      setTransferred(transferred)
      setLoadingRelated(false)
    }
    if (params.accountID && isAddress(params.accountID) && provider) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      setRelated(splitsClient, params.accountID)
    }
  }, [params, provider])

  async function queryEarnings(splitsClient: SplitsClient, userId: string) {
    setLoadingEarnings(true)
    const earnings = await getUserEarningsInfo(
      splitsClient,
      userId,
      useFetchMetadata,
    )
    if (!('error' in earnings)) {
      const {
        activeAmount,
        activeBalances,
        withdrawnAmount,
        withdrawnBalances,
        totalAmount,
      } = earnings
      setEarnings({
        activeAmount: activeAmount,
        balances: activeBalances,
        withdrawnAmount: withdrawnAmount,
        withdrawn: withdrawnBalances,
        totalAmount: totalAmount,
      })
    } else {
      setError(true)
    }
    setLoadingEarnings(false)
  }

  useEffect(() => {
    if (params.accountID && isAddress(params.accountID) && provider) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      queryEarnings(splitsClient, params.accountID)
    }
  }, [params, provider])

  const addBalance = (index: number, distributeCopy: number[]) => {
    const ind = distributeCopy.indexOf(index)
    if (ind == -1) {
      distributeCopy.push(index)
    } else {
      distributeCopy.splice(ind, 1)
    }
    setDistribute([...distributeCopy])
  }

  const distributeBalance = (distributeCopy: number[]) => {
    const distributeToken = async (splitsClient: SplitsClient) => {
      if (earnings && params.accountID) {
        let withdrawNotif = enqueueSnackbar(
          <MessageWithTransaction
            message={`Withdrawing balance ...`}
            description="Waiting for wallet approval"
          />,
          {
            variant: 'warning',
            persist: true,
            transitionDuration: { enter: 225, exit: 0 },
          },
        )
        try {
          const userId = params.accountID
          const tokens = earnings.balances
            .filter((earning, index) => distributeCopy.includes(index))
            .map((earning) => earning.key as string)
          const { tx: withdrawTx } =
            await splitsClient.submitWithdrawFundsTransaction({
              address: userId,
              tokens: tokens,
            })
          closeSnackbar(withdrawNotif)
          setTimeout(() => {
            withdrawNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Withdrawing balance ...`}
                transactionId={withdrawTx.hash}
              />,
              {
                variant: 'warning',
                persist: true,
                transitionDuration: { enter: 225, exit: 0 },
              },
            )
          }, 200)

          await withdrawTx.wait()
          closeSnackbar(withdrawNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Successfully withdrawn tokens`}
                transactionId={withdrawTx.hash}
              />,
              {
                variant: 'success',
              },
            )
          }, 200)
          setDistribute([])
          setTimeout(() => {
            queryEarnings(splitsClient, userId)
          }, 3000)
        } catch (err) {
          closeSnackbar(withdrawNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Error while withdrawing tokens`}
              />,
              {
                variant: 'error',
              },
            )
          }, 200)
        }
      }
    }
    if (distributeCopy.length && provider && signer) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
        signer,
      })
      distributeToken(splitsClient)
    }
  }

  if (!params.accountID || !isAddress(params.accountID) || error) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          flexGrow: 1,
          py: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Card sx={{ width: '100%', display: 'flex' }} variant="outlined">
          <IconButton
            startIcon={<ArrowBackIcon />}
            sx={{ px: 2 }}
            onClick={() => navigate(-1)}
          />
          {params.accountID && isAddress(params.accountID) && (
            <AccountInfo
              address={params.accountID}
              showMenu={false}
              showButtonGroup={true}
              expandOnHover={true}
              dense={false}
              truncate={!desktopView}
              provider={ensProvider}
            />
          )}
        </Card>
        <Typography sx={{ pt: 6 }}>
          {error
            ? 'Address not related to any split'
            : "Couldn't locate address"}
        </Typography>
        {error && (
          <Grid item xs={12} sx={{ pt: 6 }}>
            <Card
              sx={{
                cursor: 'pointer',
                background:
                  theme.palette.mode == 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.main,
                color: 'white',
              }}
              onClick={() => navigate('/create')}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 4,
                  transition:
                    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  ':hover': {
                    background: theme.palette.primary.dark,
                  },
                }}
              >
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">Get started</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Create a split and start using it with your favorite
                        web3 platforms today
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <EastIcon fontSize="large" />
              </Box>
            </Card>
          </Grid>
        )}
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start', pt: 6 }}>
      {loadingEarnings || loadingRelated ? (
        <AccountSkeleton />
      ) : (
        <>
          <Card sx={{ width: '100%', display: 'flex' }} variant="outlined">
            <IconButton
              startIcon={<ArrowBackIcon />}
              sx={{ px: 2, mr: 2 }}
              onClick={() => navigate(-1)}
            />
            <AccountInfo
              address={params.accountID}
              showMenu={false}
              showButtonGroup={true}
              expandOnHover={true}
              dense={false}
              truncate={!desktopView}
              provider={ensProvider}
              startElement={<AccountLabel type="User account" />}
              onCopy={() =>
                enqueueSnackbar(
                  <MessageWithTransaction message="Address copied" />,
                )
              }
              buttonProps={{
                onClick: () => {
                  if (params.accountID) {
                    navigator.clipboard.writeText(params.accountID)
                    enqueueSnackbar(
                      <MessageWithTransaction message="Address copied" />,
                    )
                  }
                },
              }}
              iconButtons={[
                {
                  startIcon: <RefreshIcon />,
                  sx: { px: 2 },
                  onClick: () => {
                    if (signer && provider && params.accountID) {
                      const splitsClient = new SplitsClient({
                        chainId: 7700,
                        provider,
                        signer,
                      })
                      queryEarnings(splitsClient, params.accountID)
                    }
                  },
                  toolTipProps: { title: 'Refresh' },
                },
              ]}
            />
          </Card>
          <Grid container spacing={6} sx={{ pt: 6 }}>
            <Grid item xs={12}>
              {params && (
                <CSRList
                  owner={params.accountID}
                  showExpand={params.accountID === address ? true : undefined}
                  hideButtons={params.accountID === address ? undefined : true}
                  trigger={trigger}
                  refresh={() => {
                    setTrigger((trigger) => trigger + 1)
                  }}
                  hideCheckbox={true}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container flexDirection="column" spacing={3}>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader
                      title="Splits"
                      action={
                        address !== params.accountID ||
                        splitCount < 30 ? null : (
                          <CustomButton
                            label="Expand"
                            onClick={() => navigate('/split')}
                            variant="outlined"
                          />
                        )
                      }
                    />
                    <ToggleButtonGroup
                      options={[
                        {
                          value: 'Receiving',
                          children: (
                            <>
                              Receiving <br />
                              {receiving ? receiving.length : 0}
                            </>
                          ),
                        },
                        {
                          value: 'Controlling',
                          children: (
                            <>
                              Controlling <br />
                              {controlling ? controlling.length : 0}
                            </>
                          ),
                        },
                        {
                          value: 'Transferred',
                          children: (
                            <>
                              Transferred <br />
                              {transferred ? transferred.length : 0}
                            </>
                          ),
                        },
                      ]}
                      fullWidth
                      buttonProps={{
                        sx: {
                          fontSize: '0.75rem',
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                          py: 1.7,
                        },
                      }}
                      value={currView}
                      onChange={(event, newView) => {
                        setCurrView(newView.substring(currView.length))
                      }}
                      color="primary"
                    />
                    <CardContent sx={{ maxHeight: 500, overflowY: 'auto' }}>
                      <Stack spacing={2} divider={<Divider />}>
                        {userViewMap[currView] &&
                        userViewMap[currView]!.length > 0 ? (
                          userViewMap[currView]!.map((split, index) => (
                            <AccountInfo
                              {...split}
                              dense={true}
                              key={`${currView}_${index}`}
                            />
                          ))
                        ) : (
                          <Typography align="center">
                            No accounts exist that match this filter
                          </Typography>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container flexDirection="column" spacing={3}>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Grid container>
                          <Grid item xs>
                            Account Earnings
                          </Grid>
                          <Grid item>${earnings?.totalAmount}</Grid>
                        </Grid>
                      }
                    ></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack
                        pl={1}
                        spacing={2}
                        pt={1}
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                      >
                        {earnings && earnings.balances.length > 0 && (
                          <>
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography
                                // variant="h6"
                                // sx={{ color: theme.palette.secondary.main }}
                                >
                                  Current Balances
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  disabled={
                                    distribute.length === 0 ? true : false
                                  }
                                  onClick={() => distributeBalance(distribute)}
                                >
                                  Withdraw Tokens
                                </Button>
                              </Grid>
                            </Grid>
                            {earnings &&
                              earnings.balances.map((balance, index) => (
                                <React.Fragment key={`Balance_${index}`}>
                                  <Stack>
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <Checkbox
                                          onChange={() =>
                                            addBalance(index, distribute)
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs>
                                        <AccountInfo
                                          truncate={false}
                                          showMenu={false}
                                          {...balance}
                                          dense={true}
                                          buttonProps={{ sx: { py: 2 } }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </React.Fragment>
                              ))}
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Balances
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.activeAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings && earnings.withdrawn.length > 0 && (
                          <>
                            <Typography
                              sx={{
                                paddingTop: earnings.balances.length ? 4 : 0,
                              }}
                            >
                              Withdrawn
                            </Typography>
                            <Stack>
                              {earnings &&
                                earnings.withdrawn.map(
                                  (withdrawnBalance, index) => (
                                    <React.Fragment key={`Withdrawn_${index}`}>
                                      <AccountInfo
                                        truncate={false}
                                        showMenu={false}
                                        {...withdrawnBalance}
                                        dense={true}
                                        buttonProps={{ sx: { py: 2 } }}
                                      />
                                    </React.Fragment>
                                  ),
                                )}
                            </Stack>
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Withdrawn
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.withdrawnAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings &&
                          earnings.balances.length === 0 &&
                          earnings.withdrawn.length === 0 && (
                            <Typography align="center">
                              No Data Found
                            </Typography>
                          )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader
                      title="Waterfalls"
                      action={
                        address !== params.accountID ||
                        waterafllCount < 30 ? null : (
                          <CustomButton
                            label="Expand"
                            onClick={() => navigate('/waterfall')}
                            variant="outlined"
                          />
                        )
                      }
                    />
                    <Divider />
                    <CardContent sx={{ maxHeight: 500, overflowY: 'auto' }}>
                      <Stack spacing={2} divider={<Divider />}>
                        {waterfalls && waterfalls.length > 0 ? (
                          waterfalls.map((waterfall, index) => (
                            <AccountInfo
                              {...waterfall}
                              dense={true}
                              key={`${currView}_${index}`}
                            />
                          ))
                        ) : (
                          <Typography align="center">
                            Account receiving from no waterfalls
                          </Typography>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}
export default SplitAccount
