import React from 'react'
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Avatar,
} from '@mui/material'
import { Button, AccountInfo } from '@neobase-one/neobase-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { getWaterfallEarningsInfo, MessageWithTransaction } from '../utils'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'
import { useProvider, useAccount } from 'wagmi'
import { isAddress } from '@ethersproject/address'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import { CANTO_ADDRESS, WCANTO_ADDRESS } from '../constants'
import { useTheme } from '@mui/material/styles'

interface Waterfall {
  address: string
  tranches: number
  balance: string
  earnings: string
  token: {
    symbol: string
    avatarSrc: string
  }
}

const Waterfalls = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar } = useSnackbar()
  const [waterfalls, setWaterfalls] = React.useState<Waterfall[]>()
  const [gridColumns, setGridColumns] = React.useState<GridColDef[]>()
  const provider = useProvider()
  const { address, isDisconnected } = useAccount()

  const columns: GridColDef[] = [
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            address={params.value}
            dense={true}
            buttonProps={{
              onClick: () => navigate(`/accounts/${params.value}`),
            }}
            onCopy={() =>
              enqueueSnackbar(
                <MessageWithTransaction message="Address copied" />,
              )
            }
          />
        )
      },
    },
    { field: 'tranches', headerName: 'Tranches', minWidth: 90, flex: 1 },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'earnings',
      headerName: 'Earnings',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'token',
      headerName: 'Token',
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.value.avatarSrc}
              sx={{ width: 24, height: 24, mr: 2 }}
            />
            <p>{params.value.symbol}</p>
          </>
        )
      },
    },
  ]

  React.useEffect(() => {
    async function queryRelated(splitsClient: SplitsClient, userId: string) {
      setLoading(true)
      const relatedWaterfalls =
        await splitsClient.waterfall!.getRelatedWaterfalls({
          address: userId,
        })
      const waterfalls = await Promise.all(
        relatedWaterfalls.receivingFrom.map(async (waterfall) => {
          const { activeAmount, totalAmount } = await getWaterfallEarningsInfo(
            splitsClient.waterfall!,
            waterfall.id,
            waterfall.token,
            useFetchMetadata,
          )
          const metaData = await useFetchMetadata(waterfall.token.address)
          return {
            address: waterfall.id,
            tranches: waterfall.tranches.length,
            balance: '$' + Number(activeAmount).toFixed(2),
            earnings: '$' + Number(totalAmount).toFixed(2),
            token: {
              symbol: waterfall.token.symbol,
              avatarSrc:
                waterfall.token.address == CANTO_ADDRESS ||
                waterfall.token.address == WCANTO_ADDRESS
                  ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
                  : metaData.image,
            },
          } as Waterfall
        }),
      )
      setGridColumns(columns)
      setWaterfalls([...waterfalls])
      setLoading(false)
    }
    if (address && isAddress(address) && provider) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      queryRelated(splitsClient, address)
    }
  }, [address, provider])

  const WaterfallsList = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Card variant="outlined" sx={{ width: '100%' }}>
          <CardHeader title="Your waterfalls" />
          <Divider />
          <CardContent sx={{ p: 0 }}>
            {gridColumns && waterfalls && !loading ? (
              <>
                {waterfalls.length ? (
                  <DataGrid
                    getRowId={(row) => row.address}
                    autoHeight
                    columns={gridColumns}
                    rows={waterfalls}
                    // getRowId={(row) => row.tokenId}
                    disableSelectionOnClick
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={{
                      border: 0,
                    }}
                  />
                ) : (
                  <Box sx={{ m: 4 }}>
                    <Typography sx={{ textAlign: 'center' }}>
                      No waterfalls associated with your account
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        Waterfalls
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1 }}>
        A Waterfall is a payable smart contract that distributes funds in a
        pre-defined order.
      </Typography>
      {isDisconnected ? (
        <p>Please connect wallet to view related waterfalls</p>
      ) : (
        <WaterfallsList />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button
          label="New Waterfall"
          onClick={() => navigate('/newWaterfall')}
        />
      </Box>
    </Container>
  )
}

export default Waterfalls
