import { Chain, configureChains, createClient } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const cantoChain: Chain = {
  id: 7700,
  name: 'Canto',
  network: 'canto',
  nativeCurrency: {
    decimals: 18,
    name: 'Canto',
    symbol: 'CANTO',
  },
  rpcUrls: {
    default: 'https://rpc-blankrasa.neobase.one',
  },
  blockExplorers: {
    default: { name: 'Canto Explorer', url: 'https://tuber.build/' },
  },
  testnet: false,
}
const chains = [cantoChain]
const { provider, webSocketProvider } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.id !== cantoChain.id) return null
      return { http: chain.rpcUrls.default }
    },
    priority: 0,
  }),
])

export const client = createClient({
  autoConnect: false,
  connectors: [
    new SafeConnector({ chains: chains }),
    new MetaMaskConnector({ chains: chains }),
    new WalletConnectConnector({
      chains: chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains: chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
})
