import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { AddressInput, Button } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import { useProvider, useSigner, useContract, useAccount } from 'wagmi'
import {
  CSR_SUBGRAPH,
  CSR_VAULT_CONTRACT,
  TURNSTILE_CONTRACT,
} from '../constants'
import { CSRVaultAbi } from '../abis/CSRValut'
import { BigNumber } from '@ethersproject/bignumber'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
export const DepositTokenForm: React.FC<{
  to?: string
  trigger?: number
  preSelectedToken?: string
  onClose: () => void
  refresh: () => void
}> = ({ to, onClose, refresh, trigger, preSelectedToken }) => {
  const theme = useTheme()
  const [tokens, setTokens] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [recipientAddress, setRecipientAddress] = React.useState('')
  const [approveStatus, setApproveStatus] = React.useState({
    approved: '',
    tokenId: '',
  })
  const { data: signer } = useSigner()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { address } = useAccount()
  const [selectedTokenIndex, setSelectedTokenIndex] = React.useState('')
  const CSRValutContract = useContract({
    address: CSR_VAULT_CONTRACT,
    abi: CSRVaultAbi,
    signerOrProvider: signer,
  })

  React.useEffect(() => {
    if (to && isAddress(to)) {
      setRecipientAddress(to)
    }
  }, [to])

  React.useEffect(() => {
    console.log(preSelectedToken, tokens)
    if (preSelectedToken && tokens && tokens.indexOf(preSelectedToken) != -1) {
      setSelectedTokenIndex(tokens.indexOf(preSelectedToken).toString())
    }
  }, [preSelectedToken, tokens])

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTokenIndex(event.target.value as string)
  }

  const fetchUserTokens = async () => {
    if (address) {
      setLoading(true)
      const client = new ApolloClient({
        uri: CSR_SUBGRAPH,
        cache: new InMemoryCache(),
      })
      const NFTQuery = gql`
        query MyQuery($owner: String!) {
          nfts(where: { owner: $owner }) {
            tokenId
            owner
            id
          }
        }
      `
      const results = (
        await client.query({
          query: NFTQuery,
          variables: { owner: address },
        })
      ).data['nfts'].map((token: { tokenId: string }) => token.tokenId)
      setTokens(results)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchUserTokens()
  }, [address, trigger])

  const getApproved = async (tokenId: string) => {
    if (signer) {
      setApproveStatus({
        approved: '',
        tokenId: '',
      })
      const _tokenId = BigNumber.from(tokenId)
      const turnstileAbi = [
        'function getApproved(uint256) public view returns (address)',
      ]
      const turnstile = new ethers.Contract(
        TURNSTILE_CONTRACT,
        turnstileAbi,
        signer,
      )
      const approved = await turnstile.getApproved(_tokenId)
      setApproveStatus({
        approved: approved,
        tokenId: tokenId,
      })
    }
  }
  React.useEffect(() => {
    if (selectedTokenIndex !== '') {
      const tokenId = tokens[parseInt(selectedTokenIndex)]

      getApproved(tokenId)
    }
  }, [selectedTokenIndex])

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const approve = async () => {
    if (signer && selectedTokenIndex !== '') {
      const tokenId = tokens[parseInt(selectedTokenIndex)]
      const _tokenId = BigNumber.from(tokenId)
      let approveTokenNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Approving for token ${tokenId} ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const turnstileAbi = [
          'function approve(address _approved, uint256 _tokenId) external payable',
        ]
        const turnstile = new ethers.Contract(
          TURNSTILE_CONTRACT,
          turnstileAbi,
          signer,
        )
        const tx = await turnstile.approve(CSR_VAULT_CONTRACT, _tokenId)
        closeSnackbar(approveTokenNotif)
        setTimeout(() => {
          approveTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Approving for token ${tokenId} ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)
        await tx.wait()
        closeSnackbar(approveTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Approved for token ${tokenId}`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
      } catch (err) {
        console.log(err)
        closeSnackbar(approveTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction message={`Error while approving token`} />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
      getApproved(tokenId)
    }
  }

  const depositToken = async () => {
    if (
      signer &&
      CSRValutContract &&
      isAddress(recipientAddress) &&
      selectedTokenIndex !== ''
    ) {
      const tokenId = tokens[parseInt(selectedTokenIndex)]
      const _tokenId = BigNumber.from(tokenId)
      console.log(_tokenId, recipientAddress)
      onClose()
      let transferTokenNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Transferring token ${tokenId} ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const tx = await CSRValutContract.depositNFT(_tokenId, recipientAddress)
        closeSnackbar(transferTokenNotif)
        setTimeout(() => {
          transferTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Transferring token ${tokenId} ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)
        await tx.wait()
        closeSnackbar(transferTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Transferred token ${tokenId}`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        refresh()
      } catch (err) {
        console.log(err)
        closeSnackbar(transferTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while transferring token to vault`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
    }
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="Deposit token to vault"></CardHeader>
        <Divider />
        <CardContent>
          {!loading && tokens !== undefined ? (
            <>
              {tokens.length > 0 ? (
                <>
                  <Typography color={theme.palette.text.primary} sx={{ pb: 4 }}>
                    Transfer your CSR tokens and set recipient of the rewards
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="caption">Recipient</Typography>
                      <AddressInput
                        value={recipientAddress}
                        setValue={(event) => setRecipientAddress(event)}
                        placeholder={'0x0000...000 (e.g. split address)'}
                        provider={ensProvider}
                        cardProps={{ sx: { mt: 1 } }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ pt: 4 }}>
                    <Typography variant="caption">CSR Token ID</Typography>
                    <Select
                      value={selectedTokenIndex}
                      onChange={handleChange}
                      fullWidth
                      sx={{ borderRadius: 2, mt: 1 }}
                    >
                      {tokens.map((token, index) => (
                        <MenuItem value={index} key={index}>
                          {token}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              ) : (
                <Typography color={theme.palette.text.primary} sx={{ pb: 4 }}>
                  You have no CSR tokens to transfer
                </Typography>
              )}
            </>
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            px: 6,
            mb: 2,
          }}
        >
          {selectedTokenIndex === '' ? (
            <Button label="Deposit" disabled />
          ) : (
            <>
              {approveStatus.tokenId !==
              tokens[parseInt(selectedTokenIndex)] ? (
                <CircularProgress />
              ) : approveStatus.approved === CSR_VAULT_CONTRACT ? (
                <Button
                  label="Deposit"
                  onClick={depositToken}
                  disabled={!isAddress(recipientAddress)}
                />
              ) : (
                <Button label="Approve" onClick={approve} />
              )}
            </>
          )}
        </CardActions>
      </Card>
    </Box>
  )
}
export default DepositTokenForm
