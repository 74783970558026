export const CSRVaultAbi = [
  {
    type: 'constructor',
    stateMutability: 'nonpayable',
    inputs: [
      { type: 'address', name: '_turnstileAddress', internalType: 'address' },
    ],
  },
  {
    type: 'event',
    name: 'DepositNFT',
    inputs: [
      {
        type: 'uint256',
        name: '_tokenId',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'address',
        name: '_feeRecipient',
        internalType: 'address',
        indexed: false,
      },
      {
        type: 'address',
        name: '_sender',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'UpdateFeeRecipient',
    inputs: [
      {
        type: 'uint256',
        name: '_tokenId',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'address',
        name: '_feeRecipient',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawFee',
    inputs: [
      {
        type: 'uint256',
        name: '_tokenId',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'uint256',
        name: '_amount',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'WithdrawNFT',
    inputs: [
      {
        type: 'uint256',
        name: '_tokenId',
        internalType: 'uint256',
        indexed: false,
      },
      { type: 'address', name: '_to', internalType: 'address', indexed: false },
    ],
    anonymous: false,
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'depositNFT',
    inputs: [
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' },
      {
        type: 'address',
        name: '_feeRecipient',
        internalType: 'address payable',
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'pure',
    outputs: [{ type: 'bytes4', name: '', internalType: 'bytes4' }],
    name: 'onERC721Received',
    inputs: [
      { type: 'address', name: '', internalType: 'address' },
      { type: 'address', name: '', internalType: 'address' },
      { type: 'uint256', name: '', internalType: 'uint256' },
      { type: 'bytes', name: '', internalType: 'bytes' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'updateFeeRecipient',
    inputs: [
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' },
      {
        type: 'address',
        name: '_feeRecipient',
        internalType: 'address payable',
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawFee',
    inputs: [
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawNFT',
    inputs: [
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' },
      { type: 'address', name: '_to', internalType: 'address' },
    ],
  },
]
