import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  AddressInput,
  Button,
  ToggleButtonGroup,
} from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SplitsForm, { SplitsData } from './SplitsForm'
import { isAddress } from 'ethers/lib/utils.js'
import { useUpdateSplit } from '@neobase-one/splits-sdk-react'
import { useProvider, useSigner, useAccount, useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { ethers } from 'ethers'
import CloseIcon from '@mui/icons-material/Close'
import { Split, SplitsClient } from '@neobase-one/splits-sdk'
import { SnackbarOrigin } from '@mui/material/Snackbar'
import { AlertColor } from '@mui/material/Alert'
import { split } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'

type SplitsType = 'immutable' | 'mutable' | 'liquid'

interface UpdateProps
  extends Pick<SplitsData, 'recipients' | 'distributorFeePercent'> {
  onClose: () => void
  refresh: () => void
  splitId: string
}

const UpdateSplit: React.FC<UpdateProps> = ({
  splitId,
  onClose,
  refresh,
  recipients,
  distributorFeePercent,
}) => {
  const provider = useProvider()
  const navigate = useNavigate()
  const { address, isConnecting, isDisconnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const [data, setData] = React.useState<SplitsData>({
    recipients,
    distributorFeePercent,
  })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { updateSplit, status, txHash, error } = useUpdateSplit()
  const [formStatus, setFormStatus] = React.useState({
    pending: true,
  })
  const [updateNotif, setUpdateNotif] = React.useState<string | number>()

  React.useEffect(() => {
    if (updateNotif) {
      closeSnackbar(updateNotif)
      setUpdateNotif(undefined)
    }
    console.log(status, txHash)
    if (status == 'pendingApproval') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Updating Split ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'txInProgress' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Updating Split ...`}
          transactionId={txHash}
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'complete' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Updated split`}
          transactionId={txHash}
        />,
        {
          variant: 'success',
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'error') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction message={`Error while updating split`} />,
        {
          variant: 'error',
        },
      )
      setUpdateNotif(notifKey)
    }
  }, [status, txHash])

  React.useEffect(() => {
    if (address && isAddress(address)) {
      setData({ ...data, controller: address })
    }
  }, [address])

  const theme = useTheme()

  React.useEffect(() => {
    if (
      data.recipients.every(
        (recipient) =>
          isAddress(recipient.address) &&
          recipient.percentAllocation > 0 &&
          recipient.percentAllocation < 100,
      ) &&
      data.distributorFeePercent < 10 &&
      data.recipients.reduce(
        (curr, next) => curr + next.percentAllocation,
        0,
      ) == 100
    ) {
      setFormStatus({ pending: false })
    } else {
      setFormStatus({ pending: true })
    }
  }, [data])

  async function executeUpdateSplit() {
    onClose()
    const events = await updateSplit({
      splitId: splitId,
      recipients: data.recipients,
      distributorFeePercent: data.distributorFeePercent,
    })
    if (events) {
      refresh()
    }
  }

  return (
    <Card
      variant="outlined"
      sx={{ py: 4, maxHeight: '90vh', overflowY: 'auto' }}
    >
      <CardHeader
        title="Update Split"
        action={
          <Button
            startIcon={<CloseIcon />}
            onClick={() => onClose()}
            label="Close"
            variant="text"
          />
        }
      ></CardHeader>

      <Box sx={{ mt: 6 }}>
        {
          <SplitsForm
            data={data}
            setDataHandler={setData}
            decimals={2}
            cardProps={{ sx: { borderRadius: 0 } }}
          />
        }
      </Box>
      <CardActions
        sx={{ justifyContent: 'center', mt: 6, flexDirection: 'column' }}
      >
        {isDisconnected ? (
          <Button onClick={() => connect()} label="Connect Wallet" />
        ) : status == 'txInProgress' ? (
          <>
            {txHash && (
              <Typography
                sx={{ pb: 4, width: '100%', textAlign: 'center' }}
                variant="caption"
                // color="error"
              >
                Tx Hash: {txHash}
              </Typography>
            )}
            <CircularProgress />
          </>
        ) : (
          <Button
            disabled={formStatus.pending}
            label="Update Split"
            onClick={executeUpdateSplit}
          />
        )}
      </CardActions>
    </Card>
  )
}
export default UpdateSplit
