import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  Link,
  Checkbox,
  Modal,
  Box,
  Avatar,
} from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode'
import * as React from 'react'
import {
  LiquidSplit,
  LiquidSplitClient,
  SplitsClient,
} from '@neobase-one/splits-sdk'
import { useEffect } from 'react'
import { AccountInfo, IconButton } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { isAddress } from '@ethersproject/address'
import { useTheme } from '@mui/material/styles'
import { useProvider, useSigner } from 'wagmi'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import { getSplitEarningsInfo, getEventData, queryRelated } from '../utils'
import AccountSkeleton from './AccountSkeleton'
import QRCode from 'react-qr-code'
import DepositForm from './DepositForm'
import VestingForm from './VestingForm'
import RefreshIcon from '@mui/icons-material/Refresh'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import { MetaDataContext } from '../context'
import { Buffer } from 'buffer'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import CSRList from './CSRList'
import DepositTokenForm from './DepositVaultForm'
import AccountLabel from './AccountLabel'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'

const LiquidAccount: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [liquid, setLiquid] = React.useState<LiquidSplit>()
  const [owner, setOwner] = React.useState<string>()
  const [earnings, setEarnings] = React.useState<{
    balances: AccountInfoProps[]
    distributed: AccountInfoProps[]
    totalAmount: string
    activeAmount: string
    distributedAmount: string
  }>()
  const { address, isConnected } = useAccount()
  const [openDeposit, setOpenDeposit] = React.useState<boolean>(false)
  const [openVesting, setOpenVesting] = React.useState<boolean>(false)
  const [recipients, setRecipients] = React.useState<AccountInfoProps[]>()
  const [receiving, setReceiving] = React.useState<AccountInfoProps[]>()
  const [accountEvents, setAccountEvents] = React.useState<
    {
      avatar: JSX.Element
      text: string
      time: string
    }[]
  >()
  const [distribute, setDistribute] = React.useState<number[]>([])

  const provider = useProvider()
  const { useFetchMetadata } = React.useContext(MetaDataContext)

  const { data: signer } = useSigner()
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: 24,
  }
  const [loadingLiquid, setLoadingLiquid] = React.useState<boolean>(false)
  const [loadingRelated, setLoadingRelated] = React.useState<boolean>(false)
  const [loadingEarnings, setLoadingEarnings] = React.useState<boolean>(false)
  const [nftSrc, setNFTSrc] = React.useState<string>()
  const [openQR, setOpenQR] = React.useState<boolean>(false)
  const [trigger, SetTrigger] = React.useState(0)
  const [openDepositToken, SetOpenDepositToken] = React.useState<boolean>(false)
  const [bookmarked, setBookmarked] = React.useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  }

  async function queryLiquidSplit(
    liquidSplitClient: LiquidSplitClient,
    liquidId: string,
  ) {
    setLoadingLiquid(true)
    const liquid = await liquidSplitClient.getLiquidSplitMetadata({
      liquidSplitId: liquidId,
    })
    const { owner } = await liquidSplitClient.getOwner({
      liquidSplitId: liquidId,
    })
    const uri = await liquidSplitClient.getUri({
      liquidSplitId: liquidId,
    })
    setNFTSrc(
      JSON.parse(Buffer.from(uri.uri.split(',')[1], 'base64').toString('utf-8'))
        .image,
    )
    setLiquid(liquid)
    setOwner(owner)
  }

  async function queryEarnings(splitClient: SplitsClient, liquidId: string) {
    setLoadingEarnings(true)
    const {
      activeAmount,
      activeBalances,
      distributedAmount,
      distributions,
      totalAmount,
    } = await getSplitEarningsInfo(
      splitClient,
      liquidId,
      true,
      useFetchMetadata,
    )
    setEarnings({
      activeAmount: activeAmount,
      balances: activeBalances,
      distributedAmount: distributedAmount,
      distributed: distributions,
      totalAmount: totalAmount,
    })
    setLoadingEarnings(false)
  }

  useEffect(() => {
    if (params.accountID && liquid && params.accountID !== liquid?.id) {
      setLiquid(undefined)
    }
    if (params.accountID && isAddress(params.accountID) && provider) {
      const liquidClient = new LiquidSplitClient({
        chainId: 7700,
        provider,
      })
      queryLiquidSplit(liquidClient, params.accountID)
    }
  }, [params.accountID])

  useEffect(() => {
    async function setRelated(splitsClient: SplitsClient, splitId: string) {
      setLoadingRelated(true)
      const { waterfalls, donors, liquidDonors, vestingModules } =
        await queryRelated(
          splitsClient,
          splitId,
          useFetchMetadata,
          true,
          navigate,
          theme,
          enqueueSnackbar,
        )

      setReceiving([
        ...donors,
        ...liquidDonors,
        ...waterfalls,
        ...vestingModules,
      ])
      setLoadingRelated(false)
      setLoadingLiquid(false)
    }

    if (params.accountID && isAddress(params.accountID) && provider && liquid) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      queryEarnings(splitsClient, params.accountID)
      setRelated(splitsClient, params.accountID)
    }
  }, [liquid])

  useEffect(() => {
    async function getAccountEvents(liquid: LiquidSplit) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      const payoutSplit = await splitsClient.getSplitMetadata({
        splitId: liquid.payoutSplitId,
      })

      if (payoutSplit.accountEvents) {
        const events = await Promise.all(
          payoutSplit.accountEvents.map(async (event: any) => {
            return await getEventData(event, useFetchMetadata)
          }),
        )
        setAccountEvents(events)
      }
    }

    if (liquid && provider) {
      const recipients = liquid.holders.map(
        (holder) =>
          ({
            address: holder.address,
            amount: `${holder.percentAllocation}%`,
            buttonProps: {
              onClick: () => navigate(`/accounts/${holder.address}`),
            },
            onCopy: () =>
              enqueueSnackbar(
                <MessageWithTransaction message="Address copied" />,
              ),
          } as AccountInfoProps),
      )
      setRecipients(recipients)
      getAccountEvents(liquid)
    } else {
      setRecipients(undefined)
      setAccountEvents(undefined)
    }
  }, [liquid])

  const addBalance = (index: number, distributeCopy: number[]) => {
    const ind = distributeCopy.indexOf(index)
    if (ind == -1) {
      distributeCopy.push(index)
    } else {
      distributeCopy.splice(ind, 1)
    }
    setDistribute([...distributeCopy])
  }

  useEffect(() => {
    const bookmarksString = localStorage.getItem('splits-bookmarks')
    if (bookmarksString && params.accountID) {
      const allBookmarks: string[] = JSON.parse(bookmarksString)
      if (allBookmarks.includes(params.accountID)) {
        setBookmarked(true)
      }
    }
  }, [params.accountID])

  const toggleBookmark = async () => {
    if (params.accountID) {
      const bookmarksString = localStorage.getItem('splits-bookmarks')
      let allBookmarks: string[] = []
      if (bookmarksString) {
        allBookmarks = JSON.parse(bookmarksString)
        if (allBookmarks.includes(params.accountID)) {
          allBookmarks = allBookmarks.filter(
            (address) => address != params.accountID,
          )
          setBookmarked(false)
        } else {
          allBookmarks.push(params.accountID)
          setBookmarked(true)
        }
      } else {
        allBookmarks = [params.accountID]
        setBookmarked(true)
      }
      localStorage.setItem('splits-bookmarks', JSON.stringify(allBookmarks))
    }
  }

  const distributeBalance = async (distributeCopy: number[]) => {
    const distributeToken = async (
      index: number,
      liquidSplitClient: LiquidSplitClient,
    ) => {
      if (earnings && liquid) {
        const liquidId = liquid.id
        const tokenName = earnings.balances[index].address
        let distributeTransactionNotif = enqueueSnackbar(
          <MessageWithTransaction
            message={`Distributing ${tokenName} ...`}
            description="Waiting for wallet approval"
          />,
          {
            variant: 'warning',
            persist: true,
            transitionDuration: { enter: 225, exit: 0 },
          },
        )
        try {
          const { tx: distributeTokenTx } =
            await liquidSplitClient.submitDistributeTokenTransaction({
              liquidSplitId: liquidId,
              token: earnings.balances[index].key as string,
            })
          closeSnackbar(distributeTransactionNotif)
          setTimeout(() => {
            distributeTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Distributing ${tokenName} ...`}
                transactionId={distributeTokenTx.hash}
              />,
              {
                variant: 'warning',
                persist: true,
                transitionDuration: { enter: 225, exit: 0 },
              },
            )
          }, 200)
          await distributeTokenTx.wait()
          closeSnackbar(distributeTransactionNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Distributed ${tokenName}`}
                transactionId={distributeTokenTx.hash}
              />,
              {
                variant: 'success',
              },
            )
          }, 200)
          return true
        } catch (err) {
          console.log(err)
          closeSnackbar(distributeTransactionNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Error while distributing ${tokenName}`}
              />,
              {
                variant: 'error',
              },
            )
          }, 200)
          return false
        }
      }
      return false
    }
    if (distributeCopy.length && provider && signer && liquid) {
      const liquidClient = new LiquidSplitClient({
        chainId: 7700,
        provider,
        signer,
      })
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      let distributed = true
      const promises = distributeCopy.map(async (index) => {
        const success = await distributeToken(index, liquidClient)
        distributed = distributed && success
      })
      await Promise.all(promises)
      setDistribute([])
      setTimeout(() => {
        queryLiquidSplit(liquidClient, liquid.id)
      }, 3000)
    }
  }

  if (!params.accountID || !isAddress(params.accountID)) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          flexGrow: 1,
          py: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>Couldn't locate address</>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start', pt: 6 }}>
      <Modal
        open={openQR}
        onClose={() => setOpenQR(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={{ ...modalStyles, width: 300 }}>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AccountInfo
                address={params.accountID}
                showMenu={false}
                showButtonGroup={false}
                expandOnHover={true}
                dense={true}
                truncate={false}
                provider={ensProvider}
              />
              <Box sx={{ p: 20 }}>
                <Card sx={{ px: 2, background: 'white', pb: '4px', pt: 2 }}>
                  <QRCode size={200} value={params.accountID} />
                </Card>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
      {loadingLiquid || loadingEarnings || loadingRelated ? (
        <AccountSkeleton />
      ) : (
        <>
          <Card sx={{ width: '100%', display: 'flex' }} variant="outlined">
            <IconButton
              startIcon={<ArrowBackIcon />}
              sx={{ px: 2 }}
              onClick={() => navigate(-1)}
            />
            <AccountInfo
              address={params.accountID}
              showMenu={false}
              showButtonGroup={true}
              expandOnHover={true}
              dense={false}
              truncate={!desktopView}
              provider={ensProvider}
              startElement={<AccountLabel type="Liquid split" />}
              onCopy={() =>
                enqueueSnackbar(
                  <MessageWithTransaction message="Address copied" />,
                )
              }
              buttonProps={{
                onClick: () => {
                  if (params.accountID) {
                    navigator.clipboard.writeText(params.accountID)
                    enqueueSnackbar(
                      <MessageWithTransaction message="Address copied" />,
                    )
                  }
                },
              }}
              iconButtons={[
                {
                  startIcon: <RefreshIcon />,
                  sx: { px: 2 },
                  onClick: () => {
                    if (signer && provider && liquid) {
                      const liquidClient = new LiquidSplitClient({
                        chainId: 7700,
                        provider,
                        signer,
                      })
                      queryLiquidSplit(liquidClient, liquid.id)
                    }
                  },
                  toolTipProps: { title: 'Refresh' },
                },
                {
                  startIcon: bookmarked ? <StarIcon /> : <StarBorderIcon />,
                  sx: { px: 2 },
                  onClick: toggleBookmark,
                  toolTipProps: {
                    title: bookmarked ? 'Remove bookmark' : 'Add bookmark',
                  },
                },
              ]}
            />
          </Card>
          <Grid container spacing={6} sx={{ pt: 6 }}>
            {liquid && (
              <Grid item xs={12}>
                <CSRList
                  recipient={liquid.id}
                  trigger={trigger}
                  refresh={() => {
                    SetTrigger((trigger) => trigger + 1)
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Grid container flexDirection="column" spacing={6}>
                <Grid item sx={{ width: '100%' }}>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Grid container>
                          <Grid item xs>
                            Split Earnings
                          </Grid>
                          <Grid item>${earnings?.totalAmount}</Grid>
                        </Grid>
                      }
                    ></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack
                        pl={1}
                        spacing={2}
                        pt={1}
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                      >
                        <Typography paragraph={true}>
                          The Split pays {liquid?.distributorFeePercent}% for
                          each balance distributed.{' '}
                          <Link href="https://docs.0xsplits.xyz/core-concepts#distributor-fee">
                            Learn more
                          </Link>
                        </Typography>
                        {earnings && earnings.balances.length > 0 && (
                          <>
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography>Balances</Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  disabled={
                                    distribute.length === 0 ? true : false
                                  }
                                  onClick={() => distributeBalance(distribute)}
                                >
                                  Distribute
                                </Button>
                              </Grid>
                            </Grid>
                            {earnings.balances.map((balance, index) => (
                              <React.Fragment key={`Balance_${index}`}>
                                <Stack>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        checked={
                                          distribute.indexOf(index) !== -1
                                        }
                                        onChange={() =>
                                          addBalance(index, distribute)
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <AccountInfo
                                        truncate={false}
                                        showMenu={false}
                                        {...balance}
                                        dense={true}
                                        buttonProps={{ sx: { py: 2 } }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </React.Fragment>
                            ))}
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Balances
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.activeAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings && earnings.distributed.length > 0 && (
                          <>
                            <Typography
                              sx={{
                                paddingTop: earnings.balances.length ? 4 : 0,
                              }}
                            >
                              Distributed
                            </Typography>
                            <Stack>
                              {earnings.distributed.map(
                                (distribution, index) => (
                                  <React.Fragment key={`Distributed_${index}`}>
                                    <AccountInfo
                                      truncate={false}
                                      showMenu={false}
                                      {...distribution}
                                      dense={true}
                                      buttonProps={{ sx: { py: 2 } }}
                                    />
                                  </React.Fragment>
                                ),
                              )}
                            </Stack>
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Distributed
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.distributedAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings &&
                          earnings.balances.length === 0 &&
                          earnings.distributed.length === 0 && (
                            <Typography align="center">
                              No Data Found
                            </Typography>
                          )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                  <Card variant="outlined">
                    <CardHeader title="Recipients"></CardHeader>
                    <Divider />
                    <CardContent>
                      {nftSrc && (
                        <Grid
                          my={1}
                          p={1}
                          container
                          alignItems="center"
                          sx={{
                            border: '1px solid rgba(255, 255, 255, 0.12)',
                            mb: 2,
                          }}
                        >
                          <Grid item>
                            <Avatar
                              src={nftSrc}
                              sx={{ width: 32, height: 32 }}
                            />
                          </Grid>
                          <Grid item pl={2}>
                            <Typography
                              sx={{ fontSize: '0.8rem' }}
                              fontWeight="bold"
                            >
                              Liquid Split NFTs are transferrable
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Stack
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                        spacing={2}
                        divider={<Divider />}
                      >
                        {recipients &&
                          recipients.map((accountInfo, index) => (
                            <AccountInfo
                              {...accountInfo}
                              dense={true}
                              provider={ensProvider}
                            />
                          ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {accountEvents && (
                  <Grid item sx={{ width: '100%' }}>
                    <Card variant="outlined">
                      <CardHeader title="History"></CardHeader>
                      <Divider />
                      <CardContent>
                        <Stack
                          sx={{ maxHeight: 500, overflowY: 'auto' }}
                          spacing={2}
                          divider={<Divider flexItem />}
                        >
                          {accountEvents &&
                            accountEvents.map(
                              ({ avatar, text, time }, index) => (
                                <Stack
                                  spacing={2}
                                  direction="row"
                                  alignItems="center"
                                >
                                  {avatar}
                                  <Typography variant="caption">
                                    {text}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{ color: theme.palette.text.secondary }}
                                  >
                                    {time}
                                  </Typography>
                                </Stack>
                              ),
                            )}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Grid container flexDirection="column" spacing={6}>
                {/* {owner !== null && (
                  <Grid item>
                    <Card
                      variant="elevation"
                      sx={{
                        border: 0.5,
                        borderColor: theme.palette.error.main,
                      }}
                    >
                      <CardHeader title="Mutable Split"></CardHeader>
                      <Divider />
                      {owner === address ? (
                        <CardContent>
                          <Stack spacing={2}>
                            <Typography paragraph={true}>
                              You have the control of this split
                            </Typography>
                            <Button
                              fullWidth
                              sx={{ textTransform: 'none' }}
                              variant="outlined"
                              onClick={() => setOpenUpdate(true)}
                            >
                              Update Split
                            </Button>
                            <Button
                              fullWidth
                              sx={{ textTransform: 'none' }}
                              variant="outlined"
                              onClick={() => setOpenTransfer(true)}
                            >
                              Transfer Control
                            </Button>
                            {split.newPotentialController && (
                              <Button
                                fullWidth
                                variant="outlined"
                                sx={{ textTransform: 'none' }}
                                onClick={cancelTransfer}
                              >
                                Cancel Control Transfer
                              </Button>
                            )}
                          </Stack>
                        </CardContent>
                      ) : split.newPotentialController === address ? (
                        <CardContent>
                          <Stack spacing={2}>
                            <Typography paragraph={true}>
                              You have been transferred control of this split
                            </Typography>
                            <Button
                              fullWidth
                              variant="outlined"
                              sx={{ textTransform: 'none' }}
                              onClick={acceptTransfer}
                            >
                              Accept Control Transfer
                            </Button>
                          </Stack>
                        </CardContent>
                      ) : (
                        <CardContent>
                          <Stack spacing={4}>
                            <Typography>
                              The controller can change this Split at any time.
                              Only trust this Split if you trust them.
                            </Typography>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{ mr: 2 }}>
                                Controller:
                              </Typography>
                              <AccountInfo
                                address={split.controller}
                                provider={ensProvider}
                                dense={true}
                                showMenu={false}
                                buttonProps={{
                                  onClick: () =>
                                    navigate(`/accounts/${split.controller}`),
                                }}
                              />
                            </Box>
                          </Stack>
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                )} */}
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Address"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack spacing={2} py={1}>
                        <Card>
                          <AccountInfo
                            address={params.accountID}
                            showMenu={false}
                            showButtonGroup={true}
                            openExplorer={false}
                            showAvatar={false}
                            truncate={false}
                            onCopy={() =>
                              enqueueSnackbar(
                                <MessageWithTransaction message="Address copied" />,
                              )
                            }
                            sx={{
                              background:
                                theme.palette.mode == 'light'
                                  ? theme.palette.grey[200]
                                  : theme.palette.grey[900],
                            }}
                            iconButtons={[
                              {
                                toolTipProps: {
                                  title: 'View QR Code',
                                },
                                startIcon: (
                                  <QrCodeIcon onClick={() => setOpenQR(true)} />
                                ),
                              },
                            ]}
                          />
                        </Card>
                        <Typography
                          paragraph={true}
                          sx={{ padding: '1rem 0rem' }}
                        >
                          Funds sent to this address will be split among the
                          recipients.
                        </Typography>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            fontWeight: 'bold',
                          }}
                          disabled={!isConnected}
                          onClick={() => setOpenDeposit(true)}
                        >
                          Deposit Funds
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isConnected}
                          onClick={() => setOpenVesting(true)}
                        >
                          New Vesting Module
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isConnected}
                          onClick={() => SetOpenDepositToken(true)}
                        >
                          Deposit CSR Token
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {receiving && receiving.length > 0 && (
                  <Grid item>
                    <Card variant="outlined">
                      <CardHeader title="Receiving from"></CardHeader>
                      <Divider />
                      <CardContent>
                        <Stack
                          sx={{ maxHeight: 500, overflowY: 'auto' }}
                          spacing={2}
                          divider={<Divider />}
                        >
                          {receiving &&
                            receiving.map((accountInfo, index) => (
                              <AccountInfo
                                key={`Receiving_${index}`}
                                dense={true}
                                {...accountInfo}
                              />
                            ))}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {address && liquid && isConnected && (
              <Modal
                open={openVesting}
                onClose={() => {
                  setOpenVesting(false)
                }}
                keepMounted={true}
                sx={{
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                }}
              >
                <Box sx={{ ...modalStyles, width: 600 }}>
                  {
                    <VestingForm
                      to={params.accountID}
                      onClose={() => {
                        setOpenVesting(false)
                      }}
                      refresh={() => {
                        const liquidClient = new LiquidSplitClient({
                          chainId: 7700,
                        })
                        setTimeout(() => {
                          queryLiquidSplit(liquidClient, liquid.id)
                        }, 3000)
                      }}
                    />
                  }
                </Box>
              </Modal>
            )}
            {address && isConnected && liquid && (
              <Modal
                open={openDeposit}
                onClose={() => {
                  setOpenDeposit(false)
                }}
                keepMounted={true}
                sx={{
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                }}
              >
                <Box sx={{ ...modalStyles }}>
                  {
                    <DepositForm
                      from={address}
                      to={params.accountID}
                      onClose={() => {
                        setOpenDeposit(false)
                      }}
                      refresh={() => {
                        if (signer && provider && liquid) {
                          const liquidClient = new LiquidSplitClient({
                            chainId: 7700,
                          })
                          setTimeout(() => {
                            queryLiquidSplit(liquidClient, liquid.id)
                          }, 3000)
                        }
                      }}
                    />
                  }
                </Box>
              </Modal>
            )}
          </Grid>
        </>
      )}
      {address && liquid && isConnected && (
        <Modal
          open={openDepositToken}
          onClose={() => {
            SetOpenDepositToken(false)
          }}
          keepMounted={true}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <Box sx={{ ...modalStyles, width: 600 }}>
            {
              <DepositTokenForm
                trigger={trigger}
                to={liquid.id}
                onClose={() => {
                  SetOpenDepositToken(false)
                }}
                refresh={() => {
                  SetTrigger((trigger) => trigger + 1)
                }}
              />
            }
          </Box>
        </Modal>
      )}
    </Container>
  )
}
export default LiquidAccount
