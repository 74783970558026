import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { AddressInput, Button } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import { useSigner, useContract } from 'wagmi'
import { CSR_VAULT_CONTRACT } from '../constants'
import { CSRVaultAbi } from '../abis/CSRValut'
import { BigNumber } from '@ethersproject/bignumber'

export const UpdateVaultRecipient: React.FC<{
  tokens: string[]
  onClose: () => void
  refresh: () => void
}> = ({ tokens, onClose, refresh }) => {
  const theme = useTheme()
  const [recipientAddress, setRecipientAddress] = React.useState('')
  const { data: signer } = useSigner()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const CSRValutContract = useContract({
    address: CSR_VAULT_CONTRACT,
    abi: CSRVaultAbi,
    signerOrProvider: signer,
  })

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const updateRecipient = async (tokenId: string) => {
    if (signer && CSRValutContract && isAddress(recipientAddress)) {
      const _tokenId = BigNumber.from(tokenId)
      let updateRecipientNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Updating recipient for token ${tokenId} ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const tx = await CSRValutContract.updateFeeRecipient(
          _tokenId,
          recipientAddress,
        )
        closeSnackbar(updateRecipientNotif)
        setTimeout(() => {
          updateRecipientNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Updating recipient for token ${tokenId} ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)
        await tx.wait()
        closeSnackbar(updateRecipientNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Updated recipient for token ${tokenId}`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
      } catch (err) {
        console.log(err)
        closeSnackbar(updateRecipientNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction message={`Error while updating token`} />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
    }
  }

  const updateTokensRecipient = async () => {
    onClose()
    await Promise.all(tokens.map(async (token) => updateRecipient(token)))
    refresh()
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="Update Recipient of tokens"></CardHeader>
        <Divider />
        <CardContent>
          <Typography color={theme.palette.text.primary} sx={{ pb: 4 }}>
            Update recipients of tokens: {tokens.join(', ')}
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="caption">Recipient</Typography>
              <AddressInput
                value={recipientAddress}
                setValue={(event) => setRecipientAddress(event)}
                placeholder={'0x0000...000 (e.g. split address)'}
                provider={ensProvider}
                cardProps={{ sx: { mt: 1 } }}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            px: 6,
            mb: 2,
          }}
        >
          <Button
            label="Update"
            onClick={updateTokensRecipient}
            disabled={!isAddress(recipientAddress)}
          />
        </CardActions>
      </Card>
    </Box>
  )
}
export default UpdateVaultRecipient
