import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { AddressInput, Button } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import { useProvider, useSigner } from 'wagmi'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'

export const TransferControl: React.FC<{
  splitId: string
  onClose: () => void
  refresh: () => void
}> = ({ splitId, onClose, refresh }) => {
  const theme = useTheme()
  const [loading, setLoading] = React.useState(false)
  const [controller, setController] = React.useState('')
  const { data: signer } = useSigner()
  const provider = useProvider()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const makeImmutable = () => {
    const initiate = async (splitsClient: SplitsClient) => {
      onClose()
      let immutableNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Making Split immutable ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const { tx: makeSplitImmutableTx } =
          await splitsClient.submitMakeSplitImmutableTransaction({
            splitId: splitId,
          })
        closeSnackbar(immutableNotif)
        setTimeout(() => {
          immutableNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Making Split immutable ...`}
              transactionId={makeSplitImmutableTx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)

        await makeSplitImmutableTx.wait()
        closeSnackbar(immutableNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Successfully made split immutable`}
              transactionId={makeSplitImmutableTx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        refresh()
      } catch (err) {
        console.log(err)
        closeSnackbar(immutableNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while making split immutable`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      } finally {
        setLoading(false)
      }
    }
    if (provider && signer) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
        signer,
      })
      setLoading(true)
      initiate(splitsClient)
    }
  }

  const transferControl = () => {
    const initiate = async (
      newController: string,
      splitsClient: SplitsClient,
    ) => {
      onClose()
      let transferNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Transferring control ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const { tx: transferSplitTx } =
          await splitsClient.submitInitiateControlTransferTransaction({
            splitId: splitId,
            newController: newController,
          })
        closeSnackbar(transferNotif)
        setTimeout(() => {
          transferNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Creating vesting module ...`}
              transactionId={transferSplitTx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)

        await transferSplitTx.wait()
        closeSnackbar(transferNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Successfully transferred control`}
              transactionId={transferSplitTx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        refresh()
      } catch (err) {
        console.log(err)
        closeSnackbar(transferNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while transfering control`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      } finally {
        setLoading(false)
      }
    }
    if (provider && signer && controller) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
        signer,
      })
      setLoading(true)
      initiate(controller, splitsClient)
    }
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="Transfer Control"></CardHeader>
        <Divider />
        <CardContent>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              minHeight={150}
            >
              <Stack sx={{ flexGrow: 2, pr: 2, width: '100%' }} spacing={2}>
                <Box>
                  <Typography variant="caption">New Controller:</Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <AddressInput
                      value={controller}
                      setValue={setController}
                      provider={ensProvider}
                      cardProps={{ sx: { width: '100%', mr: 2 } }}
                    />
                    <Button
                      variant="outlined"
                      label="clear"
                      onClick={() => setController('')}
                      sx={{ height: '100%', py: 1 }}
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  disabled={!isAddress(controller)}
                  label="Transfer control"
                  onClick={transferControl}
                />
                <Divider sx={{ py: 4 }} flexItem>
                  or
                </Divider>
                <Box sx={{ flexGrow: 1, pl: 2 }}>
                  <Button
                    variant="outlined"
                    sx={{ width: '100%' }}
                    label="Make Split Immutable"
                    onClick={makeImmutable}
                  />
                </Box>
              </Stack>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
export default TransferControl
