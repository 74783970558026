import React, { useEffect } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Stack,
  Typography,
  Box,
  LinearProgress,
  Modal,
} from '@mui/material'
import { Button, AccountInfo } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import { ButtonProps } from '@neobase-one/neobase-components/lib/components/Basic/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { FormatToken, MessageWithTransaction } from '../utils'
import { MetaDataContext } from '../context'
import { ethers } from 'ethers'
import { useProvider, useSigner, useContract, useAccount } from 'wagmi'
import { BigNumber } from '@ethersproject/bignumber'
import { CSRVaultAbi } from '../abis/CSRValut'
import { useSnackbar } from 'notistack'
import {
  CANTO_ADDRESS,
  CSR_VAULT_CONTRACT,
  CSR_VAULT_SUBGRAPH,
  TURNSTILE_CONTRACT,
} from '../constants'
import UpdateVaultRecipient from './UpdateVaultRecipient'

const CSRList: React.FC<{
  recipient?: string
  owner?: string
  trigger?: number
  hideCheckbox?: boolean
  hideButtons?: boolean
  showExpand?: boolean
  refresh: () => void
}> = ({
  recipient,
  owner,
  trigger,
  refresh,
  hideCheckbox,
  hideButtons,
  showExpand,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([])
  const [gridColumns, setGridColumns] = React.useState<GridColDef[]>()
  const [rows, setRows] = React.useState<any[]>()
  const [loading, setLoading] = React.useState(false)
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const provider = useProvider()
  const { data: signer } = useSigner()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const CSRValutContract = useContract({
    address: CSR_VAULT_CONTRACT,
    abi: CSRVaultAbi,
    signerOrProvider: signer,
  })
  const { address } = useAccount()
  const [openUpdateRecipient, SetOpenUpdateRecipient] =
    React.useState<boolean>(false)

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: 24,
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Token ID', minWidth: 90, flex: 1 },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            {...params.value}
            showMenu={false}
            dense={true}
            // showAvatar={false}
            truncate={false}
          />
        )
      },
    },
    {
      field: 'totalFeeWithdrawn',
      headerName: 'Fee Distributed',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            {...params.value}
            showMenu={false}
            dense={true}
            // showAvatar={false}
            truncate={false}
          />
        )
      },
    },
    {
      field: 'feeRecipeint',
      headerName: 'Recipient',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            address={params.value}
            dense={true}
            buttonProps={{
              onClick: () => navigate(`/accounts/${params.value}`),
            }}
          />
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            address={params.value}
            dense={true}
            buttonProps={{
              onClick: () => navigate(`/accounts/${params.value}`),
            }}
          />
        )
      },
    },
  ]

  const distributeToken = async (tokenId: string, _amount: BigNumber) => {
    const _tokenId = BigNumber.from(tokenId)
    console.log(_tokenId)
    if (CSRValutContract) {
      let distributeTokenNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Distributing rewards for token ${tokenId} ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const tx = await CSRValutContract.withdrawFee(_tokenId, _amount)
        console.log(tx)
        closeSnackbar(distributeTokenNotif)
        setTimeout(() => {
          distributeTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Distributing rewards for token ${tokenId} ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)
        await tx.wait()
        closeSnackbar(distributeTokenNotif)
        setTimeout(() => {
          distributeTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Distributed rewards for token ${tokenId}`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
      } catch (err) {
        console.log(err)
        closeSnackbar(distributeTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while distributing funds`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
    }
  }

  const distribute = async () => {
    if (rows) {
      const selectedRows = rows.filter((row) => selectionModel.includes(row.id))
      await Promise.all(
        selectedRows.map(async (row) =>
          distributeToken(row.id, row.balanceAmount),
        ),
      )
      setTimeout(() => {
        fetchList()
      }, 2000)
    }
  }

  const withdrawToken = async (tokenId: string) => {
    const _tokenId = BigNumber.from(tokenId)
    if (CSRValutContract) {
      let withdrawTokenNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Withdraing token ${tokenId} ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const tx = await CSRValutContract.withdrawNFT(_tokenId, address)
        console.log(tx)
        closeSnackbar(withdrawTokenNotif)
        setTimeout(() => {
          withdrawTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Withdraing token ${tokenId} ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)
        await tx.wait()
        closeSnackbar(withdrawTokenNotif)
        setTimeout(() => {
          withdrawTokenNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Withdrawn token ${tokenId}`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
      } catch (err) {
        console.log(err)
        closeSnackbar(withdrawTokenNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while withdrawing token`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
    }
  }

  const withdraw = async () => {
    if (rows) {
      const selectedRows = rows.filter((row) => selectionModel.includes(row.id))
      await Promise.all(selectedRows.map(async (row) => withdrawToken(row.id)))
      setTimeout(() => {
        fetchList()
        refresh()
      }, 2000)
    }
  }

  const fetchList = async () => {
    setLoading(true)
    const client = new ApolloClient({
      uri: CSR_VAULT_SUBGRAPH,
      cache: new InMemoryCache(),
    })
    let results: any = []
    if (owner) {
      const NFTQuery = gql`
        query MyQuery($owner: String!) {
          turnstileNFTs(where: { owner: $owner }) {
            id
            owner
            totalFeeWithdrawn
            feeRecipeint
          }
        }
      `
      results = (
        await client.query({
          query: NFTQuery,
          variables: { owner },
        })
      ).data['turnstileNFTs']
    } else if (recipient) {
      const NFTQuery = gql`
        query MyQuery($feeRecipeint: String!) {
          turnstileNFTs(where: { feeRecipeint: $feeRecipeint }) {
            id
            owner
            totalFeeWithdrawn
            feeRecipeint
          }
        }
      `
      results = (
        await client.query({
          query: NFTQuery,
          variables: { feeRecipeint: recipient },
        })
      ).data['turnstileNFTs']
    }
    const turnstileAbi = [
      'function balances(uint256) public view returns (uint256)',
    ]
    const turnstile = new ethers.Contract(
      TURNSTILE_CONTRACT,
      turnstileAbi,
      provider,
    )

    results = await Promise.all(
      results.map(async (row: any) => {
        const balance = await turnstile.balances(row.id)
        const newRow = {
          id: row.id,
          feeRecipeint: row.feeRecipeint,
          owner: row.owner,
          totalFeeWithdrawn: await FormatToken(
            useFetchMetadata,
            CANTO_ADDRESS,
            BigNumber.from(row.totalFeeWithdrawn),
          ),
          balance: await FormatToken(useFetchMetadata, CANTO_ADDRESS, balance),
          balanceAmount: balance,
        }
        // newRow.totalFeeWithdrawn =
        return newRow
      }),
    )
    setRows(results)
    setLoading(false)
  }

  React.useEffect(() => {
    setGridColumns(columns)
    fetchList()
  }, [recipient, owner, trigger])

  const buttons: ButtonProps[] = []
  if (!hideButtons) {
    if (showExpand) {
      buttons.push({
        label: 'Expand',
        onClick: () => navigate('/vault'),
      })
    } else {
      buttons.push({
        label: 'Distribute',
        toolTipProps: {
          title: 'Distribute CSR rewards to recipient',
        },
        onClick: distribute,
      })

      if (owner) {
        buttons.push(
          {
            label: 'Withdraw',
            toolTipProps: {
              title: 'Withdraw CSR token from vault',
            },
            onClick: withdraw,
          },
          {
            label: 'Update',
            toolTipProps: {
              title: 'Update recipient',
            },
            onClick: () => {
              SetOpenUpdateRecipient(true)
            },
          },
        )
      }
    }
  }

  return (
    <Grid container>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardHeader
          title={'CSR Vault Tokens'}
          action={
            buttons && (
              <Stack direction="row" spacing={2}>
                {buttons.map((button, index) => (
                  <Button
                    variant="outlined"
                    disabled={
                      button.label != 'Expand' &&
                      (signer === undefined ||
                        selectionModel === undefined ||
                        selectionModel.length == 0)
                    }
                    key={`TableButton_${index}`}
                    {...button}
                  />
                ))}
              </Stack>
            )
          }
        />
        <Divider />
        <CardContent sx={{ p: 0, ':last-child': { p: 0 } }}>
          {gridColumns && rows && !loading ? (
            <>
              {rows.length ? (
                <DataGrid
                  autoHeight
                  columns={gridColumns}
                  rows={rows}
                  // getRowId={(row) => row.tokenId}
                  checkboxSelection={hideCheckbox ? false : true}
                  disableSelectionOnClick
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                  }}
                  selectionModel={selectionModel}
                  columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    owner: owner === undefined,
                    feeRecipeint: recipient === undefined,
                  }}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              ) : (
                <>
                  {owner && (
                    <Box sx={{ m: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>
                        {address && owner == address
                          ? 'You have no tokens deposited in the vault'
                          : 'Account has no tokens deposited in the vault'}
                      </Typography>
                    </Box>
                  )}
                  {recipient && (
                    <Box sx={{ m: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>
                        No tokens deposited in the vault with this address as
                        recipient
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </CardContent>
      </Card>
      {address && rows && selectionModel.length > 0 && (
        <Modal
          open={openUpdateRecipient}
          onClose={() => {
            SetOpenUpdateRecipient(false)
          }}
          keepMounted={true}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <Box sx={{ ...modalStyles, width: 600 }}>
            {
              <UpdateVaultRecipient
                tokens={rows
                  .filter((row) => selectionModel.includes(row.id))
                  .map((row) => row.id)}
                onClose={() => {
                  SetOpenUpdateRecipient(false)
                }}
                refresh={() => {
                  setTimeout(() => {
                    fetchList()
                  }, 1000)
                }}
              />
            }
          </Box>
        </Modal>
      )}
    </Grid>
  )
}

export default CSRList
