import React from 'react'
import {
  SplitsClient,
  WaterfallClient,
  VestingClient,
  VestingModule,
  fetchAllTokenBalances,
} from '@neobase-one/splits-sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import PaymentsIcon from '@mui/icons-material/Payments'
import GroupIcon from '@mui/icons-material/Group'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import EditIcon from '@mui/icons-material/Edit'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { formatUnits } from 'ethers/lib/utils.js'
import { TokenData } from '@neobase-one/splits-sdk'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { CANTO_ADDRESS, WCANTO_ADDRESS } from './constants'
import { NavigateFunction } from 'react-router-dom'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import AccountLabel from './components/AccountLabel'
import {
  WaterfallModule,
  Split,
  LiquidSplit,
} from '@neobase-one/splits-sdk-react'

const EmptyAccountInfo: AccountInfoProps = {
  address: '',
  amount: '',
  avatarSrc: '',
  key: '',
}

const FormatToken = async (
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
  token_key: string,
  amountString: BigNumber,
) => {
  const metaData = await useFetchMetadata(token_key)
  const amount = Number(formatUnits(amountString, metaData.decimal)).toFixed(3)
  return {
    address: `${amount} ${metaData.symbol}`,
    subinfo: metaData.price
      ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
      : 'NA',
    avatarSrc:
      token_key == CANTO_ADDRESS || token_key == WCANTO_ADDRESS
        ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
        : metaData.image,
    key: token_key,
  } as AccountInfoProps
}

const getSplitEarningsInfo = async (
  splitsClient: SplitsClient,
  splitId: string,
  includeActiveBalances: boolean,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  const earnings = await splitsClient.getSplitEarnings({
    splitId: splitId,
    includeActiveBalances: includeActiveBalances,
  })
  let activeAmount = 0
  let distributedAmount = 0

  const activeBalances = await Promise.all(
    Object.keys(earnings.activeBalances!).map(async (key) => {
      const metaData = await useFetchMetadata(key)
      const amount = Number(
        formatUnits(
          BigNumber.from(earnings.activeBalances![key]),
          metaData.decimal,
        ),
      ).toFixed(3)
      activeAmount += Number(amount) * Number(metaData.price)
      return {
        address: `${amount} ${metaData.symbol}`,
        amount: metaData.price
          ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
          : 'NA',
        avatarSrc:
          key == CANTO_ADDRESS || key == WCANTO_ADDRESS
            ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
            : metaData.image,
        key: key,
      } as AccountInfoProps
    }),
  )

  const distributions = await Promise.all(
    Object.keys(earnings.distributed!).map(async (key) => {
      const metaData = await useFetchMetadata(key)
      const amount = Number(
        formatUnits(
          BigNumber.from(earnings.distributed![key]),
          metaData.decimal,
        ),
      ).toFixed(3)
      distributedAmount += Number(amount) * Number(metaData.price)
      return {
        address: `${amount} ${metaData.symbol}`,
        amount: metaData.price
          ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
          : 'NA',
        avatarSrc:
          key == CANTO_ADDRESS || key == WCANTO_ADDRESS
            ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
            : metaData.image,
      } as AccountInfoProps
    }),
  )

  const totalAmount = (activeAmount + distributedAmount).toFixed(2)
  return {
    activeAmount: activeAmount.toFixed(5),
    activeBalances,
    distributedAmount: distributedAmount.toFixed(5),
    distributions,
    totalAmount,
  }
}

const getActiveBalances = async (
  address: string,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  let activeAmount = 0
  const availableTokens = await fetchAllTokenBalances(address)
  const tokens = await Promise.all(
    availableTokens
      .filter((token) => token.balance != '0')
      .map(async (token: any) => {
        try {
          const metaData = await useFetchMetadata(token.contractAddress)
          const amount = Number(
            formatUnits(BigNumber.from(token.balance), metaData.decimal),
          )
          activeAmount += Number(amount) * Number(metaData.price)
          return {
            address: token.symbol,
            amount: `${amount.toFixed(3)} remaining`,
            avatarSrc:
              token.contractAddress == CANTO_ADDRESS ||
              token.contractAddress == WCANTO_ADDRESS
                ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
                : metaData.image,
            key: token.contractAddress,
          } as AccountInfoProps
        } catch {
          return EmptyAccountInfo
        }
      }),
  )
  const activeBalances: AccountInfoProps[] = tokens.filter(
    (token) => token.address != '',
  )
  return { activeBalances, activeAmount }
}

const getVestingEarningsInfo = async (
  vestingClient: VestingClient,
  vestingModule: VestingModule,
  theme: Theme,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  let activeAmount = 0
  let distributedAmount = 0
  let totalReleased = 0

  const combinedStreamAmount = vestingModule.streams?.reduce(
    (combined, stream) => {
      const address = stream.token.address.toLowerCase()
      if (address in combined) {
        combined[address] =
          combined[address] + (stream.totalAmount - stream.releasedAmount)
      } else {
        combined[address] = stream.totalAmount - stream.releasedAmount
      }
      return combined
    },
    {} as { [id: string]: number },
  )

  const availableTokens = await fetchAllTokenBalances(vestingModule.id)
  const tokens = await Promise.all(
    availableTokens
      .filter((token) => token.balance != '0')
      .map(async (token: any) => {
        try {
          const metaData = await useFetchMetadata(token.contractAddress)
          let amount = Number(
            formatUnits(BigNumber.from(token.balance), metaData.decimal),
          )
          if (
            combinedStreamAmount &&
            token.contractAddress in combinedStreamAmount
          ) {
            amount = amount - combinedStreamAmount[token.contractAddress]
            if (amount <= 0) {
              return EmptyAccountInfo
            }
          }
          activeAmount += amount * Number(metaData.price)
          return {
            address: `${amount.toFixed(3)} ${token.symbol}`,
            amount: metaData.price
              ? `$${(amount * Number(metaData.price)).toFixed(5)}`
              : 'NA',
            avatarSrc:
              token.contractAddress == CANTO_ADDRESS ||
              token.contractAddress == WCANTO_ADDRESS
                ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
                : metaData.image,
            key: token.contractAddress,
          } as AccountInfoProps
        } catch {
          return EmptyAccountInfo
        }
      }),
  )
  const activeBalances: AccountInfoProps[] = tokens.filter(
    (token) => token.address != '',
  )

  const distributions = await Promise.all(
    vestingModule.streams!.map(async (stream) => {
      const key = stream.token.address
      const metaData = await useFetchMetadata(key)
      const { amount: vestedAmount } = await vestingClient.getVestedAmount({
        vestingModuleId: vestingModule.id,
        streamId: `${stream.streamId}`,
      })
      const { amount: unreleasedAmount } =
        await vestingClient.getVestedAndUnreleasedAmount({
          vestingModuleId: vestingModule.id,
          streamId: `${stream.streamId}`,
        })
      const vestedAmountNum = Number(
        formatUnits(BigNumber.from(vestedAmount), metaData.decimal),
      )
      const unreleasedAmountNum = Number(
        formatUnits(BigNumber.from(unreleasedAmount), metaData.decimal),
      )
      const vested = (unreleasedAmountNum * 100) / stream.totalAmount
      const released = (stream.releasedAmount * 100) / stream.totalAmount
      const unvested =
        100 - Number(vested.toFixed(2)) - Number(released.toFixed(2))
      const amount = stream.totalAmount
      if (stream.releasedAmount != stream.totalAmount) {
        distributedAmount += amount * Number(metaData.price)
      } else {
        totalReleased += amount * Number(metaData.price)
      }
      return {
        address: `${amount} ${metaData.symbol}`,
        amount: metaData.price
          ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
          : 'NA',
        toolTipProps: {
          title: (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="caption">
                  Fully vested on{' '}
                  {moment
                    .unix(stream.startTime)
                    .add(vestingModule.vestingPeriod, 'seconds')
                    .format('DD-MMM-YYYY')}
                </Typography>
                <Divider />
                <Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      py: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          theme.palette.mode == 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[900],
                        width: 12,
                        height: 12,
                      }}
                    >
                      <></>
                    </Avatar>
                    <Typography variant="caption" sx={{ pl: 2 }}>
                      Unvested
                    </Typography>
                    <Box flexGrow={1} />
                    <Typography variant="caption">
                      {unvested.toFixed(2)}%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      py: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        width: 12,
                        height: 12,
                      }}
                    >
                      <></>
                    </Avatar>
                    <Typography variant="caption" sx={{ pl: 2 }}>
                      Vested
                    </Typography>
                    <Box flexGrow={1} />
                    <Typography variant="caption">
                      {vested.toFixed(2)}%
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      py: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.success.dark,
                        width: 12,
                        height: 12,
                      }}
                    >
                      <></>
                    </Avatar>
                    <Typography variant="caption" sx={{ pl: 2 }}>
                      Released
                    </Typography>
                    <Box flexGrow={1} />
                    <Typography variant="caption">
                      {released.toFixed(2)}%
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          ),
          slotProps: {
            tooltip: {
              sx: {
                background: theme.palette.background.paper,
              },
            },
          },
          arrow: true,
        },
        subnode: (
          <LinearProgress
            variant="buffer"
            sx={{
              '& .MuiLinearProgress-dashed': {
                background:
                  theme.palette.mode == 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
                animation: 'none',
              },
              '& .MuiLinearProgress-bar1Buffer': {
                background: theme.palette.success.dark,
              },
              '& .MuiLinearProgress-bar2Buffer': {
                background: theme.palette.primary.main,
              },
              mr: 10,
            }}
            value={released}
            valueBuffer={released + vested}
          />
        ),
        key: stream.streamId,
        avatarSrc:
          key == CANTO_ADDRESS || key == WCANTO_ADDRESS
            ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
            : metaData.image,
      } as AccountInfoProps
    }),
  )

  const activeStreams = distributions.map(
    (streamInfo, index) =>
      vestingModule.streams![index].releasedAmount !=
      vestingModule.streams![index].totalAmount,
  )

  const totalAmount = (
    activeAmount +
    distributedAmount +
    totalReleased
  ).toFixed(2)
  return {
    activeAmount: activeAmount.toFixed(5),
    activeBalances,
    distributedAmount: distributedAmount.toFixed(5),
    totalReleased: totalReleased.toFixed(5),
    distributions,
    activeStreams,
    totalAmount,
  }
}

const getWaterfallEarningsInfo = async (
  waterfallClient: WaterfallClient,
  waterfallModuleId: string,
  token: any,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  const { distributedFunds } = await waterfallClient.getDistributedFunds({
    waterfallModuleId,
  })
  const { fundsPendingWithdrawal } =
    await waterfallClient.getFundsPendingWithdrawal({ waterfallModuleId })
  const distributedAmount = Number(formatEther(distributedFunds))
  const activeAmount = Number(formatEther(fundsPendingWithdrawal))
  const totalAmount = (activeAmount + distributedAmount).toFixed(3)

  const metaData = await useFetchMetadata(token.address)

  const distributedBalances =
    distributedAmount > 0
      ? [
          {
            address: `${distributedAmount.toFixed(3)} ${token.symbol}`,
            amount: metaData.price
              ? `$${(
                  Number(distributedAmount) * Number(metaData.price)
                ).toFixed(5)}`
              : 'NA',
            avatarSrc:
              token.address == CANTO_ADDRESS || token.address == WCANTO_ADDRESS
                ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
                : metaData.image,
            key: token.address,
          } as AccountInfoProps,
        ]
      : []
  const activeBalances =
    activeAmount > 0
      ? [
          {
            address: `${activeAmount.toFixed(3)} ${token.symbol}`,
            amount: metaData.price
              ? `$${(Number(activeAmount) * Number(metaData.price)).toFixed(5)}`
              : 'NA',
            avatarSrc:
              token.address == CANTO_ADDRESS || token.address == WCANTO_ADDRESS
                ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
                : metaData.image,
            key: token.address,
          } as AccountInfoProps,
        ]
      : []
  return {
    activeAmount: activeAmount.toFixed(5),
    activeBalances,
    distributedAmount: distributedAmount.toFixed(5),
    distributedBalances,
    totalAmount,
  }
}

const getUserEarningsInfo = async (
  splitsClient: SplitsClient,
  userId: string,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  try {
    const earnings = await splitsClient.getUserEarnings({
      userId: userId,
    })

    let activeAmount = 0
    let withdrawnAmount = 0

    const activeBalances = await Promise.all(
      Object.keys(earnings.activeBalances!).map(async (key) => {
        const metaData = await useFetchMetadata(key)
        const amount = Number(
          formatUnits(
            BigNumber.from(earnings.activeBalances![key]),
            metaData.decimal,
          ),
        ).toFixed(3)
        activeAmount += Number(amount) * Number(metaData.price)
        return {
          address: `${amount} ${metaData.symbol}`,
          amount: metaData.price
            ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
            : 'NA',
          avatarSrc:
            key == CANTO_ADDRESS || key == WCANTO_ADDRESS
              ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
              : metaData.image,
          key: key,
        } as AccountInfoProps
      }),
    )

    const withdrawnBalances = await Promise.all(
      Object.keys(earnings.withdrawn!).map(async (key) => {
        const metaData = await useFetchMetadata(key)
        const amount = Number(
          formatUnits(
            BigNumber.from(earnings.withdrawn![key]),
            metaData.decimal,
          ),
        ).toFixed(3)
        withdrawnAmount += Number(amount) * Number(metaData.price)
        return {
          address: `${amount} ${metaData.symbol}`,
          amount: metaData.price
            ? `$${(Number(amount) * Number(metaData.price)).toFixed(5)}`
            : 'NA',
          avatarSrc:
            key == CANTO_ADDRESS || key == WCANTO_ADDRESS
              ? `${process.env.PUBLIC_URL}/assets/canto-token.png`
              : metaData.image,
          key: key,
        } as AccountInfoProps
      }),
    )

    const totalAmount = (activeAmount + withdrawnAmount).toFixed(2)
    return {
      activeAmount: activeAmount.toFixed(5),
      activeBalances,
      withdrawnAmount: withdrawnAmount.toFixed(5),
      withdrawnBalances,
      totalAmount,
      tokens: new Set(
        activeBalances
          .map((token) => token.key)
          .concat(withdrawnBalances.map((token) => token.key)),
      ).size,
    }
  } catch (err) {
    return { error: true }
  }
}

const DistributionEvent = async (
  event: any,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  const tokenId = event.token.id
  const metaData = await useFetchMetadata(tokenId)
  const text = `Distributed ${Number(
    formatUnits(BigNumber.from(event.amount), metaData.decimal),
  ).toFixed(3)} ${metaData.symbol?.toUpperCase()}`
  return {
    avatar: <PaymentsIcon sx={{ color: '#7c52d9' }} />,
    text: text,
  }
}

const SetSplitEvent = (event: any) => {
  return {
    avatar:
      event.type === 'create' ? (
        <GroupIcon sx={{ color: '#3b82f6' }} />
      ) : (
        <EditIcon sx={{ color: '#eab308' }} />
      ),
    text: event.type === 'create' ? 'Split Created' : 'Split Updated',
  }
}

const ControlTransferEvent = (event: any) => {
  return {
    avatar: <SwapHorizIcon sx={{ color: '#3b82f6' }} />,
    text: `Control Transferred from ${event.fromUserEvent.account.id} to ${event.toUserEvent.account.id}`,
  }
}

const RecipientAddedEvent = () => {
  return {
    avatar: <PersonAddIcon />,
    text: 'Added to Split',
  }
}

const RecipientRemovedEvent = () => {
  return {
    avatar: <PersonRemoveIcon />,
    text: 'Removed from Split',
  }
}

const VestedReceive = () => {
  return {
    avatar: <PaymentsIcon sx={{ color: '#6efea0' }} />,
    text: 'Received vested funds',
  }
}

const DefaultEvent = () => {
  return {
    avatar: <CalendarTodayIcon />,
    text: 'Split Event',
  }
}

const getEventData = async (
  event: any,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
) => {
  let ret
  switch (event.__typename) {
    case 'DistributionEvent':
      ret = await DistributionEvent(event, useFetchMetadata)
      break
    case 'SetSplitEvent':
      ret = SetSplitEvent(event)
      break
    case 'ControlTransferEvent':
      ret = ControlTransferEvent(event)
      break
    case 'RecipientAddedEvent':
      ret = RecipientAddedEvent()
      break
    case 'RecipientRemovedEvent':
      ret = RecipientRemovedEvent()
      break
    case 'ReceiveVestedFundsEvent':
      ret = VestedReceive()
      break
    default:
      ret = DefaultEvent()
  }
  ret = { ...ret, time: moment.unix(event.timestamp).fromNow() }
  return ret
}

const MessageWithTransaction: React.FC<{
  message: string
  transactionId?: string
  description?: string
}> = ({ message, transactionId, description }) => {
  const theme = useTheme()

  return (
    <Stack>
      <Typography>{message}</Typography>
      {transactionId && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={1.5}
          onClick={() =>
            window.open('https://tuber.build/tx/' + transactionId, '_blank')
          }
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            variant="caption"
            sx={{ color: theme.palette.text.disabled }}
          >
            view transaction
          </Typography>
          <OpenInNewIcon
            sx={{ fontSize: 15, color: theme.palette.text.disabled }}
          />
        </Box>
      )}
      {description && (
        <Typography
          sx={{ color: theme.palette.text.disabled }}
          variant="caption"
        >
          {description}
        </Typography>
      )}
    </Stack>
  )
}

async function getWaterfallAccountInfo(
  waterfall: WaterfallModule,
  splitsClient: SplitsClient,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
  showWaterfallTag: boolean,
  navigate: NavigateFunction,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
) {
  const { totalAmount } = await getWaterfallEarningsInfo(
    splitsClient.waterfall!,
    waterfall.id,
    waterfall.token,
    useFetchMetadata,
  )

  return {
    address: waterfall.id,
    subinfo: `${waterfall.tranches.length} tranches`,
    amount: `$${Number(totalAmount).toFixed(2)}`,
    startElement: showWaterfallTag ? (
      <AccountLabel dense type="Waterfall" />
    ) : undefined,
    buttonProps: {
      onClick: () => navigate(`/accounts/${waterfall.id}`),
    },
    onCopy: () =>
      enqueueSnackbar(<MessageWithTransaction message="Address copied" />),
  } as AccountInfoProps
}

async function getSplitAccountInfo(
  splitAccount: Split,
  splitsClient: SplitsClient,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
  showWaterfallTag: boolean,
  navigate: NavigateFunction,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
) {
  const { totalAmount } = await getSplitEarningsInfo(
    splitsClient,
    splitAccount.id,
    true,
    useFetchMetadata,
  )
  return {
    address: splitAccount.id,
    subinfo: `${splitAccount.recipients.length} recipients`,
    amount: `$${totalAmount}`,
    startElement: (
      <AccountLabel
        dense
        type={
          splitAccount.controller == null ? 'Immutable split' : 'Mutable split'
        }
      />
    ),
    buttonProps: { onClick: () => navigate(`/accounts/${splitAccount.id}`) },
    onCopy: () =>
      enqueueSnackbar(<MessageWithTransaction message="Address copied" />),
  } as AccountInfoProps
}

async function getLiquidSplitAccountInfo(
  liquidAccount: LiquidSplit,
  splitsClient: SplitsClient,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
  showWaterfallTag: boolean,
  navigate: NavigateFunction,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
) {
  const { totalAmount } = await getSplitEarningsInfo(
    splitsClient,
    liquidAccount.id,
    true,
    useFetchMetadata,
  )
  return {
    address: liquidAccount.id,
    subinfo: `${liquidAccount.holders.length} recipients`,
    amount: `$${totalAmount}`,
    startElement: <AccountLabel dense type="Liquid split" />,
    buttonProps: { onClick: () => navigate(`/accounts/${liquidAccount.id}`) },
    onCopy: () =>
      enqueueSnackbar(<MessageWithTransaction message="Address copied" />),
  } as AccountInfoProps
}

async function queryRelated(
  splitsClient: SplitsClient,
  splitId: string,
  useFetchMetadata: (token_key: string) => Promise<TokenData>,
  showWaterfallTag: boolean,
  navigate: NavigateFunction,
  theme: Theme,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
) {
  const relatedSplits = await splitsClient.getRelatedSplits({
    address: splitId,
  })
  const relatedWaterfalls = await splitsClient.waterfall!.getRelatedWaterfalls({
    address: splitId,
  })
  const relatedLiquidSplits =
    await splitsClient.liquidSplits!.getRelatedLiquidSplits({
      address: splitId,
    })
  const relatedVestingModules = await splitsClient.vesting!.getRelatedVesting({
    beneficiaryId: splitId,
  })
  const vestingModules = await Promise.all(
    relatedVestingModules.map(async (vestingModule) => {
      const { totalAmount } = await getVestingEarningsInfo(
        splitsClient.vesting!,
        vestingModule,
        theme,
        useFetchMetadata,
      )
      return {
        address: vestingModule.id,
        subinfo: `${
          vestingModule.streams ? vestingModule.streams.length : 0
        } streams`,
        amount: `$${Number(totalAmount).toFixed(2)}`,
        startElement: <AccountLabel dense type="Vesting module" />,
        buttonProps: {
          onClick: () => navigate(`/accounts/${vestingModule.id}`),
        },
        onCopy: () =>
          enqueueSnackbar(<MessageWithTransaction message="Address copied" />),
      } as AccountInfoProps
    }),
  )

  const waterfallCount = relatedWaterfalls.receivingFrom.length
  const splitCount =
    relatedSplits.receivingFrom.length +
    relatedSplits.controlling.length +
    relatedSplits.pendingControl.length +
    relatedLiquidSplits.receivingFrom.length
  const waterfalls = await Promise.all(
    relatedWaterfalls.receivingFrom.map(async (waterfall) => {
      return await getWaterfallAccountInfo(
        waterfall,
        splitsClient,
        useFetchMetadata,
        showWaterfallTag,
        navigate,
        enqueueSnackbar,
      )
    }),
  )
  const donors = await Promise.all(
    relatedSplits.receivingFrom.map(async (donor) => {
      return await getSplitAccountInfo(
        donor,
        splitsClient,
        useFetchMetadata,
        showWaterfallTag,
        navigate,
        enqueueSnackbar,
      )
    }),
  )
  const liquidDonors = await Promise.all(
    relatedLiquidSplits.receivingFrom.map(async (donor) => {
      return await getLiquidSplitAccountInfo(
        donor,
        splitsClient,
        useFetchMetadata,
        showWaterfallTag,
        navigate,
        enqueueSnackbar,
      )
    }),
  )

  const controlling = await Promise.all(
    relatedSplits.controlling.map(async (split) => {
      return await getSplitAccountInfo(
        split,
        splitsClient,
        useFetchMetadata,
        showWaterfallTag,
        navigate,
        enqueueSnackbar,
      )
    }),
  )

  const transferred = await Promise.all(
    relatedSplits.pendingControl.map(async (pending) => {
      return await getSplitAccountInfo(
        pending,
        splitsClient,
        useFetchMetadata,
        showWaterfallTag,
        navigate,
        enqueueSnackbar,
      )
    }),
  )
  return {
    transferred,
    controlling,
    donors,
    liquidDonors,
    waterfalls,
    splitCount,
    waterfallCount,
    vestingModules,
  }
}

export {
  getSplitEarningsInfo,
  getActiveBalances,
  getVestingEarningsInfo,
  getWaterfallEarningsInfo,
  getUserEarningsInfo,
  getEventData,
  FormatToken,
  getWaterfallAccountInfo,
  getSplitAccountInfo,
  getLiquidSplitAccountInfo,
  MessageWithTransaction,
  queryRelated,
}
