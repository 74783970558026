import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  Input,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  AddressInput,
  Button,
  IconButton,
  ToggleButtonGroup,
  PercentageInput,
} from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { ethers } from 'ethers'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Papa from 'papaparse'
import { isAddress } from 'ethers/lib/utils.js'

interface RecipientsData {
  address: string
  percentAllocation: number
}

export interface SplitsData {
  recipients: RecipientsData[]
  distributorFeePercent: number
  controller?: string
}

export const SplitsForm: React.FC<{
  data: SplitsData
  setDataHandler: (data: SplitsData) => void
  cardProps?: CardProps
  decimals: number
}> = ({ data, setDataHandler, cardProps, decimals }) => {
  const theme = useTheme()
  const [view, setView] = React.useState<'table' | 'csv'>('table')
  const [sum, setSum] = React.useState(0.0)
  const [validatingCsv, setValidatingCsv] = React.useState(false)
  const [validCSV, setValidCSV] = React.useState(false)
  const [csvText, setCsvText] = React.useState('')
  const [ensNamesMap, setEnsNamesMap] = React.useState<
    Map<string, Promise<string | null>>
  >(new Map())
  const csvUploadRef = React.useRef<HTMLInputElement>(null)
  const [empty, setEmpty] = React.useState<any[]>([])
  const handleSetView = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'table' | 'csv' | null,
  ) => {
    if (newView !== null) {
      setView(newView)
    }
  }
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  React.useEffect(() => {
    const newSum = data.recipients.reduce((curr, next) => {
      return Number((curr + next.percentAllocation).toFixed(decimals))
    }, 0)
    setSum(newSum)
  }, [data, decimals])
  React.useEffect(() => {
    const newEmpty = data.recipients
      .map((recipient, index) =>
        recipient.percentAllocation <= 0 ? index : null,
      )
      .filter((index) => index !== null)
    setEmpty(newEmpty)
  }, [data])

  const useEnsResolve = (ensText: string): Promise<string | null> => {
    if (!ensNamesMap.has(ensText)) {
      setEnsNamesMap(ensNamesMap.set(ensText, ensProvider.resolveName(ensText)))
    }

    return ensNamesMap.get(ensText)!
  }

  React.useEffect(() => {
    const validateCsv = async () => {
      if (csvText) {
        const parsed = Papa.parse(csvText)
        if (!parsed.errors.length) {
          if (parsed.data.length && (parsed.data[0] as string[]).length == 2) {
            const newRecipients = await Promise.all(
              (parsed.data as string[][]).map(async (row) => {
                if (!parseFloat(row[1])) {
                  return null
                }
                if (isAddress(row[0])) {
                  return {
                    address: row[0],
                    percentAllocation: parseFloat(row[1]),
                  }
                }
                const newAddress = await useEnsResolve(row[0])
                if (newAddress !== null) {
                  return {
                    address: newAddress,
                    percentAllocation: parseFloat(row[1]),
                  }
                }
                return null
              }),
            )
            const filtered: RecipientsData[] = newRecipients.map((recipient) =>
              recipient ? recipient : { address: '', percentAllocation: 0 },
            )
            if (newRecipients.every((recipient) => recipient !== null)) {
              setDataHandler({
                ...data,
                recipients: filtered,
              })
              setValidCSV(true)
              setValidatingCsv(false)
              return
            }
          }
        }
      }
      setDataHandler({
        ...data,
        recipients: [
          { address: '', percentAllocation: 0 },
          { address: '', percentAllocation: 0 },
        ],
      })
      setValidCSV(false)
      setValidatingCsv(false)
    }
    setValidatingCsv(true)
    validateCsv()
  }, [csvText])

  const toDistribute = (amount: number, accounts: number) => {
    amount = Math.floor(amount * Math.pow(10, decimals))
    const split = Math.floor(amount / accounts)
    let remaining = amount - split * accounts
    let splits = Array(accounts).fill(split)
    splits = splits.map((val) => {
      if (remaining) {
        remaining = remaining - 1
        return Number(
          ((val + 1) / Math.floor(Math.pow(10, decimals))).toFixed(
            decimals ? decimals : 2,
          ),
        )
      }
      return Number(
        (val / Math.floor(Math.pow(10, decimals))).toFixed(
          decimals ? decimals : 2,
        ),
      )
    })
    return splits
  }

  const handleUploadCsv = () => {
    if (csvUploadRef.current) {
      csvUploadRef.current.click()
    }
  }

  const onUploadCsv = () => {
    if (csvUploadRef.current) {
      const { files } = csvUploadRef.current
      if (files?.length) {
        const fileReader = new FileReader()
        fileReader.onloadend = (e) => {
          const content = fileReader.result
          if (content && typeof content == 'string') {
            setCsvText(content)
          }
        }
        fileReader.readAsText(files[0])
      }
    }
  }

  return (
    <Box>
      <Card variant="outlined" {...cardProps}>
        <CardHeader
          title="Recipients"
          action={
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleSetView}
              fullWidth
              color="primary"
              sx={{ minWidth: 250, pr: 2 }}
              options={[
                {
                  value: 'table',
                  children: 'Table View',
                },
                {
                  value: 'csv',
                  children: 'CSV Upload',
                },
              ]}
              buttonProps={{
                sx: {
                  textTransform: 'none',
                  p: 0,
                  fontSize: '0.75rem',
                },
              }}
            />
          }
        ></CardHeader>
        <Divider />
        {view == 'table' ? (
          <CardContent>
            <Typography sx={{ pb: 3 }}>
              Enter each recipient's address and their share. Ownership must add
              to 100%.
            </Typography>
            <Stack>
              {data.recipients.map((accountInfo, index) => (
                <Box
                  sx={{ pt: 2 }}
                  display="flex"
                  flexDirection="row"
                  key={index}
                >
                  <Box flexGrow={1}>
                    <AddressInput
                      value={accountInfo.address}
                      setValue={(event) => {
                        const newRecipients = data.recipients
                        newRecipients[index].address = event
                        setDataHandler({ ...data, recipients: newRecipients })
                      }}
                      placeholder={
                        index == 0 ? 'ethglobal.eth' : '0x0000...000'
                      }
                      provider={ensProvider}
                    />
                  </Box>
                  <PercentageInput
                    placeholder={decimals === 2 ? '0.00%' : '0.0%'}
                    value={accountInfo.percentAllocation}
                    decimals={decimals}
                    setValue={(val) => {
                      const newRecipients = data.recipients
                      newRecipients[index].percentAllocation = Math.max(
                        0,
                        Math.min(100, val),
                      )
                      setDataHandler({ ...data, recipients: newRecipients })
                    }}
                    inputProps={{ sx: { py: 2 } }}
                    sx={{ width: 200, border: 0, px: 2 }}
                    cardProps={{ sx: { ml: 2 } }}
                  />
                  <IconButton
                    sx={{ ml: 2 }}
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={() => {
                      const newRecipients = data.recipients
                      newRecipients.splice(index, 1)
                      setDataHandler({ ...data, recipients: newRecipients })
                    }}
                  />
                </Box>
              ))}
            </Stack>
          </CardContent>
        ) : (
          <CardContent>
            <Typography>
              Paste or upload a CSV. Ownership must add to 100.
            </Typography>
            <input
              hidden
              type="file"
              name="file"
              accept=".csv"
              ref={csvUploadRef}
              onChange={onUploadCsv}
            />
            <TextField
              multiline
              rows={6}
              fullWidth
              sx={{ pt: 2, fontFamily: 'IBM Plex Mono', fontSize: '0.85rem' }}
              inputProps={{
                sx: { fontFamily: 'IBM Plex Mono', fontSize: '0.85rem' },
              }}
              value={csvText}
              placeholder={
                'ethglobal.eth,50\n0xBab97Cd8f5224B63Ed140744eCE43105538B0E55,50'
              }
              onChange={(event) => setCsvText(event.target.value)}
            />
          </CardContent>
        )}
        {view == 'table' ? (
          <CardActions sx={{ px: 4, pb: 2 }}>
            <Button
              label="Add Recipient"
              variant="outlined"
              onClick={() => {
                const newRecipients = data.recipients
                newRecipients.push({ address: '', percentAllocation: 0 })
                setDataHandler({ ...data, recipients: newRecipients })
              }}
            />
            {/* <Button label="Add Donation" variant="outlined" /> */}
            <Box flexGrow={1}></Box>
            <Button
              label="Split Remaining"
              variant="outlined"
              disabled={empty.length === 0}
              onClick={() => {
                if (empty.length) {
                  const splits = toDistribute(
                    parseFloat((100 - sum).toFixed(decimals ? decimals : 2)),
                    empty.length,
                  )
                  const newRecipients = data.recipients
                  empty.forEach(
                    (index, key) =>
                      (newRecipients[index].percentAllocation = splits[key]),
                  )
                  setDataHandler({ ...data, recipients: newRecipients })
                }
              }}
            />
            <Button
              label="Split Evenly"
              variant="outlined"
              onClick={() => {
                const splits = toDistribute(100, data.recipients.length)
                const newRecipients = data.recipients.map(
                  (recipient, index) => {
                    recipient.percentAllocation = splits[index]
                    return recipient
                  },
                )
                setDataHandler({ ...data, recipients: newRecipients })
              }}
            />
          </CardActions>
        ) : (
          <CardActions sx={{ px: 4, pb: 2 }}>
            <Box>
              {csvText &&
                (validatingCsv ? (
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    Loading...
                  </Typography>
                ) : validCSV ? (
                  <Typography sx={{ color: theme.palette.success.main }}>
                    Valid recipients list
                  </Typography>
                ) : (
                  <Typography sx={{ color: theme.palette.error.main }}>
                    Invalid recipients list
                  </Typography>
                ))}
            </Box>
            <Box flexGrow={1}></Box>
            <Button
              label="Upload CSV"
              variant="outlined"
              onClick={handleUploadCsv}
            />
            <Button
              label="Open Template"
              variant="outlined"
              onClick={() =>
                window.open(
                  'https://docs.google.com/spreadsheets/d/1BfueOnm9DcA3pTWbVSdVkgkDjX4E-SsBU4s43rY3btU/edit?usp=sharing',
                  '_blank',
                )
              }
            />
            <Button
              label="Clear"
              variant="outlined"
              onClick={() => {
                setCsvText('')
              }}
            />
          </CardActions>
        )}

        <Box sx={{ px: 4, pt: 2, pb: 1 }}>
          <LinearProgress
            variant="determinate"
            value={sum}
            color={sum > 100 ? 'error' : 'primary'}
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
        <Box sx={{ pb: 2, px: 4 }} display="flex">
          <Typography color="secondary">{`${sum}% Allocated`}</Typography>
          <Box flexGrow={1}></Box>
          {sum < 100 && (
            <Typography color="secondary">{`${(100 - sum).toFixed(
              2,
            )}% Remaining`}</Typography>
          )}
        </Box>
      </Card>
      <Card {...cardProps} sx={{ mt: 10, ...cardProps?.sx }} variant="outlined">
        <CardHeader title="Distribution Incentive"></CardHeader>
        <Divider />
        <CardContent>
          <Typography>
            Set the portion of the Split's balance that is paid to any bot or
            third party that distributes the balance to recipients. A higher
            incentive leads to more frequent distributions.
          </Typography>
          <Box sx={{ pt: 4, display: 'flex' }}>
            <IconButton
              sx={{ p: 2.4, height: '100%' }}
              variant="outlined"
              onClick={() => {
                let newVal = data.distributorFeePercent - 0.05
                newVal = Math.max(newVal, 0)
                newVal = parseFloat(newVal.toFixed(2))
                setDataHandler({
                  ...data,
                  distributorFeePercent: newVal,
                })
              }}
              startIcon={<RemoveIcon />}
            />
            <PercentageInput
              placeholder="0.00%"
              value={data.distributorFeePercent}
              setValue={(val) => {
                val = Math.min(10, val)
                val = Math.max(0, val)
                setDataHandler({ ...data, distributorFeePercent: val })
              }}
              inputProps={{ sx: { py: 2.3 } }}
              sx={{ width: 200, px: 2 }}
              cardProps={{ sx: { mx: 2, height: 'fit-content' } }}
              allowZero={true}
            />
            <IconButton
              sx={{ p: 2.4, height: '100%' }}
              variant="outlined"
              onClick={() => {
                let newVal = data.distributorFeePercent + 0.05
                newVal = Math.min(10, newVal)
                newVal = parseFloat(newVal.toFixed(2))
                setDataHandler({
                  ...data,
                  distributorFeePercent: newVal,
                })
              }}
              startIcon={<AddIcon />}
            />
            {/* <Typography
              sx={{ ml: 2 }}
              variant="body2"
              color={theme.palette.text.secondary}
            >
              Tip: fill out the Recipients section to estimate when automatic
              payouts will occur
            </Typography> */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
export default SplitsForm
