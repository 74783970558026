import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useConnect } from 'wagmi'

const EmptyAccount: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography
        color={theme.palette.text.secondary}
        sx={{ pt: 1, textAlign: 'center' }}
      >
        Please connect your wallet to view your account details
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button
          label="Connect Wallet"
          // endIcon={<LogoutIcon />}
          variant="contained"
          color={theme.palette.mode == 'light' ? 'secondary' : 'primary'}
          sx={{
            textTransform: 'none',
            width: '180px',
          }}
          onClick={() => {
            connect()
          }}
        />
      </Box>
    </Container>
  )
}

export default EmptyAccount
