import { Container } from '@mui/material'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashbaord'
import Account from './Account'
import Splits from './Splits'
import NewSplit from './NewSplit'
import Waterfalls from './Waterfalls'
import NewWaterfall from './NewWaterfall'
import Explore from './Explore'
import Vault from './Vault'
import styled from '@emotion/styled'
import EmptyAccount from './EmptyAccount'
import CreatePage from './CreatePage'
import Bookmarks from './Bookmarks'

const StaticOverlay = styled.div`
  -webkit-font-smoothing: antialiased;
  background-attachment: fixed;
  background-repeat: repeat;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  background-image: url(/assets/bg-noise.gif);
  background-size: 170px;
  mix-blend-mode: lighten;
  opacity: 90%;
  z-index: 600;
`

const Content = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        py: 6,
      }}
    >
      <StaticOverlay />
      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route path="accounts" element={<EmptyAccount />} />
        <Route path="accounts/:accountID" element={<Account />} />
        <Route path="waterfall" element={<Waterfalls />} />
        <Route path="newWaterfall" element={<NewWaterfall />} />
        <Route path="create" element={<CreatePage />} />
        <Route path="split" element={<Splits />} />
        <Route path="newSplit" element={<NewSplit />} />
        <Route path="explore" element={<Explore />} />
        <Route path="bookmarks" element={<Bookmarks />} />
        <Route path="vault" element={<Vault />} />
        <Route path="*" element={<p>There's nothing here!</p>} />
      </Routes>
    </Container>
  )
}
export default Content
