import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  Checkbox,
  Modal,
  Box,
} from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode'
import * as React from 'react'
import { VestingModule, VestingClient } from '@neobase-one/splits-sdk'
import { useEffect } from 'react'
import { AccountInfo, IconButton } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { isAddress } from '@ethersproject/address'
import { useTheme } from '@mui/material/styles'
import { useProvider, useSigner } from 'wagmi'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import { getVestingEarningsInfo } from '../utils'
import AccountSkeleton from './AccountSkeleton'
import QRCode from 'react-qr-code'
import DepositForm from './DepositForm'
import VestingForm from './VestingForm'
import RefreshIcon from '@mui/icons-material/Refresh'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import AccountLabel from './AccountLabel'

const VestingAccount: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [vestingModule, setVestingModule] = React.useState<VestingModule>()
  const [earnings, setEarnings] = React.useState<{
    balances: AccountInfoProps[]
    distributed: AccountInfoProps[]
    activeStreams: boolean[]
    totalAmount: string
    activeAmount: string
    distributedAmount: string
    totalReleased: string
  }>()
  const { address, isConnected } = useAccount()
  const [openDeposit, setOpenDeposit] = React.useState<boolean>(false)
  const [openVesting, setOpenVesting] = React.useState<boolean>(false)
  const [recipients, setRecipients] = React.useState<AccountInfoProps[]>()
  const [accountEvents, setAccountEvents] = React.useState<
    {
      avatar: JSX.Element
      text: string
      time: string
    }[]
  >()
  const [distribute, setDistribute] = React.useState<number[]>([])
  const [toRelease, setToRelease] = React.useState<number[]>([])
  const [loadingVesting, setLoadingVesting] = React.useState<boolean>(false)
  const [loadingEarnings, setLoadingEarnings] = React.useState<boolean>(false)
  const [openQR, setOpenQR] = React.useState<boolean>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const provider = useProvider()
  const { useFetchMetadata } = React.useContext(MetaDataContext)

  const { data: signer } = useSigner()
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: 24,
  }

  async function queryVesting(vestingClient: VestingClient, vestingId: string) {
    setLoadingVesting(true)
    const vesting = await vestingClient.getVestingMetadata({
      vestingModuleId: vestingId,
    })
    setVestingModule(vesting)
  }

  async function queryEarnings(
    vestingClient: VestingClient,
    vestingModule: VestingModule,
  ) {
    setLoadingEarnings(true)
    const {
      activeAmount,
      activeBalances,
      distributedAmount,
      distributions,
      activeStreams,
      totalReleased,
      totalAmount,
    } = await getVestingEarningsInfo(
      vestingClient,
      vestingModule,
      theme,
      useFetchMetadata,
    )
    setEarnings({
      activeAmount: activeAmount,
      balances: activeBalances,
      distributedAmount: distributedAmount,
      distributed: distributions,
      totalAmount: totalAmount,
      activeStreams,
      totalReleased,
    })
    setLoadingEarnings(false)
    setLoadingVesting(false)
  }

  useEffect(() => {
    if (
      params.accountID &&
      vestingModule &&
      params.accountID !== vestingModule.id
    ) {
      setVestingModule(undefined)
    }
    if (params.accountID && isAddress(params.accountID)) {
      const vestingClient = new VestingClient({
        chainId: 7700,
        provider,
      })
      queryVesting(vestingClient, params.accountID)
    }
  }, [params.accountID])

  useEffect(() => {
    if (vestingModule && provider) {
      const vestingClient = new VestingClient({
        chainId: 7700,
        provider: provider,
      })
      queryEarnings(vestingClient, vestingModule)
    }
  }, [vestingModule])

  useEffect(() => {
    if (vestingModule && provider) {
      const recipients = [
        {
          address: vestingModule.beneficiary.address,
          amount: `100%`,
          buttonProps: {
            onClick: () =>
              navigate(`/accounts/${vestingModule.beneficiary.address}`),
          },
          onCopy: () =>
            enqueueSnackbar(
              <MessageWithTransaction message="Address copied" />,
            ),
        },
      ] as AccountInfoProps[]
      setRecipients(recipients)
    } else {
      setRecipients(undefined)
      setAccountEvents(undefined)
    }
  }, [vestingModule])

  const addBalance = (index: number, distributeCopy: number[]) => {
    const ind = distributeCopy.indexOf(index)
    if (ind == -1) {
      distributeCopy.push(index)
    } else {
      distributeCopy.splice(ind, 1)
    }
    setDistribute([...distributeCopy])
  }

  const addStream = (index: number, toReleaseCopy: number[]) => {
    const ind = toReleaseCopy.indexOf(index)
    if (ind == -1) {
      toReleaseCopy.push(index)
    } else {
      toReleaseCopy.splice(ind, 1)
    }
    setToRelease([...toReleaseCopy])
  }

  const releaseStreams = async (toReleaseCopy: number[]) => {
    if (
      toReleaseCopy.length &&
      provider &&
      signer &&
      earnings &&
      vestingModule
    ) {
      const vestingClient = new VestingClient({
        chainId: 7700,
        provider,
        signer,
      })
      let releaseNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Releasing streams ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const { tx } = await vestingClient.submitReleaseVestedFundsTransaction({
          vestingModuleId: vestingModule.id,
          streamIds: toReleaseCopy.map(
            (index) => earnings.distributed[index].key as string,
          ),
        })
        closeSnackbar(releaseNotif)
        setTimeout(() => {
          releaseNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Releasing streams ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)

        await tx.wait()
        closeSnackbar(releaseNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Successfully released streams`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        setToRelease([])
      } catch {
        closeSnackbar(releaseNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while releasing streams`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
      setTimeout(() => {
        queryVesting(vestingClient, vestingModule.id)
      }, 3000)
    }
  }

  const distributeBalance = async (distributeCopy: number[]) => {
    if (
      distributeCopy.length &&
      provider &&
      signer &&
      earnings &&
      vestingModule
    ) {
      const vestingClient = new VestingClient({
        chainId: 7700,
        provider,
        signer,
      })
      let startingNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Starting streams ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const { tx } = await vestingClient.submitStartVestTransaction({
          vestingModuleId: vestingModule.id,
          tokens: distributeCopy.map(
            (index) => earnings.balances[index].key as string,
          ),
        })
        closeSnackbar(startingNotif)
        setTimeout(() => {
          startingNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Starting streams ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)

        await tx.wait()
        closeSnackbar(startingNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Successfully started streams`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        setDistribute([])
      } catch {
        closeSnackbar(startingNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction message={`Error while starting streams`} />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
      setTimeout(() => {
        queryVesting(vestingClient, vestingModule.id)
      }, 3000)
    }
  }

  if (!params.accountID || !isAddress(params.accountID)) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          flexGrow: 1,
          py: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>Couldn't locate address</>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start', pt: 6 }}>
      {loadingVesting || loadingEarnings || !vestingModule ? (
        <AccountSkeleton />
      ) : (
        <>
          <Card
            sx={{ width: '100%', display: 'flex', px: 2 }}
            variant="outlined"
          >
            <IconButton
              startIcon={<ArrowBackIcon />}
              sx={{ px: 2, my: 2 }}
              onClick={() => navigate(-1)}
            />
            <AccountInfo
              address={params.accountID}
              showMenu={false}
              showButtonGroup={true}
              expandOnHover={true}
              dense={false}
              truncate={!desktopView}
              provider={ensProvider}
              startElement={<AccountLabel type="Vesting module" />}
              onCopy={() =>
                enqueueSnackbar(
                  <MessageWithTransaction message="Address copied" />,
                )
              }
              buttonProps={{
                onClick: () => {
                  if (params.accountID) {
                    navigator.clipboard.writeText(params.accountID)
                    enqueueSnackbar(
                      <MessageWithTransaction message="Address copied" />,
                    )
                  }
                },
              }}
              iconButtons={[
                {
                  startIcon: <RefreshIcon />,
                  sx: { px: 2 },
                  onClick: () => {
                    if (signer && provider && vestingModule) {
                      const vestingClient = new VestingClient({
                        chainId: 7700,
                        provider,
                      })
                      queryVesting(vestingClient, vestingModule.id)
                    }
                  },
                  toolTipProps: { title: 'Refresh' },
                },
              ]}
            />
          </Card>
          <Grid container spacing={6} sx={{ pt: 6 }}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Grid container flexDirection="column" spacing={6}>
                <Grid item sx={{ width: '100%' }}>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Grid container>
                          <Grid item xs>
                            Vesting Streams
                          </Grid>
                          <Grid item>${earnings?.totalAmount}</Grid>
                        </Grid>
                      }
                    ></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack
                        pl={1}
                        spacing={2}
                        pt={1}
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                      >
                        <Typography variant="caption">
                          Vesting streams in this contract end{' '}
                          {(
                            vestingModule?.vestingPeriod /
                            (24 * 60 * 60)
                          ).toFixed(0)}{' '}
                          days after they begin.
                        </Typography>
                        {earnings && earnings.balances.length > 0 && (
                          <>
                            <Grid container alignItems="center">
                              <Grid item xs>
                                <Typography>Pending</Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  disabled={
                                    distribute.length === 0 || !isConnected
                                      ? true
                                      : false
                                  }
                                  onClick={() => distributeBalance(distribute)}
                                >
                                  Start streams
                                </Button>
                              </Grid>
                            </Grid>
                            {earnings.balances.map((balance, index) => (
                              <React.Fragment key={`Balance_${index}`}>
                                <Stack>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        checked={
                                          distribute.indexOf(index) !== -1
                                        }
                                        onChange={() =>
                                          addBalance(index, distribute)
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <AccountInfo
                                        truncate={false}
                                        showMenu={false}
                                        {...balance}
                                        dense={true}
                                        buttonProps={{ sx: { py: 2 } }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </React.Fragment>
                            ))}
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Balances
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.activeAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings &&
                          earnings.distributed.filter(
                            (stream, index) => earnings.activeStreams[index],
                          ).length > 0 && (
                            <>
                              <Grid
                                container
                                alignItems="center"
                                sx={{
                                  pt: earnings.balances.length ? 4 : 0,
                                }}
                              >
                                <Grid item xs>
                                  <Typography>Active</Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      toRelease.length === 0 || !isConnected
                                        ? true
                                        : false
                                    }
                                    onClick={() => releaseStreams(toRelease)}
                                  >
                                    Release streams
                                  </Button>
                                </Grid>
                              </Grid>
                              <Stack>
                                {earnings.distributed.map(
                                  (distribution, index) => {
                                    if (earnings.activeStreams[index])
                                      return (
                                        <Grid
                                          container
                                          alignItems="center"
                                          key={`active_${index}`}
                                        >
                                          <Grid item>
                                            <Checkbox
                                              checked={
                                                toRelease.indexOf(index) !== -1
                                              }
                                              onChange={() =>
                                                addStream(index, toRelease)
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs>
                                            <AccountInfo
                                              truncate={false}
                                              showMenu={false}
                                              {...distribution}
                                              dense={true}
                                              buttonProps={{ sx: { py: 2 } }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )
                                  },
                                )}
                              </Stack>
                              <Divider />
                              <Grid container pr={2}>
                                <Grid item xs>
                                  <Typography color="secondary">
                                    Total Active
                                  </Typography>
                                </Grid>
                                <Grid item>${earnings?.distributedAmount}</Grid>
                              </Grid>
                            </>
                          )}
                        {earnings &&
                          earnings.distributed.filter(
                            (stream, index) => !earnings.activeStreams[index],
                          ).length > 0 && (
                            <>
                              <Grid
                                container
                                alignItems="center"
                                sx={{
                                  pt: 4,
                                }}
                              >
                                <Grid item xs>
                                  <Typography>Released</Typography>
                                </Grid>
                              </Grid>
                              <Stack>
                                {earnings.distributed.map(
                                  (distribution, index) => {
                                    if (!earnings.activeStreams[index])
                                      return (
                                        <Grid
                                          container
                                          alignItems="center"
                                          key={`released_${index}`}
                                        >
                                          <Grid item xs>
                                            <AccountInfo
                                              truncate={false}
                                              showMenu={false}
                                              {...distribution}
                                              dense={true}
                                              buttonProps={{ sx: { py: 2 } }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )
                                  },
                                )}
                              </Stack>
                              <Divider />
                              <Grid container pr={2}>
                                <Grid item xs>
                                  <Typography color="secondary">
                                    Total Released
                                  </Typography>
                                </Grid>
                                <Grid item>${earnings?.totalReleased}</Grid>
                              </Grid>
                            </>
                          )}
                        {earnings &&
                          earnings.balances.length === 0 &&
                          earnings.distributed.length === 0 && (
                            <Typography align="center">
                              No Data Found
                            </Typography>
                          )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Grid container flexDirection="column" spacing={6}>
                <Grid item sx={{ width: '100%' }}>
                  <Card variant="outlined">
                    <CardHeader title="Beneficiary"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                        spacing={2}
                        divider={<Divider />}
                      >
                        {recipients &&
                          recipients.map((accountInfo, index) => (
                            <AccountInfo
                              {...accountInfo}
                              dense={true}
                              provider={ensProvider}
                            />
                          ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Address"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack spacing={2} py={1}>
                        <Card>
                          <AccountInfo
                            address={params.accountID}
                            showMenu={false}
                            showButtonGroup={true}
                            openExplorer={false}
                            showAvatar={false}
                            truncate={false}
                            onCopy={() =>
                              enqueueSnackbar(
                                <MessageWithTransaction message="Address copied" />,
                              )
                            }
                            sx={{
                              background:
                                theme.palette.mode == 'light'
                                  ? theme.palette.grey[200]
                                  : theme.palette.grey[900],
                            }}
                            iconButtons={[
                              {
                                toolTipProps: {
                                  title: 'View QR Code',
                                },
                                startIcon: (
                                  <QrCodeIcon onClick={() => setOpenQR(true)} />
                                ),
                              },
                            ]}
                          />
                        </Card>
                        <Typography
                          paragraph={true}
                          sx={{ padding: '1rem 0rem' }}
                        >
                          Funds sent to this address are vested to the
                          beneficiary
                        </Typography>
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={!isConnected}
                          onClick={() => setOpenDeposit(true)}
                        >
                          Deposit Funds
                        </Button>
                        {/* <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isConnected}
                          onClick={() => setOpenVesting(true)}
                        >
                          New Vesting Module
                        </Button> */}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {address && vestingModule && isConnected && (
            <Modal
              open={openDeposit}
              onClose={() => {
                setOpenDeposit(false)
              }}
              keepMounted={true}
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <Box sx={{ ...modalStyles, width: 600 }}>
                {
                  <DepositForm
                    from={address}
                    to={params.accountID}
                    onClose={() => {
                      setOpenDeposit(false)
                    }}
                    refresh={() => {
                      const vestingClient = new VestingClient({
                        chainId: 7700,
                        provider,
                      })
                      setTimeout(() => {
                        queryVesting(vestingClient, vestingModule.id)
                      }, 2000)
                    }}
                  />
                }
              </Box>
            </Modal>
          )}
          {/* {address && vestingModule && isConnected && (
            <Modal
              open={openVesting}
              onClose={() => {
                setOpenVesting(false)
              }}
              keepMounted={true}
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <Box sx={{ ...modalStyles, width: 600 }}>
                {
                  <VestingForm
                    to={params.accountID}
                    onClose={() => {
                      setOpenVesting(false)
                    }}
                    refresh={() => {
                      const splitsClient = new VestingClient({
                        chainId: 7700,
                        provider,
                      })
                      setTimeout(() => {
                        queryVesting(splitsClient, vestingModule.id)
                      }, 3000)
                    }}
                  />
                }
              </Box>
            </Modal>
          )} */}
        </>
      )}
      <Modal
        open={openQR}
        onClose={() => setOpenQR(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={{ ...modalStyles, width: 300 }}>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AccountInfo
                address={params.accountID}
                showMenu={false}
                showButtonGroup={false}
                expandOnHover={true}
                dense={true}
                truncate={false}
                provider={ensProvider}
              />
              <Box sx={{ p: 20 }}>
                <Card sx={{ px: 2, background: 'white', pb: '4px', pt: 2 }}>
                  <QRCode size={200} value={params.accountID} />
                </Card>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Container>
  )
}
export default VestingAccount
