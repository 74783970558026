import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Skeleton,
  Avatar,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { useProvider } from 'wagmi'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { Button } from '@neobase-one/neobase-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AccountInfo } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate } from 'react-router-dom'
import {
  getLiquidSplitAccountInfo,
  getSplitAccountInfo,
  getWaterfallAccountInfo,
} from '../utils'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'

const Bookmarks: React.FC = () => {
  const navigate = useNavigate()
  const provider = useProvider()
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [bookmarks, setBookmarks] = React.useState<AccountInfoProps[]>()
  const [loadingBookmarks, setLoadingSplits] = React.useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    async function queryBookmarks(splitsClient: SplitsClient) {
      setLoadingSplits(true)
      const bookmarksString = localStorage.getItem('splits-bookmarks')
      if (bookmarksString) {
        const bookmarks: string[] = JSON.parse(bookmarksString)
        const accountInfo: AccountInfoProps[] = await Promise.all(
          bookmarks.map(async (accountId) => {
            const account_metadata = await splitsClient.getAccountMetadata({
              accountId,
            })
            console.log(account_metadata)
            if (account_metadata?.type == 'WaterfallModule') {
              return await getWaterfallAccountInfo(
                account_metadata,
                splitsClient,
                useFetchMetadata,
                true,
                navigate,
                enqueueSnackbar,
              )
            } else if (account_metadata?.type == 'Split') {
              return await getSplitAccountInfo(
                account_metadata,
                splitsClient,
                useFetchMetadata,
                true,
                navigate,
                enqueueSnackbar,
              )
            } else if (account_metadata?.type == 'LiquidSplit') {
              return await getLiquidSplitAccountInfo(
                account_metadata,
                splitsClient,
                useFetchMetadata,
                true,
                navigate,
                enqueueSnackbar,
              )
            }
            return {
              address: '',
            } as AccountInfoProps
          }),
        )
        accountInfo.filter((info) => info.address != '')
        setBookmarks(accountInfo)
      } else {
        setBookmarks([])
      }

      setLoadingSplits(false)
    }
    if (provider !== undefined) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      queryBookmarks(splitsClient)
    }
  }, [provider])

  const AccountInfoSkeleton = () => {
    return (
      <Stack>
        {[1, 2, 3, 4, 5, 6, 7].map((index, key) => (
          <Grid key={key} container spacing={3.5} p={2}>
            <Grid item>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: { xs: 90, sm: 150 },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: { xs: 70, sm: 100 },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: { xs: 50, sm: 100 },
                        borderRadius: '1rem',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Stack>
    )
  }

  return (
    <Container sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Grid container spacing={6} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader title="Bookmarks"></CardHeader>
            <Divider />
            <CardContent sx={{ overflowY: 'auto', maxHeight: '65vh' }}>
              {loadingBookmarks || !bookmarks ? (
                <AccountInfoSkeleton />
              ) : bookmarks.length > 0 ? (
                <Stack spacing={2}>
                  {bookmarks &&
                    bookmarks.map((accountInfo, index) => (
                      <React.Fragment key={index}>
                        <AccountInfo {...accountInfo} dense={true} />
                        {index !== bookmarks.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                </Stack>
              ) : (
                <Typography textAlign={'center'}>
                  You have no accounts bookmarked
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Bookmarks
