import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Input,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { AccountInfo, Button } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import { useProvider, useSigner } from 'wagmi'
import { VestingClient } from '@neobase-one/splits-sdk'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'

export const VestingForm: React.FC<{
  to: string
  onClose: () => void
  refresh: () => void
}> = ({ to, onClose, refresh }) => {
  const theme = useTheme()
  const [days, setDays] = React.useState<number>()
  const provider = useProvider()
  const { data: signer } = useSigner()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const createVesting = async () => {
    if (provider && signer && to && isAddress(to) && days && days > 0) {
      const vestingClient = new VestingClient({
        chainId: 7700,
        provider, // ethers provider (optional, required if using any of the SplitMain functions)
        signer, // ethers signer (optional, required if using the SplitMain write functions)
      })
      onClose()
      let vestingTransactionNotif = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating vesting module ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      try {
        const { tx } = await vestingClient.submitCreateVestingModuleTransaction(
          {
            beneficiary: to,
            vestingPeriodSeconds: days * 24 * 60 * 60,
          },
        )
        closeSnackbar(vestingTransactionNotif)
        setTimeout(() => {
          vestingTransactionNotif = enqueueSnackbar(
            <MessageWithTransaction
              message={`Creating vesting module ...`}
              transactionId={tx.hash}
            />,
            {
              variant: 'warning',
              persist: true,
              transitionDuration: { enter: 225, exit: 0 },
            },
          )
        }, 200)

        await tx.wait()
        closeSnackbar(vestingTransactionNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Created vesting module`}
              transactionId={tx.hash}
            />,
            {
              variant: 'success',
            },
          )
        }, 200)
        refresh()
      } catch (err) {
        console.log(err)
        closeSnackbar(vestingTransactionNotif)
        setTimeout(() => {
          enqueueSnackbar(
            <MessageWithTransaction
              message={`Error while creating vesting module`}
            />,
            {
              variant: 'error',
            },
          )
        }, 200)
      }
    }
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="New Vesting Module"></CardHeader>
        <Divider />
        <CardContent>
          <Typography color={theme.palette.text.primary} sx={{ pb: 4 }}>
            A Vesting Module gradually sends all CANTO & ERC20s it receives to
            the Beneficiary over the Vesting Period.
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="caption">Beneficiary</Typography>
              <Card variant="outlined">
                <AccountInfo
                  address={to}
                  showMenu={false}
                  provider={ensProvider}
                />
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 4 }}>
            <Typography variant="caption">Vesting Period (in days)</Typography>
            <Card variant="outlined">
              <Input
                value={days}
                onChange={(event) => setDays(parseInt(event.target.value))}
                fullWidth
                type="number"
                placeholder="365"
                sx={{ p: 2, border: 0 }}
                disableUnderline
              />
            </Card>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            px: 6,
          }}
        >
          <Button label="Create" onClick={createVesting} disabled={!days} />
          <Typography
            color={theme.palette.text.secondary}
            sx={{ pt: 4 }}
            variant="caption"
            align="center"
          >
            Vesting Modules are immutable smart contracts. Double check the
            Vesting Period is correct before submitting.
          </Typography>
        </CardActions>
      </Card>
    </Box>
  )
}
export default VestingForm
