import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
  Skeleton,
  Avatar,
  Box,
} from '@mui/material'
import * as React from 'react'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { useProvider } from 'wagmi'
import { useEffect } from 'react'
import { AccountInfo } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import EastIcon from '@mui/icons-material/East'
import {
  MessageWithTransaction,
  getSplitEarningsInfo,
  getUserEarningsInfo,
} from '../utils'
import { useTheme } from '@mui/material/styles'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'
import { ButtonCard } from './SyledComponents'

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const provider = useProvider()
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const [loadingSplits, setLoadingSplits] = React.useState<boolean>(false)
  const [loadingRecipients, setLoadingRecipients] =
    React.useState<boolean>(false)
  const [topSplits, setTopSplits] = React.useState<AccountInfoProps[]>()
  const [topRecipients, setTopRecipients] = React.useState<AccountInfoProps[]>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const theme = useTheme()

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const DashboardSkeleton = () => {
    return (
      <Stack>
        {[1, 2, 3, 4, 5].map((index, key) => (
          <Grid key={key} container spacing={3.5} p={2}>
            <Grid item>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </Grid>
            <Grid item xs>
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width={200} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width={100} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Stack>
    )
  }

  useEffect(() => {
    async function queryTopSplits(splitsClient: SplitsClient) {
      setLoadingSplits(true)
      const splits = await splitsClient.getTopSplits({
        lastId: '',
        numSplits: 30,
      })

      const topSplitsInfo = await Promise.all(
        splits.splits.map(async (split) => {
          const { totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            split.id,
            true,
            useFetchMetadata,
          )
          const numberOfRecipients = split.recipients.length
          return {
            address: split.id,
            amount: `$${totalAmount}`,
            subinfo: `${numberOfRecipients} recipients`,
            value: Number(totalAmount),
            buttonProps: { onClick: () => navigate(`/accounts/${split.id}`) },
          }
        }),
      )

      const topSplitsFiltered = topSplitsInfo
        .sort((split1, split2) => split2.value - split1.value)
        // .slice(0, 5)
        .map((split) => split as AccountInfoProps)

      setTopSplits(topSplitsFiltered)
      setLoadingSplits(false)
    }
    async function queryTopRecipients(splitsClient: SplitsClient) {
      setLoadingRecipients(true)
      const recipients = await splitsClient.getTopRecipients({
        lastId: '',
        numSplits: 30,
      })
      const topInfo = await Promise.all(
        recipients.recipients.map(async (recipient) => {
          const { totalAmount, tokens } = await getUserEarningsInfo(
            splitsClient,
            recipient.address,
            useFetchMetadata,
          )
          return {
            address: recipient.address,
            amount: `$${totalAmount}`,
            subinfo: `${tokens} tokens`,
            value: Number(totalAmount),
            buttonProps: {
              onClick: () => navigate(`/accounts/${recipient.address}`),
            },
          }
        }),
      )
      const unique_ids: string[] = []
      const topRecipientsFiltered = topInfo
        .sort((split1, split2) => split2.value - split1.value)
        .filter((split) => {
          if (unique_ids.indexOf(split.address) === -1) {
            unique_ids.push(split.address)
            return true
          }
          return false
        })
        // .slice(0, 5)
        .map((split) => split as AccountInfoProps)

      setTopRecipients(topRecipientsFiltered)
      setLoadingRecipients(false)
    }
    if (provider !== undefined) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      queryTopSplits(splitsClient)
      queryTopRecipients(splitsClient)
    }
  }, [provider])

  return (
    <Box>
      <Grid container spacing={6} pt={5}>
        <Grid item xs={12}>
          <Card
            sx={{
              cursor: 'pointer',
              background:
                theme.palette.mode == 'light'
                  ? theme.palette.primary.main
                  : theme.palette.primary.main,
              color: 'white',
              transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              ':hover': {
                background: theme.palette.primary.dark,
              },
            }}
            onClick={() => navigate('/create')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 4,
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Get started</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Create a split and start using it with your favorite web3
                      platforms today
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <EastIcon fontSize="large" />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardHeader title="Top Splits"></CardHeader>
            <Divider />
            <CardContent
              sx={{
                maxHeight: 320,
                overflowY:
                  !loadingRecipients && !loadingSplits ? 'auto' : 'none',
              }}
            >
              {!loadingSplits && !loadingRecipients ? (
                <Stack spacing={2} divider={<Divider />}>
                  {topSplits &&
                    topSplits.map((accountInfo, index) => (
                      <AccountInfo
                        {...accountInfo}
                        dense={true}
                        onCopy={() =>
                          enqueueSnackbar(
                            <MessageWithTransaction message="Address copied" />,
                          )
                        }
                      />
                    ))}
                </Stack>
              ) : (
                <DashboardSkeleton />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardHeader title="Top Recipients"></CardHeader>
            <Divider />
            <CardContent
              sx={{
                maxHeight: 320,
                overflowY:
                  !loadingRecipients && !loadingSplits ? 'auto' : 'none',
              }}
            >
              {!loadingRecipients && !loadingSplits ? (
                <Stack spacing={2} divider={<Divider />}>
                  {topRecipients &&
                    topRecipients.map((accountInfo, index) => (
                      <AccountInfo
                        {...accountInfo}
                        provider={ensProvider}
                        dense={true}
                        onCopy={() =>
                          enqueueSnackbar(
                            <MessageWithTransaction message="Address copied" />,
                          )
                        }
                      />
                    ))}
                </Stack>
              ) : (
                <DashboardSkeleton />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonCard
            onClick={() =>
              window.open('https://twitter.com/NeoBase_Studios', '_blank')
            }
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ pb: 1, fontWeight: 600 }}
              >
                NeoBase Twitter
              </Typography>
              <Typography variant="caption">
                Stay tuned in with our Twitter for latest updates
              </Typography>
            </CardContent>
          </ButtonCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonCard
            onClick={() => window.open('https://discord.gg/canto', '_blank')}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ pb: 1, fontWeight: 600 }}
              >
                Canto Discord
              </Typography>
              <Typography variant="caption">
                Contribute to Canto, Join the community on Discord
              </Typography>
            </CardContent>
          </ButtonCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonCard
            onClick={() =>
              window.open(
                'https://docs.0xsplits.xyz/getstarted/using',
                '_blank',
              )
            }
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ pb: 1, fontWeight: 600 }}
              >
                Learn More
              </Typography>
              <Typography variant="caption">
                Based on 0xSplits, Learn more about how to use it
              </Typography>
            </CardContent>
          </ButtonCard>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Dashboard
