import React from 'react'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material'

type AccountType =
  | 'Immutable split'
  | 'Mutable split'
  | 'Liquid split'
  | 'Vesting module'
  | 'Waterfall'
  | 'User account'

const AccountLabel: React.FC<{
  type: AccountType
  dense?: boolean
}> = ({ type, dense }) => {
  const theme = useTheme()

  const typeMap = {
    'Immutable split': '#1b4721',
    'Mutable split': '#791513',
    'Liquid split': '#3f50b5',
    'Vesting module': '#692649',
    Waterfall: '#472c82',
    'User account': theme.palette.primary.main,
  }

  return (
    <Tooltip
      title={
        <span style={{ fontFamily: 'IBM Plex Mono' }}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </span>
      }
      placement="top"
    >
      <Box
        sx={{
          mr: dense ? -1 : 0,
          width: dense ? 14 : 16,
          height: dense ? 14 : 16,
          background: typeMap[type],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: dense ? 10 : 10,
          borderRadius: '2px',
          cursor: 'default',
          color: 'white',
        }}
      >
        {type.charAt(0).toUpperCase()}
      </Box>
    </Tooltip>
  )
}

export default AccountLabel
