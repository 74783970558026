import * as React from 'react'
import Box from '@mui/material/Box'
import Header from './components/Header'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Content from './components/Content'
import Footer from './components/Footer'
import { useProvider, useSigner } from 'wagmi'
import { customDarkTheme, customLightTheme } from './theme'
import { ColorModeContext, MetaDataContext } from './context'
import { useSplitsClient } from '@neobase-one/splits-sdk-react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { TokenData, fetchMetaData } from '@neobase-one/splits-sdk'
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.SnackbarItem-variantSuccess': {
    background: theme.palette.success.dark,
  },
  '&.SnackbarItem-variantError': {
    background: theme.palette.error.dark,
  },
  '&.SnackbarItem-variantInfo': {
    background: theme.palette.info.main,
  },
  '&.SnackbarItem-variantWarning': {
    background: theme.palette.info.main,
  },
}))

const SnackbarCloseButton: React.FC<{ snackbarKey: SnackbarKey }> = ({
  snackbarKey,
}) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarKey)
      }}
    >
      <CloseIcon sx={{ color: 'white' }} />
    </IconButton>
  )
}

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const storedTheme = localStorage.getItem('theme')

  let defaultTheme: 'light' | 'dark' = prefersDarkMode ? 'dark' : 'light'

  if (storedTheme === null) {
    localStorage.setItem('theme', defaultTheme)
  } else {
    defaultTheme = storedTheme == 'dark' ? 'dark' : 'light'
  }

  const [mode, setMode] = React.useState<'light' | 'dark'>(defaultTheme)

  const [tokenDataMap, setTokenDataMap] = React.useState<
    Map<string, Promise<TokenData>>
  >(new Map())

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light'
          localStorage.setItem('theme', newMode)
          return newMode
        })
      },
    }),
    [],
  )

  const getMetaData = async (token_key: string): Promise<TokenData> => {
    const metaData = await fetchMetaData(provider, [token_key])
    return metaData[0]
  }

  const useFetchMetadata = (token_key: string): Promise<TokenData> => {
    if (!tokenDataMap.has(token_key)) {
      setTokenDataMap(tokenDataMap.set(token_key, getMetaData(token_key)))
    }

    return tokenDataMap.get(token_key)!
  }

  const provider = useProvider()
  const { data: signer } = useSigner()

  useSplitsClient({
    chainId: 7700,
    provider,
    ...(signer && { signer }),
  })

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MetaDataContext.Provider value={{ useFetchMetadata }}>
        <ThemeProvider
          theme={mode == 'light' ? customLightTheme : customDarkTheme}
        >
          <StyledSnackbarProvider
            maxSnack={7}
            // preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            iconVariant={{
              warning: (
                <CircularProgress sx={{ color: 'white', mr: 2 }} size={15} />
              ),
            }}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
            variant="info"
          >
            <CssBaseline />
            <Box
              sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Header />
              <Content />
              <Footer />
            </Box>
          </StyledSnackbarProvider>
        </ThemeProvider>
      </MetaDataContext.Provider>
    </ColorModeContext.Provider>
  )
}
