import React from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material'
import { Button, AccountInfo } from '@neobase-one/neobase-components'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { FormatToken } from '../utils'
import { MetaDataContext } from '../context'
import { ethers } from 'ethers'
import { useProvider, useSigner, useContract, useAccount } from 'wagmi'
import { CANTO_ADDRESS, CSR_SUBGRAPH, TURNSTILE_CONTRACT } from '../constants'

const CSRListUser: React.FC<{
  trigger?: number
  setOpenDepositToken: (openDepositForm: boolean) => void
  setPreSelectedToken: (tokenId: string) => void
}> = ({ trigger, setOpenDepositToken, setPreSelectedToken }) => {
  React.useState<GridSelectionModel>([])
  const [gridColumns, setGridColumns] = React.useState<GridColDef[]>()
  const [rows, setRows] = React.useState<any[]>()
  const [loading, setLoading] = React.useState(false)
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const provider = useProvider()
  const { data: signer } = useSigner()
  const { address } = useAccount()
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Token ID', minWidth: 90, flex: 1 },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <AccountInfo
            {...params.value}
            showMenu={false}
            dense={true}
            // showAvatar={false}
            truncate={false}
          />
        )
      },
    },
    {
      field: 'delete',
      type: 'actions',
      minWidth: 250,
      flex: 1,
      renderCell: (params: any) => [
        <Button
          label="Deposit Token"
          onClick={() => {
            setOpenDepositToken(true)
            setPreSelectedToken(params.id)
          }}
        />,
      ],
    },
  ]

  const fetchUserTokens = async () => {
    if (address) {
      setLoading(true)
      const client = new ApolloClient({
        uri: CSR_SUBGRAPH,
        cache: new InMemoryCache(),
      })
      const NFTQuery = gql`
        query MyQuery($owner: String!) {
          nfts(where: { owner: $owner }) {
            tokenId
            owner
            id
          }
        }
      `
      const results = (
        await client.query({
          query: NFTQuery,
          variables: { owner: address },
        })
      ).data['nfts']
      const turnstileAbi = [
        'function balances(uint256) public view returns (uint256)',
      ]
      const turnstile = new ethers.Contract(
        TURNSTILE_CONTRACT,
        turnstileAbi,
        provider,
      )

      const userNfts = await Promise.all(
        results.map(async (row: any) => {
          const balance = await turnstile.balances(row.tokenId)
          const newRow = {
            id: row.tokenId,
            balance: await FormatToken(
              useFetchMetadata,
              CANTO_ADDRESS,
              balance,
            ),
          }
          return newRow
        }),
      )
      setRows(userNfts)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    setGridColumns(columns)
    fetchUserTokens()
  }, [address, trigger])

  return (
    <Grid container>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardHeader title={'Your CSR Tokens'} />
        <Divider />
        <CardContent
          sx={{
            p: 0,
            ':last-child': { p: 0 },
          }}
        >
          {gridColumns && rows && !loading ? (
            <>
              {rows.length ? (
                <DataGrid
                  autoHeight
                  columns={gridColumns}
                  rows={rows}
                  // getRowId={(row) => row.tokenId}
                  disableSelectionOnClick
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              ) : (
                <Box sx={{ m: 4 }}>
                  <Typography sx={{ textAlign: 'center' }}>
                    You have no CSR tokens
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CSRListUser
