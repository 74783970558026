import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { AccountInfo, Button } from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import { useProvider, useSigner } from 'wagmi'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { MetaDataContext } from '../context'
import { MessageWithTransaction, getActiveBalances } from '../utils'
import { useSnackbar } from 'notistack'

export const DepositForm: React.FC<{
  from: string
  to: string
  onClose: () => void
  refresh: () => void
}> = ({ from, to, onClose, refresh }) => {
  const theme = useTheme()
  const [selectedTokenIndex, setSelectedTokenIndex] = React.useState<string>()
  const [tokens, setTokens] = React.useState<AccountInfoProps[]>([])
  const [amount, setAmount] = React.useState<number>()
  const provider = useProvider()
  const { data: signer } = useSigner()
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTokenIndex(event.target.value as string)
  }

  const makeDeposit = async () => {
    const tokenAmount = `${amount} ${
      tokens[Number(selectedTokenIndex)].address
    }`
    let depositTransactionNotif = enqueueSnackbar(
      <MessageWithTransaction
        message={`Depositing ${tokenAmount} ...`}
        description="Waiting for wallet approval"
      />,
      {
        variant: 'warning',
        persist: true,
        transitionDuration: { enter: 225, exit: 0 },
      },
    )
    const tokenAddress = String(tokens[Number(selectedTokenIndex)].key)
    // console.log(from, to, tokenAddress, tokens, amount)
    if (tokenAddress == ethers.constants.AddressZero) {
      if (provider && signer) {
        onClose()
        try {
          const tx = await signer.sendTransaction({
            to: to,
            value: ethers.utils.parseEther(String(amount)),
          })
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Depositing ${tokenAmount} ...`}
                transactionId={tx.hash}
              />,
              {
                variant: 'warning',
                persist: true,
                transitionDuration: { enter: 225, exit: 0 },
              },
            )
          }, 200)
          await tx.wait()
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Deposited ${tokenAmount}`}
                transactionId={tx.hash}
              />,
              {
                variant: 'success',
              },
            )
          }, 200)
          refresh()
        } catch (err) {
          console.log(err)
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Error while depositing funds`}
              />,
              {
                variant: 'error',
              },
            )
          }, 200)
        }
      }
    } else {
      const erc20abi = [
        'function transfer(address to, uint amount) returns (bool)',
        'event Transfer(address indexed from, address indexed to, uint amount)',
        'function decimals() view returns (uint8)',
      ]

      if (provider && signer) {
        try {
          const erc20 = new ethers.Contract(tokenAddress, erc20abi, signer)
          const decimals = await erc20.decimals()
          onClose()
          const tx = await erc20.transfer(
            to,
            ethers.utils.parseUnits(String(amount), decimals),
          )
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Depositing ${tokenAmount} ...`}
                transactionId={tx.hash}
              />,
              {
                variant: 'warning',
                persist: true,
                // key: 'lol',
                transitionDuration: { enter: 225, exit: 0 },
              },
            )
          }, 200)
          await tx.wait()
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Deposited ${tokenAmount}`}
                transactionId={tx.hash}
              />,
              {
                variant: 'success',
              },
            )
          }, 200)
          refresh()
        } catch (err) {
          console.log(err)
          closeSnackbar(depositTransactionNotif)
          setTimeout(() => {
            depositTransactionNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Error while depositing funds`}
              />,
              {
                variant: 'error',
              },
            )
          }, 200)
        }
      }
    }
  }

  React.useEffect(() => {
    async function getTokensList() {
      if (isAddress(from)) {
        const { activeBalances } = await getActiveBalances(
          from,
          useFetchMetadata,
        )
        setTokens(activeBalances)
      }
    }
    getTokensList()
  }, [from, to])

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="Deposit Funds"></CardHeader>
        <Divider />
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box sx={{ flexGrow: 1, pr: 2 }}>
              <Typography variant="caption">From</Typography>
              <Card variant="outlined">
                <AccountInfo
                  address={from}
                  showMenu={false}
                  provider={ensProvider}
                />
              </Card>
            </Box>
            <Box sx={{ flexGrow: 1, pl: 2 }}>
              <Typography variant="caption">To</Typography>
              <Card variant="outlined">
                <AccountInfo
                  address={to}
                  showMenu={false}
                  provider={ensProvider}
                />
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 4 }}>
            <Typography variant="caption">Token</Typography>
            {/* <FormControl fullWidth> */}
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              value={selectedTokenIndex}
              onChange={handleChange}
              fullWidth
              sx={{ borderRadius: 2 }}
            >
              {tokens.map((token, index) => (
                <MenuItem value={index} key={index}>
                  {
                    <AccountInfo
                      truncate={false}
                      showMenu={false}
                      {...token}
                      dense={true}
                    />
                  }
                </MenuItem>
              ))}
            </Select>
            {/* </FormControl> */}
          </Box>
          <Box sx={{ pt: 4 }}>
            <Typography variant="caption">Amount</Typography>
            <Card variant="outlined">
              <Input
                value={amount}
                onChange={(event) => setAmount(parseFloat(event.target.value))}
                fullWidth
                type="number"
                placeholder={
                  tokens[Number(selectedTokenIndex)]
                    ? tokens[Number(selectedTokenIndex)].amount
                    : '0.00'
                }
                sx={{ p: 2, border: 0 }}
                disableUnderline
              />
            </Card>
          </Box>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
          <Button label="Deposit" disabled={!amount} onClick={makeDeposit} />
        </CardActions>
      </Card>
    </Box>
  )
}
export default DepositForm
