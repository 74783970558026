import { useConnect } from 'wagmi'
import { useEffect } from 'react'

const AUTOCONNECTED_CONNECTOR_IDS = ['safe']

const FALLBACK_CONNECTOR_ID = 'metaMask'

function useAutoConnect() {
  const { connect, connectors } = useConnect()

  useEffect(() => {
    AUTOCONNECTED_CONNECTOR_IDS.forEach((connector) => {
      const connectorInstance = connectors.find(
        (c) => c.id === connector && c.ready,
      )

      if (connectorInstance) {
        connect({ connector: connectorInstance })
      } else {
        const fallbackConnector = connectors.find(
          (c) => c.id === FALLBACK_CONNECTOR_ID && c.ready,
        )

        if (fallbackConnector) {
          connect({ connector: fallbackConnector })
        }
      }
    })
  }, [connect, connectors])
}

export { useAutoConnect }
