import {
  Box,
  CardActions,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Button } from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WaterfallForm, { WaterfallData } from './WaterfallForm'
import { isAddress } from 'ethers/lib/utils.js'
import { WaterfallClient } from '@neobase-one/splits-sdk'
import { useProvider, useSigner, useAccount, useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useCreateWaterfallModule } from '@neobase-one/splits-sdk-react'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'

const Account: React.FC = () => {
  const defaultData: WaterfallData = {
    permittedToken: '',
    tranches: [
      { start: 0.0, end: 0.0, recipientAddress: '' },
      { start: 0.0, end: 0.0, recipientAddress: '' },
    ],
  }
  const provider = useProvider()
  const { data: signer } = useSigner()
  const navigate = useNavigate()
  const { address, isConnecting, isDisconnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const [data, setData] = React.useState<WaterfallData>(defaultData)
  const [formStatus, setFormStatus] = React.useState({
    pending: true,
    loading: false,
  })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [updateNotif, setUpdateNotif] = React.useState<string | number>()

  const { createWaterfallModule, status, txHash, error } =
    useCreateWaterfallModule()

  const theme = useTheme()

  React.useEffect(() => {
    if (
      data.tranches.every(
        (tranche, index) =>
          isAddress(tranche.recipientAddress) &&
          (index === data.tranches.length - 1 || tranche.end > tranche.start),
      ) &&
      isAddress(data.permittedToken)
    ) {
      setFormStatus({ ...formStatus, pending: false })
    } else {
      setFormStatus({ ...formStatus, pending: true })
    }
  }, [data])

  React.useEffect(() => {
    if (updateNotif) {
      closeSnackbar(updateNotif)
      setUpdateNotif(undefined)
    }
    console.log(status, txHash)
    if (status == 'pendingApproval') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Waterfall ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'txInProgress' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Waterfall ...`}
          transactionId={txHash}
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'complete' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Created Waterfall`}
          transactionId={txHash}
        />,
        {
          variant: 'success',
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'error') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction message={`Error while creating waterfall`} />,
        {
          variant: 'error',
        },
      )
      setUpdateNotif(notifKey)
    }
  }, [status, txHash])

  async function createWaterfallSplit(waterfallData: WaterfallData) {
    const newWaterfallData = {
      token: waterfallData.permittedToken,
      tranches: waterfallData.tranches.map((tranche, index) => {
        const trancheObj: { recipient: string; size?: number } = {
          recipient: tranche.recipientAddress,
          ...(index < waterfallData.tranches.length - 1 && {
            size: tranche.end - tranche.start,
          }),
        }
        return trancheObj
      }),
    }
    const events = await createWaterfallModule(newWaterfallData)
    const event = events && events.length > 0 ? events[0] : undefined
    if (event && event.args) {
      const splitId = event.args.waterfallModule
      setTimeout(() => navigate(`/accounts/${splitId}`), 2000)
    }
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        New Waterfall
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1 }}>
        A Waterfall is a payable smart contract that distributes funds in a
        pre-defined order.
      </Typography>
      <Box sx={{ mt: 10 }}>
        {<WaterfallForm data={data} setDataHandler={setData} />}
      </Box>
      <CardActions sx={{ justifyContent: 'center', mt: 6 }}>
        {isDisconnected ? (
          <Button onClick={() => connect()} label="Connect Wallet" />
        ) : status == 'txInProgress' || status == 'pendingApproval' ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={formStatus.pending || formStatus.loading}
            label="Create Waterfall"
            onClick={() => createWaterfallSplit(data)}
          />
        )}
      </CardActions>
    </Container>
  )
}
export default Account
