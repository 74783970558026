import { darkTheme, lightTheme } from '@neobase-one/neobase-components'
import { createTheme } from '@mui/material/styles'

const [customDarkThemeBase, customLightThemeBase] = [darkTheme, lightTheme].map(
  (theme) =>
    createTheme(theme, {
      spacing: (factor: any) => `${0.25 * factor}rem`,
      palette: {
        // divider: 'grey',
        info: {
          main: '#775a12',
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontFamily:
                'IBM Plex Mono,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
              scrollbarColor: '#6b6b6b #2b2b2b',
              '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                backgroundColor: '#6b6b6b',
              },
              '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                {
                  backgroundColor: '#959595',
                },
              '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                {
                  backgroundColor: '#959595',
                },
              '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                {
                  backgroundColor: '#959595',
                },
              '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: '#2b2b2b',
              },
              'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            title: {
              fontSize: darkTheme.typography.h6.fontSize,
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              ':last-child': {
                paddingBottom: '16px',
              },
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              fontFamily: 'inherit',
              border: 'none',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none !important',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            },
            columnSeparator: {
              display: 'none',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: 'IBM Plex Mono',
            },
            h4: {
              fontFamily: 'Silkscreen',
            },
            h5: {
              fontFamily: 'Silkscreen',
            },
            h6: {
              fontFamily: 'Silkscreen',
            },
            body1: {
              fontSize: '0.9rem',
            },
            paragraph: {
              fontSize: '0.9rem',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontFamily: 'inherit',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            outlined: {
              // borderRadius: 10,
              // borderColor: 'grey',
            },
            root: {
              borderRadius: 10,
              // borderColor: 'grey',
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              // textTransform: 'none',
              fontFamily: 'Silkscreen',
              // fontWeight: 800,
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              fontSize: '0.7rem',
              '&.MuiTypography': {
                fontSize: '0.7rem',
              },
              // textTransform: 'none',
              // fontFamily: 'Silkscreen',
              // fontWeight: 800,
            },
            '& .MuiTypography': {
              fontSize: '0.7rem',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // textTransform: 'none',
              fontFamily: 'Silkscreen',
              // fontWeight: 800,
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              '& .MuiAlert-icon': {
                alignItems: 'center',
              },
              '& .MuiAlert-action': {
                alignItems: 'center',
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              fontFamily: 'IBM Plex Mono',
              fontSize: '0.85rem',
            },
          },
        },
        // MuiModal: {
        //   styleOverrides: {
        //     backdrop: {
        //       backgroundColor: 'rgba(0, 0, 0, 0.8)',
        //     },
        //   },
        // },
      },
    }),
)

export const customDarkTheme = createTheme(customDarkThemeBase, {
  // palette: {
  //     secondary: {
  //         main: '#050505',
  //     },
  // },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#121212',
        },
      },
    },
  },
})

export const customLightTheme = createTheme(customLightThemeBase, {
  // palette: {
  //     secondary: {
  //         main: '#050505',
  //     },
  // },
})
