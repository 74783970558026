import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { ColorModeContext } from '../context'
import {
  ThemeToggle,
  MenuButton,
  Button,
  ButtonGroup,
  IconButton,
  AddressInput,
} from '@neobase-one/neobase-components'
import HomeIcon from '@mui/icons-material/Home'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  useDisconnect,
  useConnect,
  useAccount,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi'
import { Button as MuiButton } from '@mui/material'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MenuItemProps } from '@neobase-one/neobase-components/lib/components/Utility/MenuButton/MenuButton'
import { IconButtonProps } from '@neobase-one/neobase-components/lib/components/Basic/IconButton/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils.js'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Stack, Typography } from '@mui/material'
import { AccountInfo } from '@neobase-one/neobase-components'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { MessageWithTransaction } from '../utils'
import { useSnackbar } from 'notistack'
import SyncLockIcon from '@mui/icons-material/SyncLock'
import MenuIcon from '@mui/icons-material/Menu'
import { useAutoConnect } from '../useAutoConnect'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
const style = {
  position: 'absolute',
  top: '12%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
}
import StarIcon from '@mui/icons-material/Star'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const colorMode = React.useContext(ColorModeContext)
  const [openSearch, setOpenSearch] = React.useState<boolean>(false)
  const [searchAddress, setSearchAddress] = React.useState<string>('')

  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { address, isConnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const { disconnect } = useDisconnect()
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )
  useAutoConnect()
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  React.useEffect(() => {
    if (isConnected && chain && switchNetwork) {
      if (chain.id !== 7700) {
        enqueueSnackbar(
          <Stack>
            <Typography>Network not supported</Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1.5}
              onClick={() => switchNetwork(7700)}
              sx={{ cursor: 'pointer' }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.disabled,
                  textDecoration: 'underline',
                }}
              >
                switch to canto
              </Typography>
            </Box>
          </Stack>,
          { persist: true, key: 'switch_network' },
        )
      } else {
        closeSnackbar('switch_network')
      }
    }
  }, [isConnected, chain, switchNetwork])

  React.useEffect(() => {
    if (isConnected && address) {
      if (
        matchPath(location.pathname, '/') ||
        matchPath(location.pathname, '/accounts')
      ) {
        navigate(`/accounts/${address}`)
      }
    }
  }, [isConnected, address])

  const pages = [
    { label: 'Home', link: '/', startIcon: <HomeIcon /> },
    { label: 'Create', link: '/create', startIcon: <AddCircleOutlineIcon /> },
    // { label: 'Split', link: '/split', startIcon: <CallSplitIcon /> },
    // {
    //   label: 'Waterfall',
    //   link: '/waterfall',
    //   startIcon: <WaterfallChartIcon />,
    // },
    {
      label: 'CSR Vault',
      link: '/vault',
      startIcon: <SyncLockIcon />,
    },
    // { label: 'Explore', link: '/explore', startIcon: <DocumentScannerIcon /> },
    // {
    //   label: 'Wallet',
    //   link: address ? `/accounts/${address}` : '/accounts',
    //   startIcon: <AccountBalanceWalletIcon />,
    // },
  ]

  const menuInput = pages.map(
    (page) =>
      ({
        ...page,
        disabled: matchPath(location.pathname, page.link) !== null,
        onClick: () => navigate(page.link),
      } as MenuItemProps),
  )

  const butttonGroupInput = pages.map(
    (page) =>
      ({
        ...page,
        onClick: () => navigate(page.link),
        toolTipProps: { title: page.label },
        color:
          matchPath(location.pathname, page.link) !== null
            ? theme.palette.mode == 'light'
              ? 'secondary'
              : 'primary'
            : 'inherit',
        sx: {
          borderRight: 0,
          textTransform: 'none',
        },
        variant: 'text',
        size: 'large',
      } as IconButtonProps),
  )

  React.useEffect(() => {
    if (!openSearch && isAddress(searchAddress)) {
      navigate(`/accounts/${searchAddress}`)
      setSearchAddress('')
    }
  }, [openSearch])

  return (
    <Box>
      <Modal
        open={openSearch}
        onClose={() => {
          setOpenSearch(false)
        }}
      >
        <Grid
          container
          sx={{
            ...style,
            background: theme.palette.background.paper,
            maxWidth: '90vw',
          }}
          p={4}
          alignItems="center"
        >
          <SearchIcon />
          <Grid item ml={1} xs>
            <AddressInput
              value={searchAddress}
              setValue={(event) => {
                setSearchAddress(event)
              }}
              showStatus={false}
              placeholder="0x0000...000"
              provider={ensProvider}
              reDirect={() => {
                setOpenSearch(false)
              }}
              autoFocus
              cardProps={{ sx: { border: 0 } }}
            />
          </Grid>
        </Grid>
      </Modal>
      <AppBar
        position="static"
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
          py: -1,
        }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {desktopView ? (
              <ButtonGroup
                iconButtons={butttonGroupInput}
                sx={{ boxShadow: 0 }}
              />
            ) : (
              <MenuButton buttons={menuInput}>
                <IconButton
                  color="secondary"
                  sx={{ color: 'white' }}
                  startIcon={<MenuIcon />}
                />
              </MenuButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 0, px: 2 }}>
              <IconButton
                onClick={() => setOpenSearch(true)}
                color="secondary"
                sx={{ color: 'white', mr: 1 }}
                size="large"
                toolTipProps={{ title: 'Search' }}
                startIcon={<SearchIcon sx={{ color: 'white' }} />}
              />
              <ThemeToggle
                onClick={colorMode.toggleColorMode}
                sx={{ color: 'white', mr: 1 }}
                color="secondary"
                size="large"
                toolTipProps={{ title: 'Toggle theme' }}
              />
              <IconButton
                startIcon={<AccountBalanceWalletIcon />}
                onClick={() =>
                  navigate(address ? `/accounts/${address}` : '/accounts')
                }
                toolTipProps={{ title: 'Wallet' }}
                color={
                  matchPath(
                    location.pathname,
                    address ? `/accounts/${address}` : '/accounts',
                  ) !== null
                    ? theme.palette.mode == 'light'
                      ? 'secondary'
                      : 'primary'
                    : 'inherit'
                }
                sx={{
                  borderRight: 0,
                  textTransform: 'none',
                }}
                variant={'text'}
                size={'large'}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
              }}
            >
              {isConnected && address ? (
                <>
                  <MenuButton
                    buttons={[
                      {
                        label: 'Copy Address',
                        onClick: () => {
                          navigator.clipboard.writeText(address)
                          enqueueSnackbar(
                            <MessageWithTransaction message="Address copied" />,
                          )
                        },
                        startIcon: <ContentCopyIcon fontSize="small" />,
                      },
                      {
                        label: 'Open Explorer',
                        onClick: () =>
                          window.open(
                            'https://tuber.build/address/' + address,
                            '_blank',
                          ),
                        startIcon: <OpenInNewIcon fontSize="small" />,
                      },
                      {
                        label: 'Bookmarks',
                        onClick: () => navigate('bookmarks'),
                        startIcon: <StarIcon fontSize="small" />,
                      },
                      {
                        label: 'Report bug',
                        onClick: () =>
                          window.open(
                            'https://neobase.canny.io/splits-bug-reports',
                            '_blank',
                          ),
                        startIcon: <ReportGmailerrorredIcon fontSize="small" />,
                      },
                      {
                        label: 'Disconnect',
                        onClick: () => disconnect(),
                        startIcon: <LogoutIcon fontSize="small" />,
                      },
                    ]}
                  >
                    <MuiButton onClick={() => console.log('lol')} sx={{ p: 0 }}>
                      <AccountInfo
                        address={address}
                        showMenu={false}
                        dense={true}
                        buttonProps={{
                          sx: { color: 'white', minWidth: 170 },
                        }}
                      />
                    </MuiButton>
                  </MenuButton>
                </>
              ) : (
                <Button
                  label="Connect Wallet"
                  // endIcon={<LogoutIcon />}
                  variant="contained"
                  color={
                    theme.palette.mode == 'light' ? 'secondary' : 'primary'
                  }
                  sx={{
                    textTransform: 'none',
                    width: '180px',
                  }}
                  onClick={() => {
                    connect()
                  }}
                />
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
export default Header
