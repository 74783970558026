import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  AddressInput,
  Button,
  ToggleButtonGroup,
} from '@neobase-one/neobase-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SplitsForm, { SplitsData } from './SplitsForm'
import { isAddress } from 'ethers/lib/utils.js'
import { SplitsClient, LiquidSplitClient } from '@neobase-one/splits-sdk'
import {
  useCreateSplit,
  useCreateLiquidSplit,
} from '@neobase-one/splits-sdk-react'
import { useProvider, useSigner, useAccount, useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { ethers } from 'ethers'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import { useSearchParams } from 'react-router-dom'

type SplitsType = 'immutable' | 'mutable' | 'liquid'

const Account: React.FC = () => {
  const defaultData: SplitsData = {
    recipients: [
      { address: '', percentAllocation: 0 },
      { address: '', percentAllocation: 0 },
    ],
    distributorFeePercent: 1.0,
    controller: '',
  }
  const provider = useProvider()
  const { data: signer } = useSigner()
  const navigate = useNavigate()
  const { address, isConnecting, isDisconnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const [data, setData] = React.useState<SplitsData>(defaultData)
  const [splitType, setSplitType] = React.useState<SplitsType>('immutable')
  const [predictAddress, setPridictAdrress] = React.useState({
    isLoading: false,
    address: '',
    error: '',
  })

  const { createSplit, status, txHash, error } = useCreateSplit()
  const {
    createLiquidSplit,
    status: liquidStatus,
    txHash: liquidTxHash,
    error: liquidError,
  } = useCreateLiquidSplit()
  const [creatingLiquidSplit, setCreatingLiquidSplit] =
    React.useState<boolean>(false)
  const [formStatus, setFormStatus] = React.useState({
    pending: true,
  })
  const [updateNotif, setUpdateNotif] = React.useState<string | number>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [searchParams, setSearchParams] = useSearchParams()

  React.useEffect(() => {
    if (updateNotif) {
      closeSnackbar(updateNotif)
      setUpdateNotif(undefined)
    }
    console.log(status, txHash)
    if (status == 'pendingApproval') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Split ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'txInProgress' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Split ...`}
          transactionId={txHash}
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'complete' && txHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Created split`}
          transactionId={txHash}
        />,
        {
          variant: 'success',
        },
      )
      setUpdateNotif(notifKey)
    }
    if (status == 'error') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction message={`Error while creating split`} />,
        {
          variant: 'error',
        },
      )
      setUpdateNotif(notifKey)
    }
  }, [status, txHash])

  React.useEffect(() => {
    if (updateNotif) {
      closeSnackbar(updateNotif)
      setUpdateNotif(undefined)
    }
    if (liquidStatus == 'pendingApproval') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Liquid Split ...`}
          description="Waiting for wallet approval"
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (liquidStatus == 'txInProgress' && liquidTxHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Creating Liquid Split ...`}
          transactionId={liquidTxHash}
        />,
        {
          variant: 'warning',
          persist: true,
          transitionDuration: { enter: 225, exit: 0 },
        },
      )
      setUpdateNotif(notifKey)
    }
    if (liquidStatus == 'complete' && liquidTxHash) {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Created liquid split`}
          transactionId={liquidTxHash}
        />,
        {
          variant: 'success',
        },
      )
      setUpdateNotif(notifKey)
    }
    if (liquidStatus == 'error') {
      const notifKey = enqueueSnackbar(
        <MessageWithTransaction
          message={`Error while creating liquid split`}
        />,
        {
          variant: 'error',
        },
      )
      setUpdateNotif(notifKey)
    }
  }, [liquidStatus, liquidTxHash])

  React.useEffect(() => {
    if (address && isAddress(address)) {
      setData({ ...data, controller: address })
    }
  }, [address])

  function isSplitsType(keyInput: string): keyInput is SplitsType {
    return ['immutable', 'mutable', 'liquid'].includes(keyInput)
  }

  React.useEffect(() => {
    const type = searchParams.get('type')
    if (type && isSplitsType(type)) {
      setSplitType(type)
    }
  }, [searchParams])

  const theme = useTheme()

  const handleSetType = (
    event: React.MouseEvent<HTMLElement>,
    newSplitType: SplitsType | null,
  ) => {
    if (newSplitType !== null) {
      setSplitType(newSplitType)
    }
  }

  const validateData = (data: SplitsData) =>
    data.recipients.every(
      (recipient) =>
        isAddress(recipient.address) &&
        recipient.percentAllocation > 0 &&
        recipient.percentAllocation < 100,
    ) &&
    data.distributorFeePercent < 10 &&
    data.recipients.reduce(
      (curr, next) =>
        Number(
          (curr + next.percentAllocation).toFixed(
            splitType === 'liquid' ? 1 : 2,
          ),
        ),
      0,
    ) == 100 &&
    (splitType !== 'mutable' || (data.controller && isAddress(data.controller)))

  React.useEffect(() => {
    validateData(data)
      ? setFormStatus({ pending: false })
      : setFormStatus({ pending: true })
  }, [data])

  React.useEffect(() => {
    async function getPredicted(splitsClient: SplitsClient) {
      try {
        const { splitId } = await splitsClient.predictImmutableSplitAddress(
          data,
        )
        setPridictAdrress({ address: splitId, isLoading: false, error: '' })
      } catch (err: any) {
        setPridictAdrress({ address: '', isLoading: false, error: err.message })
      }
    }
    if (validateData(data) && provider !== undefined) {
      setPridictAdrress({ address: '', isLoading: true, error: '' })
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      getPredicted(splitsClient)
    } else {
      setPridictAdrress({ address: '', isLoading: false, error: '' })
    }
  }, [formStatus, data])

  const splitTypeOptions = [
    {
      value: 'immutable',
      children: [
        <Typography textAlign="left">Immutable Split</Typography>,
        <Typography
          textAlign="left"
          variant="caption"
          color={theme.palette.text.secondary}
        >
          Recipients cannot be updated once the split is deployed
        </Typography>,
      ],
    },
    {
      value: 'mutable',
      children: [
        <Typography sx={{ fontWeight: 600 }} textAlign="left">
          Mutable Split
        </Typography>,
        <Typography
          textAlign="left"
          variant="caption"
          color={theme.palette.text.secondary}
        >
          Recipients can be updated by the Controlling Address
        </Typography>,
      ],
    },
    {
      value: 'liquid',
      children: [
        <Typography textAlign="left">Liquid Split</Typography>,
        <Typography
          textAlign="left"
          variant="caption"
          color={theme.palette.text.secondary}
        >
          Recipients are represented by transferrable NFTs
        </Typography>,
      ],
    },
  ]

  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  async function createNewSplit() {
    if (splitType === 'liquid') {
      const events = await createLiquidSplit({
        recipients: data.recipients,
        distributorFeePercent: data.distributorFeePercent,
        createClone: true,
      })
      const event = events && events.length > 0 ? events[0] : undefined
      if (event && event.args) {
        const splitId = event.args.ls
        setTimeout(() => navigate(`/accounts/${splitId}`), 2000)
      }
    } else {
      const events = await createSplit({
        recipients: data.recipients,
        distributorFeePercent: data.distributorFeePercent,
        controller: splitType == 'mutable' ? data.controller : undefined,
      })
      const event = events && events.length > 0 ? events[0] : undefined
      if (event && event.args) {
        const splitId = event.args.split
        setTimeout(() => navigate(`/accounts/${splitId}`), 2000)
      }
    }
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Typography variant="h4" sx={{ pt: 2 }}>
        New Split
      </Typography>
      <Typography color={theme.palette.text.secondary} sx={{ pt: 1 }}>
        A Split is a payable smart contract that splits all CANTO & ERC20 tokens
        it receives.
      </Typography>
      <Box sx={{ mt: 10 }}>
        <ToggleButtonGroup
          value={splitType}
          exclusive
          onChange={handleSetType}
          fullWidth
          // color="primary"
          options={splitTypeOptions}
          buttonProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              textTransform: 'none',
              alignItems: 'start',
            },
          }}
          color="primary"
        ></ToggleButtonGroup>
      </Box>
      <Box sx={{ mt: 10 }}>
        {
          <SplitsForm
            data={data}
            setDataHandler={setData}
            decimals={splitType === 'liquid' ? 1 : 2}
          />
        }
      </Box>
      {splitType == 'immutable' && (
        <Card sx={{ mt: 10 }} variant="outlined">
          <CardHeader title="Contract Address"></CardHeader>
          <Divider />
          <CardContent>
            <Typography>
              Immutable Splits use CREATE2 to determine the Split's address
              before it's actually deployed. The Split must eventually be
              deployed for recipients to withdraw their funds.
            </Typography>
            <Box sx={{ pt: 3, display: 'flex' }}>
              {predictAddress.isLoading ? (
                <CircularProgress />
              ) : (
                <AddressInput
                  value={predictAddress.address}
                  setValue={(event) => undefined}
                  disabled
                  placeholder="0xXXXX...XXX"
                  cardProps={{ sx: { flexGrow: 1 } }}
                  showStatus={false}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      )}
      {splitType == 'mutable' && (
        <Card sx={{ mt: 10 }} variant="outlined">
          <CardHeader title="Controlling Address"></CardHeader>
          <Divider />
          <CardContent>
            <Box sx={{ pb: 3, display: 'flex' }}>
              <AddressInput
                value={data.controller ? data.controller : ''}
                setValue={(val) => setData({ ...data, controller: val })}
                placeholder="0xXXXX...XXX"
                cardProps={{ sx: { flexGrow: 1 } }}
                provider={ensProvider}
              />
              <Button
                label="clear"
                variant="outlined"
                sx={{ ml: 4 }}
                onClick={() => setData({ ...data, controller: '' })}
              />
            </Box>
            <Typography>
              The Controlling Address is the{' '}
              <Typography color="error" paragraph={false} display="contents">
                only account
              </Typography>{' '}
              that can change the Split once it's been created. Make sure this
              is an EOA or contract (e.g., a multisig) that can interact with
              the Split contract.
            </Typography>
          </CardContent>
        </Card>
      )}
      <CardActions
        sx={{ justifyContent: 'center', mt: 6, flexDirection: 'column' }}
      >
        {isDisconnected ? (
          <Button onClick={() => connect()} label="Connect Wallet" />
        ) : status == 'txInProgress' ||
          status == 'pendingApproval' ||
          liquidStatus == 'txInProgress' ||
          liquidStatus == 'pendingApproval' ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={
              formStatus.pending ||
              (splitType == 'immutable' &&
                (!predictAddress.address || predictAddress.isLoading))
            }
            label="Create Split"
            onClick={createNewSplit}
          />
        )}
      </CardActions>
    </Container>
  )
}
export default Account
