import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const Footer: React.FC = () => {
  const theme = useTheme()
  return (
    <Box sx={{ pt: 6 }}>
      <AppBar
        position="static"
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters variant="dense">
            <Box
              sx={{
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              <Typography
                // fontSize={'small'}
                // variant="h5"
                sx={{
                  fontSize: '.8rem',
                  fontFamily: 'Silkscreen',
                  color:
                    theme.palette.mode == 'dark'
                      ? theme.palette.primary.main
                      : 'white',
                }}
              >
                Powered by NeoBase
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
export default Footer
