import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  Modal,
  Box,
} from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode'
import * as React from 'react'
import {
  SplitsClient,
  WaterfallClient,
  WaterfallModule,
} from '@neobase-one/splits-sdk'
import { useEffect } from 'react'
import { AccountInfo, IconButton } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { isAddress } from '@ethersproject/address'
import { useTheme } from '@mui/material/styles'
import { useProvider, useSigner } from 'wagmi'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import {
  getSplitEarningsInfo,
  getVestingEarningsInfo,
  getWaterfallEarningsInfo,
  queryRelated,
} from '../utils'
import AccountSkeleton from './AccountSkeleton'
import QRCode from 'react-qr-code'
import DepositForm from './DepositForm'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'
import { MetaDataContext } from '../context'
import RefreshIcon from '@mui/icons-material/Refresh'
import VestingForm from './VestingForm'
import { useSnackbar } from 'notistack'
import { MessageWithTransaction } from '../utils'
import CSRList from './CSRList'
import DepositTokenForm from './DepositVaultForm'
import AccountLabel from './AccountLabel'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'

const WaterfallAccount: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [waterfall, setWaterfall] = React.useState<WaterfallModule>()
  const [earnings, setEarnings] = React.useState<{
    balances: AccountInfoProps[]
    distributed: AccountInfoProps[]
    totalAmount: string
    activeAmount: string
    distributedAmount: string
  }>()
  const { address, isConnected } = useAccount()
  const [openDeposit, setOpenDeposit] = React.useState<boolean>(false)
  const [openVesting, setOpenVesting] = React.useState<boolean>(false)
  const [loadingRelated, setLoadingRelated] = React.useState<boolean>(false)
  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false)
  const [receiving, setReceiving] = React.useState<AccountInfoProps[]>()
  const [bookmarked, setBookmarked] = React.useState(false)

  //   const [accountEvents, setAccountEvents] = React.useState<
  //     {
  //       avatar: JSX.Element
  //       text: string
  //     }[]
  //   >()

  const provider = useProvider()
  const { data: signer } = useSigner()
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    maxWidth: '90vw',
    maxHeight: '90vh',
    boxShadow: 24,
  }
  const [loadingWaterfall, setLoadingWaterfall] = React.useState<boolean>(false)
  const [loadingEarnings, setLoadingEarnings] = React.useState<boolean>(false)
  const [openQR, setOpenQR] = React.useState<boolean>(false)
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [trigger, SetTrigger] = React.useState(0)
  const [openDepositToken, SetOpenDepositToken] = React.useState<boolean>(false)

  async function queryWaterfall(
    waterfallClient: WaterfallClient,
    waterfallModuleId: string,
  ) {
    setLoadingWaterfall(true)
    const waterfall = await waterfallClient.getWaterfallMetadata({
      waterfallModuleId,
    })
    // console.log(waterfall)
    setWaterfall(waterfall)
  }

  async function queryEarnings(
    waterfallClient: WaterfallClient,
    waterfallId: string,
    token: any,
  ) {
    setLoadingEarnings(true)
    const {
      activeAmount,
      activeBalances,
      distributedAmount,
      distributedBalances,
      totalAmount,
    } = await getWaterfallEarningsInfo(
      waterfallClient,
      waterfallId,
      token,
      useFetchMetadata,
    )
    setEarnings({
      activeAmount: activeAmount,
      balances: activeBalances,
      distributedAmount: distributedAmount,
      distributed: distributedBalances,
      totalAmount: totalAmount,
    })
    setLoadingEarnings(false)
  }

  useEffect(() => {
    if (params.accountID && waterfall && params.accountID !== waterfall.id) {
      setWaterfall(undefined)
    }
    if (params.accountID && isAddress(params.accountID) && provider) {
      const waterfallClient = new WaterfallClient({
        chainId: 7700,
        provider,
      })
      queryWaterfall(waterfallClient, params.accountID)
    }
  }, [params.accountID])

  useEffect(() => {
    if (
      params.accountID &&
      isAddress(params.accountID) &&
      provider &&
      waterfall
    ) {
      const waterfallClient = new WaterfallClient({
        chainId: 7700,
        provider,
      })
      queryEarnings(waterfallClient, params.accountID, waterfall.token)
    }
  }, [waterfall])

  useEffect(() => {
    const bookmarksString = localStorage.getItem('splits-bookmarks')
    if (bookmarksString && params.accountID) {
      const allBookmarks: string[] = JSON.parse(bookmarksString)
      if (allBookmarks.includes(params.accountID)) {
        setBookmarked(true)
      }
    }
  }, [params.accountID])

  const toggleBookmark = async () => {
    if (params.accountID) {
      const bookmarksString = localStorage.getItem('splits-bookmarks')
      let allBookmarks: string[] = []
      if (bookmarksString) {
        allBookmarks = JSON.parse(bookmarksString)
        if (allBookmarks.includes(params.accountID)) {
          allBookmarks = allBookmarks.filter(
            (address) => address != params.accountID,
          )
          setBookmarked(false)
        } else {
          allBookmarks.push(params.accountID)
          setBookmarked(true)
        }
      } else {
        allBookmarks = [params.accountID]
        setBookmarked(true)
      }
      localStorage.setItem('splits-bookmarks', JSON.stringify(allBookmarks))
    }
  }

  useEffect(() => {
    async function setRelated(splitsClient: SplitsClient, splitId: string) {
      setLoadingRelated(true)
      const { waterfalls, donors, liquidDonors, vestingModules } =
        await queryRelated(
          splitsClient,
          splitId,
          useFetchMetadata,
          true,
          navigate,
          theme,
          enqueueSnackbar,
        )

      setReceiving([
        ...donors,
        ...liquidDonors,
        ...waterfalls,
        ...vestingModules,
      ])
      setLoadingRelated(false)
      setLoadingWaterfall(false)
    }

    if (
      params.accountID &&
      isAddress(params.accountID) &&
      provider &&
      waterfall
    ) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider: provider,
      })
      setRelated(splitsClient, params.accountID)
    }
  }, [waterfall])

  //   useEffect(() => {
  //     async function getAccountEvents(split: any) {
  //       const events = await Promise.all(
  //         split.accountEvents.map(async (event: any) => {
  //           return await getEventData(event, provider)
  //         }),
  //       )
  //       setAccountEvents(events)
  //     }
  //     if (waterfall && provider) {
  //       getAccountEvents(waterfall)
  //     } else {
  //       setAccountEvents(undefined)
  //     }
  //   }, [waterfall, provider])

  const distributeBalance = () => {
    const distributeToken = async (waterfallClient: WaterfallClient) => {
      if (waterfall) {
        let distributingNotif = enqueueSnackbar(
          <MessageWithTransaction
            message={`Distributing balance ...`}
            description="Waiting for wallet approval"
          />,
          {
            variant: 'warning',
            persist: true,
            transitionDuration: { enter: 225, exit: 0 },
          },
        )
        try {
          const waterfallModuleId = waterfall.id
          const { tx: waterfallFundsTx } =
            await waterfallClient.submitWaterfallFundsTransaction({
              waterfallModuleId,
            })
          closeSnackbar(distributingNotif)
          setTimeout(() => {
            distributingNotif = enqueueSnackbar(
              <MessageWithTransaction
                message={`Distributing balance ...`}
                transactionId={waterfallFundsTx.hash}
              />,
              {
                variant: 'warning',
                persist: true,
                transitionDuration: { enter: 225, exit: 0 },
              },
            )
          }, 200)

          await waterfallFundsTx.wait()
          closeSnackbar(distributingNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Successfully distributed balance`}
                transactionId={waterfallFundsTx.hash}
              />,
              {
                variant: 'success',
              },
            )
          }, 200)
          setTimeout(() => {
            queryWaterfall(waterfallClient, waterfallModuleId)
            queryEarnings(waterfallClient, waterfallModuleId, waterfall.token)
          }, 3000)
        } catch (err) {
          console.log(err)
          closeSnackbar(distributingNotif)
          setTimeout(() => {
            enqueueSnackbar(
              <MessageWithTransaction
                message={`Error while distributing balance`}
              />,
              {
                variant: 'error',
              },
            )
          }, 200)
        }
      }
    }
    if (provider && signer) {
      const waterfallClient = new WaterfallClient({
        chainId: 7700,
        provider,
        signer,
      })
      distributeToken(waterfallClient)
    }
  }

  if (!params.accountID || !isAddress(params.accountID)) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          flexGrow: 1,
          py: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>Couldn't locate address</>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ alignSelf: 'start', pt: 6 }}>
      <Modal
        open={openQR}
        onClose={() => setOpenQR(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={{ ...modalStyles, width: 300 }}>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AccountInfo
                address={params.accountID}
                showMenu={false}
                showButtonGroup={false}
                expandOnHover={true}
                dense={true}
                truncate={false}
                provider={ensProvider}
              />
              <Box sx={{ p: 20 }}>
                <Card sx={{ px: 2, background: 'white', pb: '4px', pt: 2 }}>
                  <QRCode size={200} value={params.accountID} />
                </Card>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
      {loadingWaterfall || loadingEarnings || loadingRelated ? (
        <AccountSkeleton />
      ) : (
        <>
          <Card sx={{ width: '100%', display: 'flex' }} variant="outlined">
            <IconButton
              startIcon={<ArrowBackIcon />}
              sx={{ px: 2 }}
              onClick={() => navigate(-1)}
            />
            <AccountInfo
              address={params.accountID}
              showMenu={false}
              showButtonGroup={true}
              expandOnHover={true}
              dense={false}
              truncate={!desktopView}
              provider={ensProvider}
              startElement={<AccountLabel type="Waterfall" />}
              onCopy={() =>
                enqueueSnackbar(
                  <MessageWithTransaction message="Address copied" />,
                )
              }
              buttonProps={{
                onClick: () => {
                  if (params.accountID) {
                    navigator.clipboard.writeText(params.accountID)
                    enqueueSnackbar(
                      <MessageWithTransaction message="Address copied" />,
                    )
                  }
                },
              }}
              iconButtons={[
                {
                  startIcon: <RefreshIcon />,
                  sx: { px: 2 },
                  onClick: () => {
                    if (params.accountID && provider && waterfall) {
                      const waterfallId = params.accountID
                      const waterfallClient = new WaterfallClient({
                        chainId: 7700,
                        provider,
                      })
                      queryWaterfall(waterfallClient, waterfallId)
                    }
                  },
                  toolTipProps: { title: 'Refresh' },
                },
                {
                  startIcon: bookmarked ? <StarIcon /> : <StarBorderIcon />,
                  sx: { px: 2 },
                  onClick: toggleBookmark,
                  toolTipProps: {
                    title: bookmarked ? 'Remove bookmark' : 'Add bookmark',
                  },
                },
              ]}
            />
          </Card>
          <Grid container spacing={6} sx={{ pt: 6 }}>
            {waterfall && (
              <Grid item xs={12}>
                <CSRList
                  recipient={waterfall.id}
                  trigger={trigger}
                  refresh={() => {
                    SetTrigger((trigger) => trigger + 1)
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Grid container flexDirection="column" spacing={6}>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Waterfall"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Typography paragraph={true}>
                        All {waterfall?.token.symbol} received will be released
                        sequentially to these {waterfall?.tranches.length}{' '}
                        tranches
                      </Typography>
                      <Stack
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                        spacing={2}
                      >
                        {waterfall &&
                          waterfall.tranches.map((tranche, index) => (
                            <React.Fragment key={`Tranche_${index}`}>
                              <AccountInfo
                                address={tranche.recipientAddress}
                                showMenu={false}
                                dense={true}
                                buttonProps={{
                                  onClick: () =>
                                    navigate(
                                      `/accounts/${tranche.recipientAddress}`,
                                    ),
                                }}
                                amount={`${tranche.startAmount} ${
                                  waterfall.token.symbol
                                }
                                  ${
                                    tranche.size
                                      ? `- ${
                                          tranche.startAmount + tranche.size!
                                        } ${waterfall.token.symbol}`
                                      : '+'
                                  }`}
                              />
                            </React.Fragment>
                          ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Grid container>
                          <Grid item xs>
                            Earnings
                          </Grid>
                          <Grid item>${earnings?.totalAmount}</Grid>
                        </Grid>
                      }
                    ></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack
                        pl={1}
                        spacing={2}
                        pt={1}
                        sx={{ maxHeight: 500, overflowY: 'auto' }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography>Balances</Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              disabled={
                                earnings && earnings.distributed.length === 0
                              }
                              variant="outlined"
                              onClick={() => distributeBalance()}
                            >
                              Distribute
                            </Button>
                          </Grid>
                        </Grid>
                        {earnings && earnings.balances.length > 0 && (
                          <React.Fragment>
                            {earnings.balances.map((balance, index) => (
                              <React.Fragment key={`Balance_${index}`}>
                                <Stack>
                                  <Grid container alignItems="center">
                                    <Grid item xs>
                                      <AccountInfo
                                        truncate={false}
                                        showMenu={false}
                                        {...balance}
                                        dense={true}
                                        buttonProps={{ sx: { py: 2 } }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </React.Fragment>
                            ))}
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Balances
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.activeAmount}</Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                        {earnings && earnings.distributed.length > 0 && (
                          <>
                            <Typography sx={{ paddingTop: '0.5rem' }}>
                              Distributed
                            </Typography>
                            <Stack>
                              {earnings.distributed.map(
                                (distribution, index) => (
                                  <React.Fragment key={`Distributed_${index}`}>
                                    <AccountInfo
                                      truncate={false}
                                      showMenu={false}
                                      {...distribution}
                                      dense={true}
                                      buttonProps={{ sx: { py: 2 } }}
                                    />
                                  </React.Fragment>
                                ),
                              )}
                            </Stack>
                            <Divider />
                            <Grid container pr={2}>
                              <Grid item xs>
                                <Typography color="secondary">
                                  Total Distributed
                                </Typography>
                              </Grid>
                              <Grid item>${earnings?.distributedAmount}</Grid>
                            </Grid>
                          </>
                        )}
                        {earnings &&
                          earnings.balances.length === 0 &&
                          earnings.distributed.length === 0 && (
                            <Grid container>
                              <Grid item xs={12} my={3}>
                                <Card sx={{ background: '#1f2937', p: 2 }}>
                                  Get started by depositing funds into this
                                  waterfall contract
                                </Card>
                              </Grid>
                            </Grid>
                          )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="History"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack sx={{ maxHeight: 500, overflowY: 'auto' }}>
                        {accountEvents &&
                          accountEvents.map(({ avatar, text }, index) => (
                            <Grid
                              container
                              key={`Event_${index}`}
                              spacing={2}
                              alignItems="center"
                            >
                              <Grid item>{avatar}</Grid>
                              <Grid item xs>
                                {text}
                              </Grid>
                            </Grid>
                          ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Grid container flexDirection="column" spacing={6}>
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Address"></CardHeader>
                    <Divider />
                    <CardContent>
                      <Stack spacing={2} py={1}>
                        <Card>
                          <AccountInfo
                            address={params.accountID}
                            showMenu={false}
                            showButtonGroup={true}
                            openExplorer={false}
                            showAvatar={false}
                            truncate={false}
                            onCopy={() =>
                              enqueueSnackbar(
                                <MessageWithTransaction message="Address copied" />,
                              )
                            }
                            sx={{
                              background:
                                theme.palette.mode == 'light'
                                  ? theme.palette.grey[200]
                                  : theme.palette.grey[900],
                            }}
                            iconButtons={[
                              {
                                toolTipProps: {
                                  title: 'View QR Code',
                                },
                                startIcon: (
                                  <QrCodeIcon onClick={() => setOpenQR(true)} />
                                ),
                              },
                            ]}
                          />
                        </Card>
                        <Typography
                          paragraph={true}
                          sx={{ padding: '1rem 0rem' }}
                        >
                          Funds sent to this address will be split among the
                          recipients.
                        </Typography>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            fontWeight: 'bold',
                          }}
                          disabled={!isConnected}
                          onClick={() => setOpenDeposit(true)}
                        >
                          Deposit Funds
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isConnected}
                          onClick={() => setOpenVesting(true)}
                        >
                          New Vesting Module
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isConnected}
                          onClick={() => SetOpenDepositToken(true)}
                        >
                          Deposit CSR Token
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {receiving && receiving.length > 0 && (
                  <Grid item>
                    <Card variant="outlined">
                      <CardHeader title="Receiving from"></CardHeader>
                      <Divider />
                      <CardContent>
                        <Stack
                          sx={{ maxHeight: 500, overflowY: 'auto' }}
                          spacing={2}
                          divider={<Divider />}
                        >
                          {receiving &&
                            receiving.map((accountInfo, index) => (
                              <AccountInfo
                                dense={true}
                                key={`Receiving_${index}`}
                                {...accountInfo}
                              />
                            ))}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {address && isConnected && waterfall && (
            <Modal
              open={openDeposit}
              onClose={() => {
                setOpenDeposit(false)
              }}
              keepMounted={true}
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <Box sx={{ ...modalStyles }}>
                {
                  <DepositForm
                    from={address}
                    to={params.accountID}
                    onClose={() => {
                      setOpenDeposit(false)
                    }}
                    refresh={() => {
                      const waterfallClient = new WaterfallClient({
                        chainId: 7700,
                      })
                      setTimeout(() => {
                        queryWaterfall(waterfallClient, waterfall.id)
                      }, 3000)
                    }}
                  />
                }
              </Box>
            </Modal>
          )}
        </>
      )}
      {address && waterfall && isConnected && (
        <Modal
          open={openVesting}
          onClose={() => {
            setOpenVesting(false)
          }}
          keepMounted={true}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <Box sx={{ ...modalStyles, width: 600 }}>
            {
              <VestingForm
                to={params.accountID}
                onClose={() => {
                  setOpenVesting(false)
                }}
                refresh={() => {
                  const waterfallClient = new WaterfallClient({
                    chainId: 7700,
                    provider,
                  })
                  setTimeout(() => {
                    queryWaterfall(waterfallClient, waterfall.id)
                  }, 3000)
                }}
              />
            }
          </Box>
        </Modal>
      )}
      {address && waterfall && isConnected && (
        <Modal
          open={openDepositToken}
          onClose={() => {
            SetOpenDepositToken(false)
          }}
          keepMounted={true}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <Box sx={{ ...modalStyles, width: 600 }}>
            {
              <DepositTokenForm
                trigger={trigger}
                to={waterfall.id}
                onClose={() => {
                  SetOpenDepositToken(false)
                }}
                refresh={() => {
                  SetTrigger((trigger) => trigger + 1)
                }}
              />
            }
          </Box>
        </Modal>
      )}
    </Container>
  )
}
export default WaterfallAccount
