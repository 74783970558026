import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  AddressInput,
  Button,
  IconButton,
  NumberInput,
} from '@neobase-one/neobase-components'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { ethers } from 'ethers'

interface TranchesData {
  start: number
  end: number
  recipientAddress: string
}

export interface WaterfallData {
  permittedToken: string
  tranches: TranchesData[]
}

export const WaterfallForm: React.FC<{
  data: WaterfallData
  setDataHandler: (data: WaterfallData) => void
}> = ({ data, setDataHandler }) => {
  const theme = useTheme()
  const [view, setView] = React.useState<'table' | 'csv'>('table')
  const [sum, setSum] = React.useState(0.0)
  const [empty, setEmpty] = React.useState<any[]>([])
  const handleSetView = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'table' | 'csv' | null,
  ) => {
    if (newView !== null) {
      setView(newView)
    }
  }
  const ensProvider = new ethers.providers.InfuraProvider(
    'homestead',
    process.env.REACT_APP_INFURA_KEY || '',
  )

  // React.useEffect(() => {
  //   const newSum = data.recipients.reduce(
  //     (curr, next) => curr + next.percentAllocation,
  //     0,
  //   )
  //   setSum(newSum)
  // }, [data])
  // React.useEffect(() => {
  //   const newEmpty = data.recipients
  //     .map((recipient, index) =>
  //       recipient.percentAllocation <= 0 ? index : null,
  //     )
  //     .filter((index) => index !== null)
  //   setEmpty(newEmpty)
  // }, [data])

  // const toDistribute = (amount: number, accounts: number) => {
  //   amount = Math.floor(amount * 100)
  //   const split = Math.floor(amount / accounts)
  //   let remaining = amount - split * accounts
  //   let splits = Array(accounts).fill(split)
  //   splits = splits.map((val) => {
  //     if (remaining) {
  //       remaining = remaining - 1
  //       return (val + 1) / 100
  //     }
  //     return val / 100
  //   })
  //   return splits
  // }

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title="Permitted Token"></CardHeader>
        <Divider />
        <CardContent>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Each Waterfall can process either CANTO or a single ERC20 token.
            Non-permitted tokens sent to this Waterfall can be recovered to any
            of the recipients.
          </Typography>
          <Typography sx={{ pt: 2 }}>Token Address</Typography>
          <Stack spacing={4}>
            <Box sx={{ pt: 2 }} display="flex" flexDirection="row">
              <Box flexGrow={1}>
                {/* // TODO: Add erc20 token validation */}
                <AddressInput
                  value={data.permittedToken}
                  setValue={(event) => {
                    setDataHandler({
                      ...data,
                      permittedToken: event,
                    })
                  }}
                  placeholder="0x0000...000"
                  provider={ensProvider}
                />
              </Box>
              <Button
                sx={{ ml: 2 }}
                label="Clear"
                variant="outlined"
                onClick={() => {
                  setDataHandler({
                    ...data,
                    permittedToken: '',
                  })
                }}
              />
            </Box>
            <Stack flexDirection={'row'}>
              <Button
                label="CANTO"
                variant="outlined"
                onClick={() => {
                  setDataHandler({
                    ...data,
                    permittedToken:
                      '0x0000000000000000000000000000000000000000',
                  })
                }}
              />
              <Button
                sx={{ ml: 2 }}
                label="NOTE"
                variant="outlined"
                onClick={() => {
                  setDataHandler({
                    ...data,
                    permittedToken:
                      '0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503',
                  })
                }}
              />
              <Button
                sx={{ ml: 2 }}
                label="USDC"
                variant="outlined"
                onClick={() => {
                  setDataHandler({
                    ...data,
                    permittedToken:
                      '0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd',
                  })
                }}
              />
              <Button
                sx={{ ml: 2 }}
                label="USDT"
                variant="outlined"
                onClick={() => {
                  setDataHandler({
                    ...data,
                    permittedToken:
                      '0xd567b3d7b8fe3c79a1ad8da978812cfc4fa05e75',
                  })
                }}
              />
            </Stack>
          </Stack>
        </CardContent>
        {/* TODO: Add erc20 token selector */}
        <Box></Box>
      </Card>
      <Card sx={{ mt: 10 }} variant="outlined">
        <CardHeader title="Tranches"></CardHeader>
        <Divider />
        <CardContent>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Funds received will be paid out sequentially in Tranches. When one
            Tranches limit has been reached, the excess funds are then paid to
            the subsequent Tranche, and so on.
          </Typography>
          <Stack>
            {data.tranches.map((tranch, index) => (
              <Box
                sx={{ pt: 2 }}
                display="flex"
                flexDirection="row"
                key={index}
              >
                {/* <NumberInput
                  placeholder="0.000"
                  value={
                    index > 0 ? data.tranches[index - 1].end : tranch.start
                  }
                  setValue={(val) => {
                    console.log(val)
                  }}
                  inputProps={{ sx: { py: 2 } }}
                  sx={{ width: 150, border: 0, px: 2 }}
                  cardProps={{ sx: { ml: 2 } }}
                /> */}
                <Card
                  sx={{
                    width: 150,
                    border: 0,
                    boxShadow: 'none',
                    background:
                      theme.palette.mode == 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[900],
                    px: 2,
                    py: 2,
                    borderRadius: 1,
                  }}
                >
                  {index === 0
                    ? '0'
                    : tranch.start > 0
                    ? tranch.start
                    : '0.000'}
                </Card>
                {index === data.tranches.length - 1 ? (
                  <Card
                    sx={{
                      width: 150,
                      border: 0,
                      boxShadow: 'none',
                      background:
                        theme.palette.mode == 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[900],
                      px: 2,
                      py: 2,
                      ml: 2,
                      borderRadius: 1,
                    }}
                  >
                    Residual
                  </Card>
                ) : (
                  <NumberInput
                    placeholder="0.000"
                    value={tranch.end}
                    error={tranch.end > 0 && tranch.end <= tranch.start}
                    setValue={(val) => {
                      const newTranches = data.tranches
                      newTranches[index].end = val
                      newTranches[index + 1].start = val
                      setDataHandler({ ...data, tranches: newTranches })
                    }}
                    inputProps={{ sx: { py: 2 } }}
                    sx={{ width: 150, border: 0, px: 2 }}
                    cardProps={{ sx: { ml: 2 } }}
                  />
                )}
                <Box flexGrow={1}>
                  <AddressInput
                    value={tranch.recipientAddress}
                    setValue={(val) => {
                      const newTranches = data.tranches
                      newTranches[index].recipientAddress = val
                      setDataHandler({ ...data, tranches: data.tranches })
                    }}
                    placeholder="0x0000...000"
                    provider={ensProvider}
                    cardProps={{ sx: { ml: 2 } }}
                  />
                </Box>
                <IconButton
                  sx={{ ml: 2 }}
                  startIcon={<CloseIcon />}
                  variant="outlined"
                  disabled={data.tranches.length <= 2}
                  onClick={() => {
                    if (data.tranches.length > 2) {
                      const newTranches = data.tranches
                      if (index < data.tranches.length - 1)
                        data.tranches[index + 1].start = tranch.start
                      newTranches.splice(index, 1)
                      setDataHandler({ ...data, tranches: newTranches })
                    }
                  }}
                />
              </Box>
            ))}
          </Stack>
          {/* <Box sx={{ pt: 4, display: 'flex' }}>
            <Typography
              sx={{ ml: 2 }}
              variant="body2"
              color={theme.palette.text.secondary}
            >
              Tip: fill out the Recipients section to estimate when automatic
              payouts will occur.
            </Typography>
          </Box> */}
        </CardContent>
        <CardActions sx={{ px: 4, pb: 2 }}>
          <Button
            label="Add Tranche"
            variant="outlined"
            onClick={() => {
              const newTranches = data.tranches
              newTranches.push({ start: 0, end: 0, recipientAddress: '' })
              setDataHandler({ ...data, tranches: newTranches })
            }}
            sx={{ mb: 2 }}
          />
          {/* <Button label="Add Donation" variant="outlined" /> */}
        </CardActions>
      </Card>
    </Box>
  )
}
export default WaterfallForm
