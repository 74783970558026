import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Skeleton,
  Avatar,
} from '@mui/material'
import * as React from 'react'
import { SplitsClient } from '@neobase-one/splits-sdk'
import { useProvider } from 'wagmi'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { Button } from '@neobase-one/neobase-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AccountInfo } from '@neobase-one/neobase-components'
import { AccountInfoProps } from '@neobase-one/neobase-components/lib/components/Utility/AccountInfo/AccountInfo'
import { useNavigate } from 'react-router-dom'
import { MessageWithTransaction, getSplitEarningsInfo } from '../utils'
import { MetaDataContext } from '../context'
import { useSnackbar } from 'notistack'

const Explore: React.FC = () => {
  const navigate = useNavigate()
  const provider = useProvider()
  const { useFetchMetadata } = React.useContext(MetaDataContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [splits, setSplits] = React.useState<AccountInfoProps[]>()
  const [loadingSplits, setLoadingSplits] = React.useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    async function queryTopSplits(splitsClient: SplitsClient) {
      setLoadingSplits(true)
      const splits = await splitsClient.getTopSplits({
        lastId: '',
        numSplits: 1000,
      })

      const splitsInfo = await Promise.all(
        splits.splits.map(async (split) => {
          const { totalAmount } = await getSplitEarningsInfo(
            splitsClient,
            split.id,
            true,
            useFetchMetadata,
          )
          const numberOfRecipients = split.recipients.length
          return {
            address: split.id,
            amount: `$${totalAmount}`,
            subinfo: `${numberOfRecipients} recipients`,
            value: Number(totalAmount),
            buttonProps: { onClick: () => navigate(`/accounts/${split.id}`) },
          }
        }),
      )
      setSplits(splitsInfo)
      setLoadingSplits(false)
    }
    if (provider !== undefined) {
      const splitsClient = new SplitsClient({
        chainId: 7700,
        provider,
      })
      queryTopSplits(splitsClient)
    }
  }, [provider])

  const AccountInfoSkeleton = () => {
    return (
      <Stack>
        {[1, 2, 3, 4, 5, 6, 7].map((index, key) => (
          <Grid key={key} container spacing={3.5} p={2}>
            <Grid item>
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: { xs: 90, sm: 150 },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: { xs: 70, sm: 100 },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: { xs: 50, sm: 100 },
                        borderRadius: '1rem',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Stack>
    )
  }

  return (
    <Container sx={{ alignSelf: 'start' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        label="back"
        variant="text"
      />
      <Grid container spacing={6} sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader title="Splits"></CardHeader>
            <Divider />
            <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
              {loadingSplits ? (
                <AccountInfoSkeleton />
              ) : (
                <Stack
                  sx={{
                    minHeight: '440px',
                  }}
                  spacing={2}
                >
                  {splits &&
                    splits.map((accountInfo, index) => (
                      <React.Fragment key={index}>
                        <AccountInfo
                          {...accountInfo}
                          dense={true}
                          onCopy={() =>
                            enqueueSnackbar(
                              <MessageWithTransaction message="Address copied" />,
                            )
                          }
                        />
                        {index !== splits.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Explore
